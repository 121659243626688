import axios from 'axios'
import { CustomerAccount } from '@/classes/CustomerAccount'

export const state = () => ({
  customerAccounts: [],
  customerAccount: null,
})

export const getters = {
  customerAccounts: (state) => state?.customerAccounts,
  customerAccount: (state) => (id) => {
    id = Number(id)
    return state.customerAccounts.find((c) => c.customerAccountId === id)
  },
  getCustomerAccountDetail(state) {
    return state?.customerAccount
  },
  getCustomerAccountId(state) {
    return state?.customerAccount?.id
  },
}

export const mutations = {
  SET_CUSTOMER_ACCOUNT_DETAIL(state, { customerAccount }) {
    state.customerAccount = new CustomerAccount(customerAccount)
  },

  setCustomerAccounts(state, customerAccounts) {
    state.customerAccounts = customerAccounts
  },

  addCustomerAccount(state, customerAccount) {
    state.customerAccounts.push(customerAccount)
  },

  deleteCustomerAccount(state, customerAccount) {
    state.customerAccounts = state.customerAccounts.filter(
      (c) => c.id !== customerAccount.id
    )
  },
}

export const actions = {
  setCustomerAccountDetail({ commit }, { customerAccount }) {
    commit('SET_CUSTOMER_ACCOUNT_DETAIL', { customerAccount })
  },
  async fetchCustomerAccounts({ commit }, params) {
    const response = await axios.get(`/tables/customer-accounts?pageSize=-1`, {
      params,
    })
    commit('setCustomerAccounts', response.data.resultList)
  },
  async addCustomerAccount({ commit }, customerAccount) {
    const response = await axios.post(`/customerAccounts`, customerAccount)
    commit('addCustomerAccount', response.data.customerAccount)
  },
  async deleteCustomerAccount({ commit }, customerAccount) {
    const response = await axios.delete(
      `/customerAccounts/${customerAccount.id}`
    )
    commit('deleteCustomerAccount', response.data.customerAccount)
  },
  async updateCustomerAccount({ commit }, customerAccount) {
    const response = await axios.patch(
      `/customerAccounts/${customerAccount.id}`,
      customerAccount
    )
  },
}
