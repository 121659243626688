var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cr-address-selector"},[_c('CRForm',{ref:"form"},[_c('v-flex',[_c('CRAddressSelector',{attrs:{"id":"card-selector__add-new-card__billing-address__autocomplete","error-messages":[..._vm.errorMessages, ..._vm.validationErrors],"display-field":"street1","label":_vm.placeIsSelected
            ? 'Address'
            : `Search and select your billing address`},on:{"blur":_vm.handleBlur,"place-selected":_vm.handlePlaceSelected,"place-cleared":_vm.handlePlaceCleared}})],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeIsSelected),expression:"placeIsSelected"}]},[_c('v-flex',{staticClass:"py-4"},[_c('CRTextField',{attrs:{"label":"Apt / Suite # (optional)"},model:{value:(_vm.address.street2),callback:function ($$v) {_vm.$set(_vm.address, "street2", $$v)},expression:"address.street2"}})],1),_c('v-layout',{attrs:{"row":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('CRTextField',{attrs:{"label":"City"},model:{value:(_vm.address.city),callback:function ($$v) {_vm.$set(_vm.address, "city", $$v)},expression:"address.city"}})],1),_c('v-flex',{attrs:{"xs2":""}},[_c('p',{staticClass:"font-14 margin-t-0 margin-b-1"},[_vm._v(_vm._s(_vm.stateLabel))]),(_vm.isUSAddress || _vm.isCanadaAddress)?_c('v-select',{attrs:{"items":_vm.stateItems,"item-text":"value","item-value":"value","autocomplete":"off","flat":"","solo":""},model:{value:(_vm.address.state),callback:function ($$v) {_vm.$set(_vm.address, "state", $$v)},expression:"address.state"}}):_c('CRTextField',{model:{value:(_vm.address.state),callback:function ($$v) {_vm.$set(_vm.address, "state", $$v)},expression:"address.state"}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('CRTextField',{attrs:{"label":_vm.postalCodeLabel,"rules":_vm.zipCodeRequired
                  ? [
                      _vm.isRequired(true, _vm.isNotEmpty, {
                        req: `${_vm.postalCodeLabel} is Required`,
                        error: `${_vm.postalCodeLabel} is Required`,
                      }),
                    ]
                  : []},model:{value:(_vm.address.postalCode),callback:function ($$v) {_vm.$set(_vm.address, "postalCode", $$v)},expression:"address.postalCode"}})],1)],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }