import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const actions = {
  getCustomerAudits(store, params) {
    const {
      additionalQueries,
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/customer-audits/${additionalQueries}/audits?${query}`
    return axios.get(url)
  },
}
