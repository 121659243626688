import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { deepClone } from '@/utils/deepClone'
import customerAccounts from '@/services/customerAccounts'

const apiUrl = `${baseUrl('api')}/quotes`

export const namespaced = true

export const state = {
  quoteInProgress: getSavedState('quoteInProgress'),
  availableCustomerAccounts: [],
  toggleCreateCustomerAccount: false,
  selectedCustomerAccount: null,
  isACustomer: false,
  selectedCustomer: {},
  selectedMarket: {},
  autoDetectedMarket: {},
  selectedGarage: {},
  shouldSplitTrip: false,
  shouldRecalculatePricing: false,
}

// TODO: get rid of this stuff
export const getters = {
  quoteInProgress(state) {
    return state.quoteInProgress
  },
  availableCustomerAccounts(state) {
    return state.availableCustomerAccounts
  },
  getCreateCustomerAccount(state) {
    return state.toggleCreateCustomerAccount
  },
  selectedCustomerAccount(state) {
    return state.selectedCustomerAccount
  },
  isACustomer(state) {
    return state.isACustomer
  },
  selectedCustomer(state) {
    return state.selectedCustomer
  },
  getSelectedMarket: (state) => (index) => {
    return state.selectedMarket[index]
  },
  getSelectedMarketChanges(state) {
    return state.selectedMarket
  },
  getAutoDetectedNearestMarketChanges(state) {
    return state.autoDetectedMarket
  },
  getSelectedGarage: (state) => (index) => {
    return state.selectedGarage[index]
  },
  getGarageChanges(state) {
    return state.selectedGarage
  },
  getShouldSplitTrip(state) {
    return state.shouldSplitTrip
  },
  getShouldRecalculatePricing(state) {
    return state.shouldRecalculatePricing
  },
}

export const mutations = {
  SAVE_QUOTE_IN_PROGRESS(state, quote) {
    if (!quote) {
      state.quoteInProgress = {}
      deleteState('quoteInProgress')
      return
    }
    state.quoteInProgress = deepClone(quote)
    saveState('quoteInProgress', quote)
  },
  CLEAR_QUOTE_IN_PROGRESS(state) {
    state.quoteInProgress = null
    deleteState('quoteInProgress')
  },
  SET_ACCOUNT(state, account) {
    state.selectedCustomerAccount = account
    return state.selectedCustomerAccount
  },
  SET_TOGGLE_CUSTOMER_ACCOUNT_CREATION(state) {
    state.toggleCreateCustomerAccount = !state.toggleCreateCustomerAccount
    return state.toggleCreateCustomerAccount
  },
  SET_IS_CUSTOMER(state) {
    state.isACustomer = false
    return state.isACustomer
  },
  SET_SELECTED_CUSTOMER(state, customer) {
    state.selectedCustomer = customer
    return state.selectedCustomer
  },
  ADD_AVAILABLE_ACCOUNTS(state, accounts) {
    if (Array.isArray(accounts)) {
      state.availableCustomerAccounts = state.availableCustomerAccounts.concat(
        accounts
      )
    } else {
      state.availableCustomerAccounts.push(accounts)
    }

    return state.availableCustomerAccounts
  },
  CLEAR_SELECTED_MARKETS(state) {
    state.selectedMarket = {}
  },
  SET_SELECTED_MARKET(state, { market, index = 0 }) {
    const copiedMarket = JSON.parse(JSON.stringify(state.selectedMarket))
    copiedMarket[index] = market

    state.selectedMarket = copiedMarket
  },
  SET_AUTO_DETECTED_NEAREST_MARKET(state, { market, index = 0 }) {
    const copiedMarket = JSON.parse(JSON.stringify(state.autoDetectedMarket))
    copiedMarket[index] = market

    state.autoDetectedMarket = copiedMarket
  },
  SET_SELECTED_GARAGE(state, { garage, index = 0 }) {
    const copiedGarage = JSON.parse(JSON.stringify(state.selectedGarage))
    copiedGarage[index] = garage

    state.selectedGarage = copiedGarage
  },
  SET_SHOULD_SPLIT_TRIP(state, value) {
    state.shouldSplitTrip = value
  },
  SHOULD_RECALCULATE_PRICING(state, value) {
    state.shouldRecalculatePricing = value
  },
}

export const actions = {
  // TODO: set the default state in the state definition above,
  //   then set up a getter for this.
  quotesListView(store, params) {
    const {
      // companyId,
      // quotes,
      sorts,
      filters,
      additionalQueries,
      pageSize = 10,
      page = 1,
    } = params

    // keep this one around ...
    // let query = `companyId=${companyId}&quotes=${quotes}&page=${page}&pageSize=${pageSize}`

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/quotes?${query}&v2=true`
    return axios.get(url)
  },
  quotesTableExport(store, params) {
    const { sorts, filters } = params

    let query = ''
    if (sorts) {
      query += sorts
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/quotes/export?${query}`
    return axios.get(url, { responseType: 'blob' })
  },
  checkManagedId(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/checkManagedId`
    return axios.post(url, payload)
  },
  getV2Quote(store, quoteId) {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/${quoteId}`

    return axios.get(url)
  },
  duplicateQuote(store, params) {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/duplicate?sendEmail=no-email`
    return axios.post(url, payload)
  },
  createV2Quote(store, params) {
    const { payload, emailPreference = 'no-email' } = params
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/full?sendEmail=${emailPreference}`
    return axios.post(url, payload)
  },
  updateV2Quote(store, params) {
    const { quoteId, payload, emailPreference = 'no-email' } = params
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/full/${quoteId}?sendEmail=${emailPreference}`
    return axios.post(url, payload)
  },
  convert(store, params) {
    const { quoteId, payload } = params

    if (payload?.paymentMethodData) {
      delete payload.paymentMethodData.cardNumber
      delete payload.paymentMethodData.securityCode
    }

    const host = baseUrl()
    const url = `https://${host}/quotes/${quoteId}/convert?showToCustomer=false`
    return axios.post(url, payload)
  },
  saveQuoteInProgress({ commit }, quote) {
    commit('SAVE_QUOTE_IN_PROGRESS', quote)
  },
  saveQuote(store, quote) {
    const { emailPreference = 'no-email' } = quote
    delete quote.emailPreference
    return axios.post(`https://${apiUrl}?sendEmail=${emailPreference}`, quote)
  },
  deleteQuote(store, quoteId) {
    const url = `https://${apiUrl}/${quoteId}`

    return axios.delete(url)
  },
  markSoldOut(store, quoteId) {
    const host = baseUrl()
    const url = `https://${host}/quotes/markSoldOut/${quoteId}`

    return axios.get(url)
  },
  togglePendingConfirmation(store, quoteId) {
    const host = baseUrl()
    const url = `https://${host}/quotes/togglePendingConfirmation/${quoteId}`

    return axios.post(url)
  },
  getQuotePricingById(store, params) {
    return axios.get(
      `https://${baseUrl('api')}/v2/pricings/${
        params.quoteId
      }?biddingCompanyId=${params.companyId}&needsManualReferral=${params.needsManualReferral}`
    )
  },
  getQuotePricing(store, quote) {
    if (quote.priceForAllPricingMethods === undefined) {
      quote.priceForAllPricingMethods = true
    }

    return axios.post(`https://${baseUrl('api')}/v2/pricings`, quote)
  },
  setSelectedCustomerAccount({ commit }, account) {
    return commit('SET_ACCOUNT', account)
  },
  toggleCreateCustomerAccount({ commit }) {
    return commit('SET_TOGGLE_CUSTOMER_ACCOUNT_CREATION')
  },
  setSelectedCustomer({ commit }, account) {
    return commit('SET_SELECTED_CUSTOMER', account)
  },
  addAvailableAccounts({ commit }, account) {
    return commit('ADD_AVAILABLE_ACCOUNTS', account)
  },
  async getCustomerAccounts(store) {
    const {
      data: { resultList },
    } = await customerAccounts.getCustomerAccounts(
      { pageSize: 100 },
      { root: true }
    )
    return store.commit('ADD_AVAILABLE_ACCOUNTS', resultList)
  },
  changeEmailCampaignStatus(store, { status, quoteId }) {
    const payload = {
      status,
    }
    const host = baseUrl()
    const url = `https://${host}/quotes/email/${quoteId}`
    return axios.post(url, payload)
  },
  getEmailCustomizations() {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/email`
    return axios.get(url)
  },
  saveEmailCustomizations(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/email`
    return axios.patch(url, payload)
  },
  createEmailCustomizations(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/email`
    return axios.post(url, payload)
  },
  sendTestEmail(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/v2/quotes/email/provider/test`
    return axios.post(url, payload)
  },
  getQuoteTripEstimations(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/tripEstimations`
    return axios.post(url, payload)
  },
  getQuoteSummary(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/quotes/summary`
    return axios.get(url, payload)
  },
  quoteTimeSeriesCustomerData(store, { customerId, payload }) {
    return axios.post(
      `https://${baseUrl()}/quotes/timeSeries/${customerId}/customer`,
      payload
    )
  },
  clearSelectedMarkets({ commit }) {
    return commit('CLEAR_SELECTED_MARKETS')
  },
  setSelectedMarket({ commit }, { market, index }) {
    return commit('SET_SELECTED_MARKET', { market, index })
  },
  setAutoDetectedNearestMarket({ commit }, { market, index }) {
    return commit('SET_AUTO_DETECTED_NEAREST_MARKET', { market, index })
  },
  setSelectedGarage({ commit }, { garage, index }) {
    return commit('SET_SELECTED_GARAGE', { garage, index })
  },
  linkQuoteToContract(store, params) {
    return axios.post(
      `https://${baseUrl()}/quotes/${params.quoteId}/linkQuoteToContract/${
        params.contractId
      }`
    )
  },
  setShouldSplitTrip({ commit }, value) {
    return commit('SET_SHOULD_SPLIT_TRIP', value)
  },
  getSplitTrips(_, payload) {
    return axios.post(`https://${baseUrl()}/v2/quotes/split`, payload)
  },
  setShouldRecalculatePricing({ commit }, value) {
    commit('SHOULD_RECALCULATE_PRICING', value)
  },
  getMarketplaceLink(store, quoteId) {
    return axios.get(`https://${baseUrl()}/quotes/marketplaceLink/${quoteId}`)
  },
  updateQuoteMarketplace(store, payload) {
    return axios.post(`https://${baseUrl()}/quoteDetail/marketplace`, payload)
  },
  updateQuoteSentBy(store, payload) {
    return axios.post(`https://${baseUrl()}/quoteDetail/sentBy`, payload)
  },
  updateQuoteCRMDetails(store, payload) {
    return axios.post(`https://${baseUrl()}/quoteDetail/crmDetails`, payload)
  },
  updateConvertedQuote(store, payload) {
    return axios.put(
      `https://${baseUrl()}/quotes/convertedQuote/${payload.quoteId}`,
      payload
    )
  },
}

// Private helpers

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function deleteState(key) {
  window.localStorage.removeItem(key)
}
