import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { Tier } from '@/models/Tier'
import { TableViewParameters, TableViewResult } from '@/models/TableView'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Fetches all available tier types for entities (e.g., customers, quotes)
   * @returns
   */
  getTiers(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Tier>>> {
    const { pageSize = 100, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/tiers?${query}`
    return httpService.get(url)
  },
}
