import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { OnTimeStatusResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  byJourneyStopId(journeyStopId: number): Promise<AxiosResponse<OnTimeStatusResult>> {
    const url = `https://${baseUrl()}/onTime/calculateOnTimeStatus/${journeyStopId}`
    return httpService.post(url, null);
  },
}
