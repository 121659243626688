/**
 * disabledRiskTypes represents the risk types that have been disabled
 * in the current session. It resets when the trip-edit form
 * (TripsForm.vue) mounts and when the trip is updated (i.e., saved).
 */
export const state = {
  disabledRiskTypes: [],
}

export const getters = {
  disabledRiskTypes(state) {
    return state.disabledRiskTypes
  },
}

export const mutations = {
  ADD_DISABLED_RISK_TYPE(state, riskTypeId) {
    state.disabledRiskTypes.push(riskTypeId)
    return state.disabledRiskTypes
  },
  CLEAR_DISABLED_RISK_TYPES(state) {
    state.disabledRiskTypes = []
  },
}

export const actions = {
  addDisabledRiskType({ commit }, riskTypeId) {
    return commit('ADD_DISABLED_RISK_TYPE', riskTypeId)
  },
  clearDisabledRiskTypes({ commit }) {
    commit('CLEAR_DISABLED_RISK_TYPES')
  },
}
