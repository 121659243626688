<template>
  <div class="cr-address-selector">
    <CRForm ref="form">
      <v-flex>
        <CRAddressSelector
          id="card-selector__add-new-card__billing-address__autocomplete"
          :error-messages="[...errorMessages, ...validationErrors]"
          display-field="street1"
          :label="
            placeIsSelected
              ? 'Address'
              : `Search and select your billing address`
          "
          @blur="handleBlur"
          @place-selected="handlePlaceSelected"
          @place-cleared="handlePlaceCleared"
        />
      </v-flex>
      <v-expand-transition>
        <div v-show="placeIsSelected">
          <v-flex class="py-4">
            <CRTextField
              v-model="address.street2"
              label="Apt / Suite # (optional)"
            />
          </v-flex>
          <v-layout row justify-space-between>
            <v-flex xs6>
              <CRTextField v-model="address.city" label="City" />
            </v-flex>
            <v-flex xs2>
              <p class="font-14 margin-t-0 margin-b-1">{{ stateLabel }}</p>
              <v-select
                v-if="isUSAddress || isCanadaAddress"
                v-model="address.state"
                :items="stateItems"
                item-text="value"
                item-value="value"
                autocomplete="off"
                flat
                solo
              />
              <CRTextField v-else v-model="address.state" />
            </v-flex>
            <v-flex xs3>
              <CRTextField
                v-model="address.postalCode"
                :label="postalCodeLabel"
                :rules="
                  zipCodeRequired
                    ? [
                        isRequired(true, isNotEmpty, {
                          req: `${postalCodeLabel} is Required`,
                          error: `${postalCodeLabel} is Required`,
                        }),
                      ]
                    : []
                "
              />
            </v-flex>
          </v-layout>
        </div>
      </v-expand-transition>
    </CRForm>
  </div>
</template>

<script>
import { isRequired, isNotEmpty } from '@/cr/utils/validators'
import { states, provinces } from '@/cr/utils/states'

export default {
  name: 'CRAddressSelectorExpandable',
  props: {
    errorMessages: {
      type: Array,
      default: () => [],
    },
    zipCodeRequired: {
      type: Boolean,
    },
  },
  data: () => ({
    placeIsSelected: false,
    validationErrors: [],
    address: {
      street1: null,
      street2: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
      completeAddress: null,
      addressName: null,
      lat: null,
      lng: null,
      timeZone: null,
    },
  }),
  computed: {
    isUSAddress() {
      return this.address.country === 'US'
    },
    isCanadaAddress() {
      return this.address.country === 'CA'
    },
    postalCodeLabel() {
      return this.isUSAddress ? 'Zip Code' : 'Postal Code'
    },
    stateLabel() {
      return this.isUSAddress
        ? 'State'
        : this.isCanadaAddress
        ? 'Province'
        : 'Region'
    },
    stateItems() {
      return this.isUSAddress ? states : this.isCanadaAddress ? provinces : []
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.$emit('input', this.address)
      },
    },
  },
  methods: {
    isRequired,
    isNotEmpty,
    handlePlaceSelected({ place }) {
      this.validationErrors = []

      this.address = {
        street1: place.street1,
        street2: '',
        city: place.city,
        state: place.state,
        country: place.country,
        postalCode: place.postalCode,
        completeAddress: place.completeAddress,
        addressName: place.addressName,
        lat: place.lat,
        lng: place.lng,
        timeZone: place.timeZone,
        initialSearch: place.street1,
      }

      this.placeIsSelected = true
    },
    handleBlur() {
      if (!this.placeIsSelected) {
        this.validationErrors = ['Address is Required']
      }
    },
    handlePlaceCleared() {
      this.placeIsSelected = false
      this.validationErrors = []
      this.$emit('address-clear')
    },
    validate() {
      if (!this.placeIsSelected) {
        this.validationErrors = ['Address is Required']
        return false
      }

      let formIsValid = this.$refs.form.validate()
      return this.placeIsSelected && formIsValid
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-select .v-input__control {
  max-height: 46px !important;
  .v-input__slot {
    border: 2px solid $border-gray !important;
    border-radius: 5px;
    min-height: 46px !important;
    .v-input__append-inner {
      .v-input__icon--append {
        margin-top: -5px;
      }
    }
  }
}
</style>
