<template>
  <v-layout
    v-if="active"
    class="alert-box-row"
    :class="type"
    no-gutters
    align-stretch
  >
    <v-flex class="end-cap" cols="auto">
      <v-icon v-if="type === 'error'" color="white">
        {{ errorIcon }}
      </v-icon>
      <v-icon v-else color="white">check_circle</v-icon>
    </v-flex>
    <v-flex class="alert-text">
      <div><slot /></div>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'success',
    },
    active: {
      type: Boolean,
    },
    message: {
      type: String,
      default: null,
    },
    errorIcon: {
      type: String,
      default: 'warning',
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';
.alert-box-row {
  border-radius: 4px;
  border: 1px solid $success;
  background-color: $success;
  padding: 0;

  .end-cap {
    border-radius: 4px 0 0 4px;
    padding: 12px 8px;
    flex-grow: 0;
  }

  .alert-text {
    background-color: $background-success;
    padding-left: 16px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  &.error {
    background-color: $error;
    border: 1px solid $error;

    .alert-text {
      background-color: $background-error;
    }
  }
}
</style>
