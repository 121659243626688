<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="!isIconInButton && !material ? viewBox : '0 0 24 24'"
    :style="style"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }} icon</title>
    <g :fill="crColor">
      <component :is="icon"></component>
    </g>
  </svg>
</template>

<script>
import { toPascal } from '@/cr/utils/string'
import CRColor from '@/cr/color/CRColor'

export default {
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    iconName: {
      type: String,
      default: 'box',
    },
    margin: {
      type: String,
      default: '0',
    },
    viewBox: {
      type: String,
      default: '0 0 32 32',
    },
    fileName: {
      type: String,
      default: undefined,
    },
    material: {
      type: Boolean,
    },
  },
  computed: {
    style() {
      let variables = `--color: ${this.crColor};`
      variables = `${variables} margin: ${this.margin};`
      return variables
    },
    crColor() {
      if (this.color === 'currentColor') {
        return this.color
      }
      return new CRColor(this.color).toHexString()
    },
    iconFileName() {
      if (this.$slots.default?.[0]?.text) {
        return toPascal(this.$slots.default?.[0]?.text.trim())
      } else if (this.fileName) {
        return this.fileName
      }
      return null
    },
    icon() {
      if (this.iconFileName) {
        return async () =>
          await import('./icons/Icon' + this.iconFileName + '.vue')
      }
      return null
    },
    hasClickListener() {
      return !!this?.$listeners?.click
    },
    classes() {
      let classes = 'cr-icon'
      classes = this.hasClickListener ? `${classes} cr-icon--link` : classes
      classes = this.disabled ? `${classes} cr-icon--disabled` : classes
      return classes
    },
    isIconInButton() {
      const parentClasses = this?.$parent?.classes
      if (parentClasses && typeof parentClasses === 'string') {
        return this.$parent.classes.includes('cr-btn')
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
$color: var(--color);

.cr-icon {
  color: $color;
  &svg {
    display: inline-block;
    vertical-align: baseline;
  }
  &.cr-icon--link {
    cursor: pointer;
    outline: none;
  }

  &--disabled {
    color: $gray-lighter !important;
  }
}
</style>
