import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult, CustomerAccount } from '@/models/dto'
import {
  CreateRiderGroupRequest,
  UpdateRiderGroupRequest,
} from '@/models/dto/RiderGroup'
import { CustomerAccountDefaults } from '@/models/dto/CustomerAccountDefaults'

const httpService: HttpService = new HttpService()

export default {
  getCustomerAccounts(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<CustomerAccount>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/customer-accounts?${query}`
    return httpService.get(url)
  },
  getCustomerAccount(
    accountId: number
  ): Promise<AxiosResponse<CustomerAccount>> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${accountId}`
    return httpService.get(url)
  },
  modifyCustomerAccount(params: {
    id: number
    payload: CustomerAccount
  }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${params.id}`
    return httpService.patch(url, params.payload)
  },
  addCustomerAccount(payload: CustomerAccount): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts`
    return httpService.post(url, payload)
  },
  deleteCustomerAccount(accountId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${accountId}`
    return httpService.delete(url)
  },
  exportCustomerAccounts(params: TableViewParameters): Promise<AxiosResponse> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/customer-accounts/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
  addExistingCustomerAccountToParentCustomerAccount(payload: {
    parentCustomerAccountId: number
    teamCustomerAccountId: number
  }): Promise<AxiosResponse> {
    const parentCustomerAccountId = payload.parentCustomerAccountId
    const teamCustomerAccountId = payload.teamCustomerAccountId

    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${parentCustomerAccountId}/team/${teamCustomerAccountId}`
    return httpService.get(url)
  },
  removeTeamFromParentCustomerAccount(payload: {
    parentCustomerAccountId: number
    teamCustomerAccountId: number
  }): Promise<AxiosResponse> {
    const parentCustomerAccountId = payload.parentCustomerAccountId
    const teamCustomerAccountId = payload.teamCustomerAccountId

    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${parentCustomerAccountId}/team/${teamCustomerAccountId}`
    return httpService.delete(url)
  },
  inviteUserToAccount(payload: any): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/invite`
    return httpService.post(url, payload)
  },
  uploadCustomerAccountLightLogo({
    customerAccountId,
    formData,
  }): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/customerAccounts/${customerAccountId}/uploadLightLogo`
    return httpService.post(url, formData)
  },
  resendCustomerAccountInvite(payload: any): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/resendCustomerInvite`
    return httpService.post(url, payload)
  },
  getCustomerAccountDefaults(accountId: number): Promise<AxiosResponse<CustomerAccountDefaults>> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${accountId}/defaultValueSet`
    return httpService.get(url)
  },
  getCustomerAccountEffectiveDefaults(
    accountId: number
  ): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${accountId}/effectiveDefaultValueSet`
    return httpService.get(url)
  },
  updateCustomerAccountDefaultValueSet(payload: {
    customerAccountId: number
  }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${payload.customerAccountId}/defaultValueSet`
    return httpService.put(url, payload)
  },
  getCustomerAccountCard(
    quoteId: number,
    customerId: number
  ): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/quoteForm/${quoteId}/userSelector/${customerId}/customerAccountCard`
    return httpService.get(url)
  },
  getCustomers(customerAccountId: number) {
    const host = baseUrl()
    const url = `https://${host}/customerAccounts/${customerAccountId}/customers`
    return httpService.get(url)
  },
  getSharedContacts(customerAccountId: number) {
    const host = baseUrl()
    const url = `https://${host}/customerAccount/${customerAccountId}/sharedContacts/table`
    return httpService.get(url)
  },
  getUserSharedTrips(userId: number) {
    const host = baseUrl()
    const url = `https://${host}/user/${userId}/sharedEntities`
    return httpService.get(url)
  },
  getContracts(customerAccountId: number) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/contracts`
    return httpService.get(url)
  },
  getRiderGroups(customerAccountId: number) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/rider-groups`
    return httpService.get(url)
  },
  createRiderGroup(payload: CreateRiderGroupRequest) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${payload.customerAccountId}/rider-groups`
    return httpService.post(url, payload)
  },
  updateRiderGroup(payload: UpdateRiderGroupRequest) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${payload.customerAccountId}/rider-groups/${payload.riderGroupId}`
    return httpService.put(url, payload)
  },
  deleteRiderGroup(customerAccountId: number, riderGroupId: number) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/rider-groups/${riderGroupId}`
    return httpService.delete(url)
  },
  getRiders(customerAccountId: number) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/riders`
    return httpService.get(url)
  },
  createRider(payload) {
    let query = ''
    if (payload.sendInviteEmail) {
      query = `?sendInviteEmail=${payload.sendInviteEmail}`
    }
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${payload.customerAccountId}/riders${query}`
    return httpService.post(url, payload)
  },
  updateRider(payload) {
    let query = ''
    if (payload.sendInviteEmail) {
      query = `?sendInviteEmail=${payload.sendInviteEmail}`
    }
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${payload.customerAccountId}/riders/${payload.riderId}${query}`
    return httpService.put(url, payload)
  },
  deleteRider(customerAccountId: number, riderId: number) {
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/riders/${riderId}`
    return httpService.delete(url)
  },
}
