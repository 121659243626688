/**
 * Returns the current timestamp in ISO string format.
 * @returns The current timestamp as an ISO string.
 */
const now = (): string => new Date().toISOString()

/**
 * Formats the input message. If the input is a string, it returns the message
 * otherwise, it converts the message to a JSON string.
 * @param m - The message to format.
 * @returns The formatted message as a string.
 */
const format = (m: any): string => (typeof m === 'string' ? m : JSON.stringify(m))

/**
 * Writes a log message to the console.
 * @param level - The logging level (info, debug, warn, error).
 * @param stdwhere - The standard logging method to use (log for info/debug, warn for warn, error for error).
 * @param msg - The messages to log.
 */
const write = (level: string, stdwhere: 'log' | 'warn' | 'error', ...msg: any[]): void => {
  // FIXME: Add a way to turn logging on and off per the environment
  // FIXME: Consider a way to also log to a server for broker users
  const messageParts = []
  let error = null
  msg.forEach((item) => {
    if (typeof item === 'string') {
      // It's a string for the message
      messageParts.push(item)
    } else if (error === null && item && item.stack) {
      // it's an error, probably
      error = item
    } else {
      // Fallback - just stringify it
      messageParts.push(JSON.stringify(item))
    }
  })

  const message = messageParts.join(' ')
  if (error) {
    console[level](message, error)
  } else {
    console[level](message)
  }
}


/**
 * Logs an informational message.
 * @param msg - The messages to log.
 */
export const info = (...msg: any[]): void => write('info', 'log', ...msg)

/**
 * Logs a debug message.
 * @param msg - The messages to log.
 */
export const debug = (...msg: any[]): void => write('debug', 'log', ...msg)

/**
 * Logs a warning message.
 * @param msg - The messages to log.
 */
export const warn = (...msg: any[]): void => write('warn', 'warn', ...msg)

/**
 * Logs an error message.
 * @param msg - The messages to log.
 */
export const error = (...msg: any[]): void => write('error', 'error', ...msg)

/**
 * Alias for info.
 * @param msg - The messages to log.
 */
export const log = info
