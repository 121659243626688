export enum RiskTypeId {
  AddressRisk = 1,
  MissingPickupTimeRisk = 2,
  DriverHotelRisk = 3,
  FlightRisk = 4,
  DOTComplianceRisk = 5,
  IncompleteStopRisk = 6,
  MissingStopRisk = 7,
}

export enum ChargeTypeId {
  BaseFare = 1,
  ProcessingFee = 4,
  Discount = 15,
  Amenities = 20,
}

export enum VehicleTypeId {
  CharterBus = 1,
  MiniBus = 2,
  Sprinter = 3,
  PartyBus = 4,
  Sedan = 5,
  SUV = 6,
  Limousine = 7,
  Trolley = 8,
  SchoolBus = 9,
  Van = 10,
}

export enum AmenityTypeId {
  Wifi = 1,
  Luggage = 2,
  Bathroom = 3,
  SeatBelts = 4,
  ADA = 5,
  TVScreens = 6,
  Outlets = 7,
  LeatherSeats = 8,
  CDC = 9,
  DOD = 10,
  SPAB = 11,
  AlcoholConsumption = 12,
  MountainDriver = 13,
  NineteenACertification = 14,
  AuxBluetooth = 15,
  PASystem = 16,
  BottledWater = 17,
  MakeModelYear = 18,
  BusColor = 19,
  BusLogos = 20,
  NonStandard = 21,
  TripCoordinators = 22,
  BusWraps = 23
}

export enum paidAmenityTypeIds {
  Wifi = 1,
  LeatherSeats = 8,
  AlcoholAllowed = 12,
}

export enum guaranteedAmenityTypeIds {
  Wifi = 1,
  LeatherSeats = 8,
  AlcoholAllowed = 12,
  SPAB = 11,
  ADA = 5,
  SeatBelts = 4,
  Outlets = 7,
  TVScreens = 6,
  MountainDriver = 13,
  NineteenACertification = 14,
  AuxBluetooth = 15,
  PASystem = 16,
  BottledWater = 17,
}

export enum bestEffortAmenityTypeIds {
  MakeModelYear = 18,
  BusColor = 19,
  BusLogos = 20,
  NonStandard = 21,
  TripCoordinators = 22,
  BusWraps = 23
}

export enum VehicleAmenityTypeIds {
  Wifi = 1,
  Luggage = 2,
  Bathroom = 3,
  SeatBelts = 4,
  ADA = 5,
  TVScreens = 6,
  Outlets = 7,
  LeatherSeats = 8,
  CDC = 9,
  DOD = 10,
  AuxBluetooth = 15,
  PASystem = 16,
  MakeModelYear = 18,
  BusColor = 19,
  BusLogos = 20,
  NonStandard = 21,
  BusWraps = 23
}

export enum CreditCardType {
  Visa = 'visa',
  Mastercard = 'mastercard',
  Discover = 'discover',
  AmericanExpress = 'amex',
  Diners = 'diners',
  Default = 'default',
}

export enum KeyCode {
  Enter = 13,
  Escape = 27,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40,
}

export enum ReservationStatus {
  Upcoming = 'UPCOMING',
  Started = 'STARTED',
  Finished = 'FINISHED',
}

export enum ReservationTrackingAllocation {
  All = 'ALL',
  Partial = 'PARTIAL',
  None = 'NONE',
}

export enum ProviderConflict {
  Partial = 'partial',
  Complete = 'complete',
  None = 'none',
}

export enum CompanyId {
  CharterUP = 2,
}

export enum PartnerTypeId {
  GoldReseller = 1,
  SilverReseller = 2,
  BronzeReseller = 13,
  Platinum = 3,
  Gold = 4,
  Silver = 5,
  Bronze = 6,
  NonPartner = 7,
}

export enum SplitTrafficType {
  Anonymous = 'anonymous',
  User = 'user',
}

export enum SplitKeyType {
  AnonymousUser = 'anonymous',
}

export enum SplitFeatureFlag {
  DriverVehicleDecoupling = 'driver-vehicle-decoupling',
  ParentBrandsCallCenter = 'parent-brands-callcenter',
  NewPartnerLabels = 'new-partner-labels',
  Auth0SignIn = 'auth0-coachrail',
  ShowNotAuthorizedOperators = 'default-show-unauthorized-operators',
  ServiceTier = 'coachrail-service-tier',
  AcceleratedPayments = 'coachrail-accelerated-payments',
  PaymentTermsDisputes = 'payment-terms-disputes',
  CapturePaymentStages = 'capture-payment-stages',
  StopStatusV2Endpoint = 'stop-status-v2-endpoint',
  MarkupCalculationUpdates = 'sales-markup-calculation-updates',
  SpanishCallQueues = 'spanish-call-queues',
  MainContactEdit = 'main-contact-edit',
  VehicleVinOverride = 'vehicle-vin-override',
  NewTripAmenities = 'new-trip-amenities',
  QuoteFormPaymentTerms = 'quote-form-payment-terms',
  NewGarageEntities = 'new-garage-entities',
}

export enum PricingMethod {
  SinglePrice = 'single_price',
  Bids = 'bids',
  Category = 'category',
}

export enum SplitEvents {
  QuoteConverted = 'quote-converted',
}

export enum VINReviewStatusKeys {
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
}

export enum VINReviewTypes {
  Create = 'create',
  Update = 'update',
}

export enum CancellationRefundPercentage {
  None = 0,
  Full = 1,
}

export enum ValuePropKey {
  BookingProtection = 'booking_protection',
  Tracking = 'tracking',
  LiveSupport = 'live_support',
  QualifiedOperators = 'qualified_operators',
}

export enum CustomerAccountPurpose {
  Demo = 'demo',
  Standard = 'standard',
}

export enum ReservationStopStatus {
  ExpectedOnTime = 'EXPECTED ON TIME',
  OnTime = 'ON TIME',
  Arrived = 'ARRIVED',
  EnRoute = 'EN ROUTE',
  ExpectedLate = 'EXPECTED LATE',
  Late = 'LATE',
  NoTracking = 'NO TRACKING',
  Undetected = 'UNDETECTED',
  Completed = 'COMPLETED',
  ToDo = 'TO DO',
}

export enum InfoModalVariant {
  Simple = 'simple',
  Standard = 'standard',
  Itemized = 'itemized',
  Custom = 'custom',
}

export enum PaymentMethodTypeKey {
  CreditCard = 'credit_card',
  ACH = 'ach',
  Check = 'check',
  Other = 'other',
  Hold = 'hold',
}

export enum PaymentMethodTypeId {
  CreditCard = 1,
  ACH = 2,
  Check = 3,
  Billing = 4,
  Refund = 5,
}

export enum PaymentGatewayTypeKey {
  AuthorizeNet = 'auth_net',
  Finix = 'finix',
}

export enum CheckoutPageId {
  GOGO = 1,
  National = 2,
  Shofur = 3,
}

export enum PaymentTerms {
  Net3 = 'net_3',
  Net14 = 'net_14',
  Pending = 'pending',
}

export enum ReferralStatus {
  Reoffered = 'reoffered',
  Offered = 'offered',
  Accepted = 'accepted',
  Rejected = 'rejected',
  NotOffered = 'not_offered',
  FullyOffered = 'fully_offered',
  FullyAccepted = 'fully_accepted',
  FullyConfirmed = 'fully_confirmed',
  Confirmed = 'confirmed',
}

export enum PaymentTypeId {
  FullPayment = 1,
  DownPayment = 2,
  BillAfterServices = 3,
}

export enum PaymentTypeKey {
  FullPayment = 'full_payment',
  DownPayment = 'down_payment',
  BillAfterServices = 'bill_after_services',
}

export enum PaymentStageKey {
  Unset = 'unset',
  Checkout = 'checkout',
  RemainingBalance = 'remaining_balance',
}

export enum ReservationStatusKey {
  Upcoming = 'upcoming',
  Started = 'started',
  Finished = 'finished',
  Cancelled = 'cancelled',
  OnHold = 'hold',
}

export enum CancellationStatusKey {
  Started = 'started',
  Cancelled = 'cancelled',
}

export enum TicketStatusTypeId {
  Created = 1,
  Open = 2,
  Closed = 3,
  Migrated = 4,
}

export enum TierKey {
  Vip = 'vip',
  Plus = 'plus',
  Standard = 'standard',
}

export enum TierLabel {
  Vip = 'VIP',
  Plus = 'Plus',
  Standard = 'Standard',
}

export enum CompanyTierLabel {
  Tier1 = 'Tier 1',
  Tier2 = 'Tier 2',
  Tier3 = 'Tier 3',
  NoTier = 'No Tier',
}

export enum PoStatus {
  Confirmed = "PO Confirmed",
  Received = "PO Received"
}

export enum TripMonitoringTabsHeader {
  Reservation = 'Reservation Details',
  Driver = 'Driver',
  Vehicle = 'Vehicle',
  Provider = 'Provider',
  Tracking = 'Tracking',
}

export enum ContactType {
  Driver = "driver",
  Operator = "operator"
}

export enum VinVerificationType {
  VerificationFailure = "verification_failure"
}
