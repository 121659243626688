export const isRequired = (status, fn, messages) => {
  const isEmpty = (value) => {
    if (typeof value === 'undefined') {
      return true
    }

    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0
    }
    if (['number', 'boolean'].includes(typeof value)) {
      return false
    }
    if (value instanceof Date) {
      return false
    }

    if (typeof value === 'object' && value !== null) {
      return false
    }
    return false
  }

  return (val) => {
    const isItEmpty = isEmpty(val)

    if (!status) {
      return fn(val) || 'Field is not valid'
    }

    if (status && !isItEmpty) {
      return fn(val) || messages.error || 'Field is not valid'
    }

    if (status && isItEmpty) {
      return messages.req || 'Field is required'
    }

    return ''
  }
}

export const isNotEmpty = (val) => {
  const isItNotEmpty = (value) => {
    if (value === null) {
      return false
    }
    if (typeof value === 'undefined') {
      return false
    }
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length !== 0
    }
    if (['number', 'boolean'].includes(typeof value)) {
      return true
    }
    if (value instanceof Date) {
      return true
    }

    if (typeof value === 'object' && value !== null) {
      return true
    }
    return false
  }

  return isItNotEmpty(val)
}
