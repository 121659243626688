<template>
  <div :style="cssVariables" class="outer-ring">
    <div v-if="selected" class="inner-dot"></div>
  </div>
</template>

<script>
import CRColor from '@/cr/color/CRColor'

export default {
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    cssVariables() {
      return {
        '--btn-color': CRColor(this.color).toHexString(),
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$btn-color: var(--btn-color);

.outer-ring {
  width: 16px;
  height: 16px;
  border: solid 2px $btn-color;
  border-radius: 50%;
  float: left;
  margin-top: 11px;
  margin-right: 10px;
  position: relative;
}
.inner-dot {
  width: 6px;
  height: 6px;
  background: $btn-color;
  border-radius: 50%;
  float: left;
  position: absolute;
  left: 3px;
  top: 3px;
}
</style>
