<template>
  <div class="cr-text-field" :style="cssVars">
    <label v-if="label" :for="$attrs.id">{{ label }}</label>
    <div class="cr-text-field__input-control">
      <!-- Input slot -->
      <div :id="idOnWrapper ? $attrs.id : ''" :class="inputSlotClass">
        <span
          style="cursor: pointer; display: flex"
          @click="() => this.$emit('prepend-icon-click')"
        >
          <CRIcon
            v-if="prependIcon"
            :width="20"
            :height="20"
            color="primary"
            :view-box="prependIconViewbox || '0 0 24 24'"
            :style="'margin-right: 10px;'"
          >
            {{ prependIcon }}
          </CRIcon>
        </span>
        <div
          v-if="prefix"
          class="cr-text-field__input-control__input-slot__prefix"
        >
          {{ prefix }}
        </div>
        <input
          :id="!idOnWrapper ? $attrs.id : ''"
          ref="inputBase"
          v-mask="vMask"
          :class="`cr-text-field__input-control__input-slot__input-base ${inputClass}`"
          :type="type"
          :readonly="readonly"
          :placeholder="placeholder"
          :autofocus="autofocus"
          :disabled="disabled"
          :tabindex="tabindex"
          v-bind="$attrs"
          :value="inputValue || $attrs.value"
          :aria-labelledby="label"
          v-on="inputListeners"
        />
        <button v-if="clearable && inputValue.length > 0" @click="clearInput">
          <CRIcon color="primary" view-box="0 0 24 24" :width="22" :height="22">
            clear
          </CRIcon>
        </button>
        <span
          style="cursor: pointer; display: flex"
          @click="() => this.$emit('append-icon-click')"
        >
          <CRIcon
            v-if="appendIcon"
            :width="20"
            :height="20"
            color="primary"
            class="icon-append"
            style="margin-left: 10px"
            :view-box="appendIconViewbox || '0 0 24 24'"
          >
            {{ appendIcon }}
          </CRIcon>
        </span>
      </div>

      <!-- Input messages  -->
      <div
        v-if="errorsList.length > 0"
        class="cr-text-field__input-control__input-messages"
      >
        <transition name="fade">
          <template v-if="errorsList.length > 0">
            <p
              v-for="err in errorsList"
              :key="err"
              class="cr-text-field__input-control__input-messages__error"
            >
              {{ err }}
            </p>
          </template>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CRColor from '@/cr/color/CRColor'

export default {
  directives: {
    mask: (el, binding) => {
      if (!binding.value) return
      mask(el, binding)
    },
  },
  inheritAttrs: false,
  props: {
    appendIcon: {
      type: String,
      default: undefined,
    },
    appendIconViewbox: {
      type: String,
      default: undefined,
    },
    autofocus: { type: Boolean },
    backgroundColor: {
      type: String,
      default: 'blueLight',
    },
    color: {
      type: String,
      default: 'primary',
    },
    clearable: { type: Boolean },
    disabled: { type: Boolean },
    error: { type: Boolean },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    inputClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: undefined,
    },
    legacyBorder: {
      type: Boolean,
    },
    idOnWrapper: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: undefined,
    },
    prependIcon: {
      type: String,
      default: undefined,
    },
    prependIconViewbox: {
      type: String,
      default: undefined,
    },
    readonly: { type: Boolean },
    rules: {
      type: Array,
      default: () => [],
    },
    tabindex: {
      type: String,
      default: '0',
    },
    type: {
      type: String,
      default: 'text',
    },
    validateOnBlur: { type: Boolean },
    vMask: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      hasFocus: false,
      hasBlur: false,
      ruleErrors: [],
      inputValue: '',
      didValidateInput: false,
    }
  },
  computed: {
    inputListeners() {
      var vm = this

      return Object.assign({}, this.$listeners, {
        input: function (e) {
          vm.inputValue = e.target.value

          if (!vm.validateOnBlur || vm.hasBlur) {
            vm.testRules()
          }

          vm.$emit('input', e.target.value)
        },
        focus: () => {
          vm.$emit('focus')
          vm.hasFocus = true
        },
        blur: () => {
          vm.hasFocus = false
          vm.hasBlur = true
          vm.$emit('blur')
        },
        change: (e) => {
          vm.$emit('change', e.target.value)
        },
      })
    },
    inputSlotClass() {
      let classStr = 'cr-text-field__input-control__input-slot'
      if (this.hasFocus) {
        classStr = `${classStr} cr-text-field__input-control__input-slot__focused`
      }
      if (this.errorState) {
        classStr = `${classStr} cr-text-field__input-control__input-slot__error`
      }
      if (this.disabled) {
        classStr = `${classStr} cr-text-field__input-control__input-slot__disabled`
      }
      if (this.legacyBorder) {
        classStr = `${classStr} cr-text-field__input-control__input-slot__legacy-border`
      }
      return classStr
    },
    errorsList() {
      if (typeof this.errorMessages === 'string') {
        return [...this.ruleErrors, this.errorMessages]
      } else if (Array.isArray(this.errorMessages)) {
        return [...this.ruleErrors, ...this.errorMessages]
      } else {
        return [...this.ruleErrors]
      }
    },
    errorState() {
      if (this.validateOnBlur && !this.hasBlur && !this.didValidateInput) {
        return false
      }

      return this.errorsList.length > 0 || this.error
    },
    cssVars() {
      return {
        '--prop-color': CRColor(this.color).toHexString(),
        '--slot-background-color': CRColor(this.backgroundColor).toHexString(),
      }
    },
  },
  watch: {
    hasBlur(blur) {
      if (blur) {
        this.testRules()
      }
    },
    '$attrs.value': {
      immediate: true,
      handler: function (newValue) {
        this.inputValue = newValue
      },
    },
  },
  mounted() {
    if (this.$attrs.value) {
      this.inputValue = this.$attrs.value
    }
  },
  methods: {
    clearInput() {
      this.$emit('input', '')
      this.$emit('change', '')
      this.$emit('clear')
      this.inputValue = ''
      this.$refs.inputBase.focus()
    },
    testRules() {
      let brokenRules = this.rules
        .filter((rule) => {
          let result = rule(this.inputValue)
          return !(result === '' || result === true)
        })
        .map((rule) => rule(this.inputValue))

      this.ruleErrors = brokenRules
    },
    validate() {
      this.didValidateInput = true
      this.testRules()
      return this.errorsList.length <= 0 && !this.error
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-text-field {
  font-family: 'Lato', sans-serif;

  label {
    margin-bottom: 4px;
    display: block;
  }

  &__input-control {
    min-height: 48px;

    svg {
      color: var(--prop-color);
    }

    input {
      outline: none;
      border: none;
      background-color: inherit;
      font-size: 14px;
      border-radius: 5px;
      max-height: 32px;
      padding: 8px 0;
      width: 100%;
    }

    button {
      outline: none;
      max-height: 24px;
    }

    &__input-slot {
      min-height: 40px;
      margin-bottom: 8px;
      text-align: left;
      padding: 0 12px;
      color: $gray;
      background: var(--slot-background-color);
      border: 1px solid $border-gray;
      border-radius: 5px;
      display: flex;
      align-items: center;
      caret-color: var(--prop-color);

      &::placeholder {
        color: $gray-medium-light;
      }

      &:hover {
        border-color: $gray-mid-light;
      }

      &__focused {
        border-color: $primary;

        &:hover {
          border-color: $primary;
        }
      }

      &__error {
        caret-color: $error !important;
        border: 1px solid $error;
        color: $error;
        background: $error-pale;

        &:hover {
          border-color: $error;
        }

        svg {
          color: $error !important;
        }

        .cr-text-field__input-control__input-slot__prefix {
          color: $error !important;
        }

        input {
          background-color: $error-pale;
        }
      }

      &__disabled {
        background: $blue-dull !important;
        border-color: $blue-dull !important;

        input {
          background-color: $blue-dull !important;
        }
      }

      &__prefix {
        text-align: right;
        padding-right: 4px;
        font-size: 14px;
        white-space: nowrap;
        color: var(--prop-color);
      }

      &__legacy-border {
        border: none !important;
      }
    }

    &__input-messages {
      padding: 0 12px;
      margin-bottom: 8px;
      min-height: 12px;

      p {
        font-size: 12px;
        margin: 0;
        line-height: 1.25;
      }

      &__error {
        color: $error;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s, transform 0.25s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }
}
</style>
