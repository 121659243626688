import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const actions = {
  getVehicles(store, params) {
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      additionalQueries = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/vehicles?${query}`
    return axios.get(url)
  },
  getVehicleTypes(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/vehicleTypes?${query}` // localhost and http. Refer to uri_map
    return axios.get(url)
  },
  getAllVehiclesForCompany(store, params) {
    const { pageSize = -1, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}&referredCompanyId=${params.companyId}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/vehicles?${query}`
    return axios.get(url)
  },
  getVehicle(store, vehicleId) {
    const host = baseUrl()
    const url = `https://${host}/vehicles/${vehicleId}`
    return axios.get(url)
  },
  modifyVehicle(store, params) {
    const host = baseUrl()
    const url = `https://${host}/vehicles/${params.id}`

    return axios.put(url, params.payload)
  },
  addVehicle(store, params) {
    const host = baseUrl()
    const url = `https://${host}/vehicles`

    return axios.post(url, params.payload)
  },
  uploadVehiclePhotos(store, params) {
    const host = baseUrl()
    const url = `https://${host}/vehicles/${params.vehicleId}/vehiclePhotos`
    return axios.post(url, params.payload)
  },
  removeVehiclePhotos(store, params) {
    const host = baseUrl()
    const url = `https://${host}/vehicles/${params.vehicleId}/removeVehiclePhotos`
    return axios.post(url, params.payload)
  },
  uploadVehiclePhotosCharterUp(store, params) {
    const host = baseUrl()
    let url = `https://${host}/vehicles/${params.companyId}/vehiclePhotosCharterUp`
    return axios.post(url, params.payload)
  },
  getAffiliateSummaryVehicles(store, companyId) {
    const host = baseUrl()
    let url = `https://${host}/vehicles/${companyId}/getSummaryVehicles`
    return axios.get(url)
  },
  getAffiliatesVehicleDetails(store, vehicleId) {
    const host = baseUrl()
    let url = `https://${host}/vehicles/${vehicleId}/affiliatesDetail`
    return axios.get(url)
  },
  getGaragesByVehicleIds(store, payload) {
    const host = baseUrl()
    let url = `https://${host}/vehicles/garages`
    return axios.post(url, payload)
  },
}
