import Vue, { VueConstructor } from 'vue';
import { Auth0Client, Auth0ClientOptions, PopupConfigOptions, PopupLoginOptions, RedirectLoginOptions, GetTokenSilentlyOptions, createAuth0Client } from '@auth0/auth0-spa-js'
import { store } from '@/state/store'
import { baseUrl } from '@/utils/env';

/** Define a default action to perform after authentication */
const DEFAULT_REDIRECT_CALLBACK = (appState?: any) => window.history.replaceState({}, document.title, window.location.pathname);
let instance: Vue | null = null
/** Returns the current instance of the SDK */
export const getInstance = (): Vue | null => instance;
/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}: Auth0ClientOptions & { onRedirectCallback?: (appState?: any) => void; redirectUri?: string }) => {
  if (instance) return instance;
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {} as any, // Use appropriate user type or `any` if type is unknown
        auth0Client: null as Auth0Client | null,
        popupOpen: false,
        error: null as Error | null
      }
    },
    methods: {
      /** Authenticates the user using a popup window */
      async loginWithPopup(o?: PopupLoginOptions, c?: PopupConfigOptions) {
        if (this.popupOpen) {
          return
        }

        const defaultPopupOptions: PopupLoginOptions = {
          authorizationParams: {
            redirect_uri: redirectUri,
            prompt: 'login' // force reauthentication
          }
        }
        const defaultPopupConfig: PopupConfigOptions = {
          timeoutInSeconds: 120,
        }

        const currentUser = this.user
        this.setPopupOpen(true)
        try {
          await this.auth0Client!.loginWithPopup(o ?? defaultPopupOptions, c ?? defaultPopupConfig);

          // If someone tries logging into an account that is not the
          // current active session, sign them out.
          const newUser = await this.auth0Client!.getUser()
          if (currentUser.sub !== newUser.sub) {
            await this.logout()
            return
          }

          const token = await this.getTokenSilently()
          await store.dispatch('auth/auth0Login', token)

          this.setUser(await this.auth0Client!.getUser());
          this.setIsAuthenticated(await this.auth0Client!.isAuthenticated())
          this.setError(null)
        } catch (e) {
          this.setError(e)
          console.error(e)
        } finally {
          this.setPopupOpen(false)
        }
      },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.setLoading(true);
        let appState
        try {
          const callbackReponse = await this.auth0Client.handleRedirectCallback()
          appState = callbackReponse.appState
          this.setUser(await this.auth0Client!.getUser());
          this.setIsAuthenticated(true);
          this.setError(null);
          onRedirectCallback(appState)
        } catch (e) {
          console.log('Error during redirect callback:', e);
          this.setError(e);
        } finally {
          this.setLoading(false);
        }
        return appState
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o: RedirectLoginOptions) {
        return this.auth0Client!.loginWithRedirect(o);
      },
      /** Returns all the claims present in the ID token */
      getIdTokenClaims(o: any) { // Specify the correct type instead of `any` if available
        return this.auth0Client!.getIdTokenClaims(o);
      },
      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o: GetTokenSilentlyOptions) {
        return this.auth0Client!.getTokenSilently({...o, authorizationParams: { ...options.authorizationParams }});
      },
      /** Gets the access token using a popup window */
      getTokenWithPopup(o: any) { // Specify the correct type instead of `any` if available
        return this.auth0Client!.getTokenWithPopup(o);
      },
      /** Logs the user out and removes their session on the authorization server */
      async logout() {
        const redirectUrl = `https://${baseUrl(null)}/ci/login`
        await this.auth0Client!.logout({
          logoutParams: { returnTo: redirectUrl }
        })
      },
      /** Sets the loading state */
      setLoading(loading: boolean) {
        this.loading = loading
      },
      /** Sets the authentication state */
      setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated
      },
      /** Sets the user */
      setUser(user: any) {
        this.user = user
      },
      /** Sets the Auth0 client */
      setAuth0Client(client: Auth0Client) {
        this.auth0Client = client
      },
      /** Sets the popup window status */
      setPopupOpen(popupOpen: boolean) {
        this.popupOpen = popupOpen
      },
      /** Sets the error state */
      setError(error: Error) {
        this.error = error
      },
      /** Use this method to instantiate the SDK client */
      async init() {
        // Create a new instance of the SDK client using members of the given options object
        this.auth0Client = await createAuth0Client({
          ...options,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          authorizationParams: {
            redirect_uri: redirectUri,
            response_type: 'code',
            scope: 'openid profile email offline_access',
            audience: `https://${options.domain}/api/v2/`,
            ...options.authorizationParams
          }
        });
        this.setError(null)
        try {
          // If the user is returning to the app after authentication..
          if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
            this.setError(null)
            // TODO: we may be able to depreciate the callback page and put the
            // functionality here while calling this function from the app level
          }
        } catch (e) {
          this.setError(e)
        } finally {
          // Initialize our internal authentication state
          this.setIsAuthenticated(await this.auth0Client.isAuthenticated())
          this.setUser(await this.auth0Client.getUser())
          this.setLoading(false)
        }
      }
    },
  })
  return instance
};
/** Create a simple Vue plugin to expose the wrapper object throughout the application */
export const Auth0Plugin = {
  install(Vue: VueConstructor, options: Auth0ClientOptions & { onRedirectCallback?: (appState?: any) => void }) {
    Vue.prototype.$auth0 = useAuth0(options);
  }
};
