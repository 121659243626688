import { TripVehicle } from './TripVehicle'
import { TripDate } from './TripDate'
import { DateTime } from 'luxon'
import { TripVehicleGroup } from './TripVehicleGroup'
import { TripAddress } from './TripAddress'

export class Trip {
  constructor() {
    this.tripId = null
    this.routeName = null
    this.tripTypeId = null
    this.passengerCount = null
    this.requiredDrivers = null
    this.description = null
    this.notes = null
    this.tripContactIsCustomer = null
    this.isTripContactSelected = null
    this.processingFeePercentage = 3
    this.processingFeeCharge = null

    this.tripContact = {}
    this.tripNotes = []
    this.tripAddresses = [new TripAddress({ orderIndex: 0 })]
    this.tripVehicleGroups = [
      new TripVehicleGroup({ tripVehicleGroupIndex: 0, selected: true }),
    ]
    this.rates = []
    this.charges = []
    this.recurrences = []
    this.paymentMethods = []
    this.paymentType = {
      id: null,
    }
    this.paymentTypeId = null
    this.suggestedPricing = null
    this.liveMileLabel = null
    this.deadMileLabel = null
    this.estimatedTimeLabel = null
    this.refreshPricing = null
    this.deadDistance = null
    this.drivingTime = null
    this.distance = null
    this.endDate = null
    this.pricingMethod = null
    this.dueDate = null
    this.depositPercentage = 10
    this.pricingMarket = null
    this.nearestMarketId = null
    this.marketplaceDiscountPercent = null
    this.marketplaceMarkupPercent = null
  }

  toObject() {
    const trip = Object.assign({}, this)
    trip.tripAddresses = this.tripAddresses.map((tripAddress) =>
      tripAddress.toObject()
    )
    trip.tripVehicleGroups = this.tripVehicleGroups.map((tripVehicleGroup) =>
      tripVehicleGroup.toObject()
    )
    trip.requiredVehicles = this.requiredVehicles
    trip.tripNotes = this.tripNotes
    trip.tripContact = this.tripContact
    trip.stops = this.stops
    trip.calendarDays = this.calendarDays
    trip.hourlyDifference = this.hourlyDifference
    trip.tripType = this.tripType
    return trip
  }

  toJSON() {
    const trip = Object.assign({}, this)
    trip.tripAddresses = this.tripAddresses.map((tripAddress) =>
      tripAddress.toObject()
    )
    trip.tripVehicleGroups = this.tripVehicleGroups.map((tripVehicleGroup) =>
      tripVehicleGroup.toObject()
    )
    trip.requiredVehicles = this.requiredVehicles
    trip.tripNotes = this.tripNotes
    trip.stops = this.stops
    trip.calendarDays = this.calendarDays
    trip.hourlyDifference = this.hourlyDifference
    trip.tripType = this.tripType
    return trip
  }

  get tripVehicles() {
    return [...this.requiredVehicles]
  }

  get requiredVehicles() {
    return this.tripVehicleGroups
      .flatMap((group) => group.tripVehicles.map((tv) => tv.toObject()))
      .reduce((arr, vehicle) => {
        const foundVehicle = arr.find(
          (v) => v.vehicleTypeId === vehicle.vehicleTypeId
        )
        if (foundVehicle) {
          foundVehicle.quantity += vehicle.quantity
          return arr
        }
        arr.push(vehicle)
        return arr
      }, [])
  }

  set tripVehicles(tripVehicles) {
    for (let tripVehicle of tripVehicles) {
      let tripVehicleGroup = this.tripVehicleGroups.find(
        (g) => g.tripVehicleGroupId === tripVehicle.tripVehicleGroupId
      )

      if (!tripVehicleGroup) {
        tripVehicleGroup = new TripVehicleGroup()
        this.tripVehicleGroups.push(tripVehicleGroup)
        tripVehicleGroup.name = tripVehicle.name
      }

      tripVehicleGroup.push(new TripVehicle(...tripVehicle))
    }
  }

  get stops() {
    let stops = []
    for (let group of this.tripVehicleGroups) {
      stops = stops.concat(group.tripVehicleGroupStops)
    }

    stops = stops.sort(
      (a, b) =>
        DateTime.fromISO(a.pickupDatetime) - DateTime.fromISO(b.pickupDatetime)
    )

    stops.forEach((s, i) => {
      s.orderIndex = i + 1
      s.address = this.tripAddresses.find(
        (a) => a.tripAddressHash === s.tripAddressHash
      ).address
    })

    return stops
  }

  set stops(stops) {
    for (let stop of stops) {
      let tripVehicleGroup = this.tripVehicleGroups.find(
        (g) => g.tripVehicleGroupId === stop.tripVehicleGroupId
      )

      if (!tripVehicleGroup) {
        tripVehicleGroup = new TripVehicleGroup()
        this.tripVehicleGroups.push(tripVehicleGroup)
      }

      let stopDate = DateTime.fromISO(stop.pickupDatetime).toFormat(
        'yyyy-MM-dd'
      )

      let tripDate = tripVehicleGroup.tripDates.find((d) => d.date === stopDate)

      if (!tripDate) {
        tripDate = new TripDate()
        tripVehicleGroup.tripDates.push(tripDate)
      }

      tripDate.tripAddressId = stop.tripAddressId
      tripDate.times.push(
        DateTime.fromISO(stop.pickupDatetime).toFormat('HH:mm')
      )
    }
  }

  get calendarDays() {
    const dates = []
    for (const stop of this.stops) {
      const { pickupDate, dropoffDate } = stop
      if (pickupDate) {
        dates.push(DateTime.fromISO(pickupDate))
      }
      if (dropoffDate) {
        dates.push(DateTime.fromISO(dropoffDate))
      }
    }
    dates.sort((a, b) => (a < b ? -1 : 0))

    if (dates.length === 0 || dates.length === 1) {
      return 1
    } else {
      const first = dates[0]
      const last = dates[dates.length - 1]
      const diff = Math.trunc(last.diff(first, 'days').as('days'))
      return diff + 1
    }
  }

  get hourlyDifference() {
    const dates = []
    for (const stop of this.stops) {
      const { pickupDatetime, dropoffDatetime } = stop

      if (pickupDatetime) {
        dates.push(DateTime.fromISO(pickupDatetime))
      }
      if (dropoffDatetime) {
        dates.push(DateTime.fromISO(dropoffDatetime))
      }
    }
    dates.sort((a, b) => (a && a < b ? -1 : 0))

    if (dates.length === 0 || dates.length === 1) {
      return 0
    } else {
      const first = dates[0]
      const last = dates[dates.length - 1]
      return Math.trunc(last.diff(first, 'hours').as('hours'))
    }
  }

  get tripType() {
    return { id: this.tripTypeId }
  }
}
