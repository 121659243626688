<template>
  <div class="cr-text-area">
    <label v-if="label" :for="$attrs.id">
      {{ label }}
    </label>
    <div :class="inputWrapperClass" :style="cssVars">
      <div
        class="cr-text-area__input-wrapper__grow"
        :data-replicated-value="`${inputValue}`"
      >
        <textarea
          :id="$attrs.id"
          ref="textArea"
          :value="$attrs.value || inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          v-bind="$attrs"
          v-on="inputListeners"
        ></textarea>
      </div>
    </div>
    <div v-if="!hideDetails" class="cr-text-area__messages">
      <div class="cr-text-area__messages__errors">
        <transition name="fade">
          <template v-if="errorsList.length > 0">
            <p
              v-for="err in errorsList"
              :key="err"
              class="cr-text-area__messages__errors__msg"
            >
              {{ err }}
            </p>
          </template>
        </transition>
      </div>
      <div
        v-if="this.$options.propsData.counter"
        :class="
          counterHasError
            ? 'cr-text-area__messages__counter cr-text-area__messages__counter__error'
            : 'cr-text-area__messages__counter'
        "
      >
        <p>{{ `${inputValue.length} / ${counterNumber}` }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRTextArea',
  inheritAttrs: false,
  props: {
    autoGrow: Boolean,
    counter: {
      type: [Boolean, String, Number],
      default: 25,
    },
    disabled: Boolean,
    error: Boolean,
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    resize: Boolean,
    rows: {
      type: Number,
      default: 2,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    validateOnBlur: Boolean,
  },
  data() {
    return {
      hasBlur: false,
      hasFocus: false,
      ruleErrors: [],
      inputValue: '',
      didValidateInput: false,
    }
  },
  computed: {
    counterNumber() {
      if (typeof this.counter === 'boolean') {
        return 25
      } else if (typeof counter === 'string') {
        if (parseInt(this.counter)) {
          return parseInt(this.counter)
        } else {
          return 25
        }
      } else {
        return this.counter
      }
    },
    cssVars() {
      return {
        '--min-height': `${this.rows * 21}px`,
        '--resize': !this.autoGrow && this.resize ? 'vertical' : 'none',
        '--overflowY': this.autoGrow ? 'hidden' : 'scroll',
      }
    },
    inputWrapperClass() {
      let classStr = 'cr-text-area__input-wrapper'
      if (this.hasFocus) {
        classStr = `${classStr} cr-text-area__input-wrapper__focused`
      }
      if (this.errorState) {
        classStr = `${classStr} cr-text-area__input-wrapper__error`
      }
      if (this.disabled) {
        classStr = `${classStr} cr-text-area__input-wrapper__disabled`
      }
      return classStr
    },
    errorState() {
      if (this.validateOnBlur && !this.hasBlur && !this.didValidateInput) {
        return false
      }

      return (
        this.error ||
        !this.testRules() ||
        this.errorsList.length > 0 ||
        this.counterHasError
      )
    },
    errorsList() {
      if (typeof this.errorMessages === 'string') {
        return [...this.ruleErrors, this.errorMessages]
      } else if (Array.isArray(this.errorMessages)) {
        return [...this.ruleErrors, ...this.errorMessages]
      } else {
        return [...this.ruleErrors]
      }
    },
    counterHasError() {
      if (!this.$options.propsData.counter) return false
      return this.inputValue.length > this.counterNumber
    },
    inputListeners() {
      var vm = this

      return Object.assign({}, this.$listeners, {
        input: function (e) {
          vm.inputValue = e.target.value
          vm.$emit('input', e.target.value)
        },
        focus: () => {
          vm.$emit('focus')
          vm.hasFocus = true
        },
        blur: () => {
          vm.hasFocus = false
          vm.hasBlur = true
          vm.$emit('blur')
        },
        change: (e) => {
          vm.$emit('change', e.target.value)
        },
      })
    },
  },
  watch: {
    '$attrs.value': {
      handler(newValue) {
        this.inputValue = newValue
      },
    },
  },
  methods: {
    testRules() {
      let flagError = false
      let ruleErrors = []

      const falseOrString = (rule) => {
        if (typeof rule === 'string') {
          flagError = true
          ruleErrors.push(rule)
          return true
        } else if (typeof rule === 'boolean' && !rule) {
          flagError = true
          return true
        }
        return false
      }

      for (let i = 0; i < this.rules.length; i++) {
        let rule = this.rules[i]
        if (!falseOrString(rule) && typeof rule === 'function') {
          let result = rule(this.inputValue)
          falseOrString(result)
        }
      }

      this.ruleErrors = ruleErrors
      return !flagError
    },
    validate() {
      this.didValidateInput = true
      return (
        !this.error &&
        this.testRules() &&
        this.errorsList.length === 0 &&
        !this.counterHasError
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-text-area {
  font-family: 'Lato', sans-serif;

  label {
    margin-bottom: 4px;
    display: block;
    font-size: 14px;
  }

  &__input-wrapper {
    background: $input-background-gray;
    border: 1px solid $border-gray;
    padding: 8px 12px;
    border-radius: 5px;
    color: $gray;

    &__grow {
      word-break: break-word;
      display: grid;
      min-height: var(--min-height);

      &::after {
        content: attr(data-replicated-value) ' ';
        white-space: pre-wrap;
        visibility: hidden;
      }

      // Needs to be styled identically in order for autogrow to happen
      textarea,
      &::after {
        font: inherit;
        grid-area: 1 / 1 / 2 / 2; // Wrapper and textarea directly on top of each other
        color: inherit;
        background: inherit;
        border-radius: 5px;
        caret-color: inherit;
        outline: none;
        font-size: 14px;
        border-radius: 5px;
        border: none;
      }

      textarea {
        resize: var(--resize);
        overflow: var(--overflowY);
        min-height: var(--min-height);
      }
    }

    &:hover {
      border-color: $gray-mid-light;
    }

    &__focused {
      border-color: $primary;

      &:hover {
        border-color: $primary;
      }
    }

    &__error {
      border-color: $error;
      background-color: $error-pale;
      caret-color: $error;
      color: $error;

      &:hover {
        border-color: $error;
      }
    }

    &__disabled {
      background: $blue-dull !important;
      border-color: $blue-dull;

      &:hover {
        border-color: $blue-dull;
      }
    }
  }

  &__messages {
    padding: 0 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    min-height: 12px;
    display: grid;
    grid-template-columns: [first] 75% [second] 25% [third];

    &__errors {
      grid-column-start: first;
      grid-column-end: second;

      &__msg {
        color: $error;
      }
    }

    &__counter {
      grid-column-start: second;
      grid-column-end: third;
      display: flex;
      justify-content: flex-end;

      &__error {
        p {
          color: $error;
        }
      }
    }

    p {
      font-size: 12px;
      margin: 0;
      line-height: 1.25;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.25s, transform 0.25s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-5px);
  }
}
</style>
