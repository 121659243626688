export class Customer {
  constructor() {
    this.id = null
    this.customerAccount = null
    this.lastQuote = null
    this.isSignedUp = false
    this.firstName = null
    this.lastName = null
    this.phone = null
    this.email = null
  }

  toObject() {
    const customer = Object.assign({}, this)
    return customer
  }
}
