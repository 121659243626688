import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  async addressAutoComplete(term: string) {
    const url = `https://${baseUrl('api')}/places?term=${term}`
    return httpService.get(url)
  },
  async addressAutoCompleteV2(term: string, lat: number, lng: number, parentKnownAddressId: number, limit: number) {
    let query = ''
    if (term) {
      query = `term=${term}`
    }
    if (lat && lng) {
      const separator = query ? '&' : ''
      query = `${query}${separator}lat=${lat}&lng=${lng}`
    }
    if (parentKnownAddressId !== undefined) {
      const separator = query ? '&' : ''
      query = `${query}${separator}parentKnownAddressId=${parentKnownAddressId}`
    }
    if (limit !== undefined) {
      const separator = query ? '&' : ''
      query = `${query}${separator}limit=${limit}`
    }
    if (query) {
      query = `?${query}`
    }
    const url = `https://${baseUrl('api')}/places/v2/search${query}`
    return httpService.get(url)
  },
  async placeDetail(placeId: number) {
    const url = `https://${baseUrl('api')}/places/${placeId}`
    return httpService.get(url)
  },
  async detailV2(placeId: number, source: string) {
    const url = `https://${baseUrl(
      'api'
    )}/places/v2/${placeId}?source=${source}`
    return httpService.get(url)
  },
  async placeDetailFormatted(placeId: number) {
    const url = `https://${baseUrl('api')}/places/${placeId}`
    const { data } = await httpService.get(url).catch(() => null)
    if (!data) {
      return {}
    }

    return data
  },
}
