import { Role } from "../models/dto"

/**
 * Checks if any role in the given array of roles contains a permission with the specified name.
 *
 * This function iterates through each role and its permissions, looking for a match
 * with the provided permission name. It uses a short-circuit evaluation to return
 * true as soon as a matching permission is found, enhancing performance by not iterating
 * through the entire collection once a match is identified.
 *
 * @param {Role[]} roles - An array of Role objects, where each Role contains an array of Permissions.
 * @param {string} name - The name of the permission to search for across all roles.
 * @returns {boolean} - Returns true if at least one role contains a permission with the specified name, otherwise false.
 *
 */
export const findPermissionByName = (roles: Role[], name: string): boolean => {
  return (roles || []).some(role =>
    role.permissions.some(permission => permission.permissionName === name)
  )
}
