import { Operator } from '../../classes/Operator'
import { VehicleTypeRate } from '../../classes/VehicleTypeRate'

export const state = {
  customerAccountId: null,
  companyId: null,
  operator: null,
  rates: [],
  mode: null,
}

export const getters = {
  getCustomerAccountId(state) {
    return state?.customerAccountId
  },
  getCompanyId(state) {
    return state?.companyId
  },
  getOperator(state) {
    return state?.operator
  },
  getRates(state) {
    return state?.rates
  },
  getMode(state) {
    return state?.mode
  },
}

export const mutations = {
  CLEAR_SELECTED_OPERATOR(state) {
    state.customerAccountId = null
    state.companyId = null
    state.operator = null
    state.rates = []
    state.mode = null
  },
  SET_CUSTOMER_ACCOUNT_ID(state, { customerAccountId }) {
    state.customerAccountId = customerAccountId
  },
  SET_COMPANY_ID(state, { companyId }) {
    state.companyId = companyId
  },
  SET_OPERATOR(state, { operator }) {
    state.operator = new Operator(operator)
  },
  SET_RATES(state, { rates }) {
    const tempRates = []
    for (const rate of rates || []) {
      tempRates.push(new VehicleTypeRate(rate))
    }
    state.rates = tempRates
  },
  SET_MODE(state, { mode }) {
    state.mode = mode
  },
  SET_RATE(state, { vehicleTypeKey, rateTypeKey, rate }) {
    const foundVehicleTypeRate = state?.rates?.find(
      (vtr) => vtr.vehicleTypeKey === vehicleTypeKey
    )
    if (foundVehicleTypeRate) {
      const index = foundVehicleTypeRate?.rates?.findIndex(
        (r) => r.rateTypeKey === rateTypeKey
      )
      if (index !== -1) {
        foundVehicleTypeRate.rates.splice(index, 1, rate)
      }
    }
  },
}

export const actions = {
  clearSelectedOperator({ commit }) {
    commit('CLEAR_SELECTED_OPERATOR')
  },
  setCustomerAccountId({ commit }, { customerAccountId }) {
    commit('SET_CUSTOMER_ACCOUNT_ID', { customerAccountId })
  },
  setCompanyId({ commit }, { companyId }) {
    commit('SET_COMPANY_ID', { companyId })
  },
  setOperator({ commit }, { operator }) {
    commit('SET_OPERATOR', { operator })
  },
  setRates({ commit }, { rates }) {
    commit('SET_RATES', { rates })
  },
  setRate({ commit }, { vehicleTypeKey, rateTypeKey, rate }) {
    commit('SET_RATE', { vehicleTypeKey, rateTypeKey, rate })
  },
  setMode({ commit }, { mode }) {
    commit('SET_MODE', { mode })
  },
}
