import { render, staticRenderFns } from "./GaragesIcon.vue?vue&type=template&id=4af1d440&scoped=true"
var script = {}
import style0 from "./GaragesIcon.vue?vue&type=style&index=0&id=4af1d440&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af1d440",
  null
  
)

export default component.exports