import Vue from 'vue'
import { EventBus } from '@/utils/event-bus'

export const state = {
  alerts: [],
  dialog: {},
  isSidebarOpen: true,
  sidebarDialog: {},
  highlightedElementRef: null,
  highlightedElementText: {},
  highlightedElementDimensions: null,
}

export const getters = {
  alerts(state) {
    return state.alerts
  },
  dialogComponent(state) {
    return state.dialog.component
  },
  sidebarDialogComponent(state) {
    return state.sidebarDialog.component
  },
  isSidebarOpen(state) {
    return state.isSidebarOpen
  },
  showDialog(state) {
    return state.dialog.showing
  },
  persistent(state) {
    return state.sidebarDialog.persistent
  },
  showSidebarDialog(state) {
    return state.sidebarDialog.showing
  },
  dialogData(state) {
    return state.dialog.data
  },
  sidebarDialogData(state) {
    return state.sidebarDialog.data
  },
  highlightedElementRef(state) {
    return state.highlightedElementRef
  },
  highlightedElementText(state) {
    return state.highlightedElementText
  },
  highlightedElementDimensions(state) {
    return state.highlightedElementDimensions
  },
}

export const mutations = {
  SHOW_ALERT(state, alertData) {
    state.alerts.push(
      Object.assign(alertData, {
        shown: true,
        date: Date.now(),
      })
    )
  },
  HIDE_ALERT(state, alert) {
    alert.shown = false
  },
  UNDO_ALERT_ACTION(state, alert) {
    EventBus.$emit('global-alert-action-undo', alert)
    alert.shown = false
  },
  DIALOG_CLOSED_ACTION(state) {
    EventBus.$emit('global-dialog-closed-action')
  },
  OPEN_DIALOG(state, { component, data }) {
    Vue.set(state.dialog, 'showing', true)
    Vue.set(state.dialog, 'component', component)
    Vue.set(state.dialog, 'data', data)
  },
  OPEN_SIDEBAR(state) {
    state.isSidebarOpen = true
  },
  CLOSE_SIDEBAR(state) {
    state.isSidebarOpen = false
  },
  OPEN_SIDEBAR_DIALOG(state, { component, data, persistent }) {
    Vue.set(state.sidebarDialog, 'showing', true)
    Vue.set(state.sidebarDialog, 'component', component)
    Vue.set(state.sidebarDialog, 'data', data)
    Vue.set(state.sidebarDialog, 'persistent', persistent)
  },
  CLOSE_SIDEBAR_DIALOG(state) {
    state.sidebarDialog = {}
  },
  CLOSE_DIALOG(state) {
    state.dialog = {}
    state.sidebarDialog = {}
    state.alerts = []
  },
  SET_HIGHLIGHTED_ELEMENT(state, { elementRef, text }) {
    state.highlightedElementRef = elementRef
    state.highlightedElementText = text
  },
  HIGHLIGHT_ELEMENT(state, { elementDimensions }) {
    state.highlightedElementDimensions = elementDimensions
  },
  CLEAR_HIGHLIGHT(state) {
    state.highlightedElementRef = null
    state.highlightedElementDimensions = null
    state.highlightedElementText = {}
  },
}

export const actions = {
  showAlert({ commit }, alert) {
    commit('SHOW_ALERT', alert)
  },
  hideAlert({ commit }, alert) {
    commit('HIDE_ALERT', alert)
  },
  undoAlertAction({ commit }, alert) {
    commit('UNDO_ALERT_ACTION', alert)
  },
  dialogClosedAction({ commit }) {
    commit('DIALOG_CLOSED_ACTION')
  },
  openSidebar({ commit }) {
    commit('OPEN_SIDEBAR')
  },
  closeSidebar({ commit }) {
    commit('CLOSE_SIDEBAR')
  },
  async openDialog({ commit }, { component, data }) {
    await component
    commit('OPEN_DIALOG', { component, data })
  },
  async openSidebarDialog({ commit }, { component, data, persistent }) {
    await component
    commit('OPEN_SIDEBAR_DIALOG', { component, data, persistent })
  },
  closeSidebarDialog({ commit }) {
    commit('CLOSE_SIDEBAR_DIALOG')
  },
  closeDialog({ commit }) {
    commit('CLOSE_DIALOG')
  },
  highlightElementByRef({ commit }, { elementRef, text }) {
    commit('SET_HIGHLIGHTED_ELEMENT', { elementRef, text })
  },
  showHighlightForElement({ commit }, { elementDimensions }) {
    commit('HIGHLIGHT_ELEMENT', { elementDimensions })
  },
  clearHighlightedElement({ commit }) {
    commit('CLEAR_HIGHLIGHT')
  },
}
