export class Chaperone {
  constructor(chaperone) {
    this.externalId = chaperone.externalId
    this.firstName = chaperone.firstName
    this.lastName = chaperone.lastName
    this.email = chaperone.email
    this.phone = chaperone.phone
    this.customerAccounts = chaperone.customerAccounts ?? []
  }

  toObject() {
    const chaperone = Object.assign({}, this)
    return chaperone
  }
}
