import { updateDriverHotelInfo } from '@/services/trips'
import { deepClone } from '@/utils/deepClone'

export const state = {
  hotelInformation: null,
}

export const getters = {
  hotelInformation(state) {
    return state.hotelInformation
  },
  address(state) {
    return state.hotelInformation?.address
  },
  hotelName(state) {
    return state.hotelInformation?.hotelName
  },
  confirmationNumber(state) {
    return state.hotelInformation?.confirmationNumber
  },
  nameOnReservation(state) {
    return state.hotelInformation?.nameOnReservation
  },
}

export const mutations = {
  SET_HOTEL_INFORMATION(state, hotelInformation) {
    state.hotelInformation = hotelInformation
  },
  SET_ADDRESS(state, address) {
    state.hotelInformation.address = address
  },
  SET_HOTEL_NAME(state, hotelName) {
    state.hotelInformation.hotelName = hotelName
  },
  SET_CONFIRMATION_NUMBER(state, confirmationNumber) {
    state.hotelInformation.confirmationNumber = confirmationNumber
  },
  SET_NAME_ON_RESERVATION(state, nameOnReservation) {
    state.hotelInformation.nameOnReservation = nameOnReservation
  },
}

export const actions = {
  setHotelInformation({ commit }, hotelInformation) {
    commit('SET_HOTEL_INFORMATION', hotelInformation)
  },
  setAddress({ commit }, address) {
    commit('SET_ADDRESS', address)
  },
  setHotelName({ commit }, hotelName) {
    commit('SET_HOTEL_NAME', hotelName)
  },
  setConfirmationNumber({ commit }, confirmationNumber) {
    commit('SET_CONFIRMATION_NUMBER', confirmationNumber)
  },
  setNameOnReservation({ commit }, nameOnReservation) {
    commit('SET_NAME_ON_RESERVATION', nameOnReservation)
  },
  selectAddress({ dispatch }, place) {
    const address = place.place
    dispatch('setAddress', address)

    const title = place.defaultAddressTitle
    const isHotelNameSet = !!state.hotelInformation?.hotelName
    if (!isHotelNameSet && title) {
      dispatch('setHotelName', title)
    }
  },
  async submit({ state }) {
    const tripId = state.hotelInformation.tripId
    const payload = deepClone(state.hotelInformation)
    delete payload.tripId
    if (tripId === 0) {
      return
    }
    return updateDriverHotelInfo(tripId, payload)
  },
}
