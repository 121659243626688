import axios from 'axios'
import { Chaperone } from '@/classes/Chaperone'
import { baseUrl } from '@/utils/env'
const host = 'https://' + baseUrl()

export const state = () => ({
  chaperones: [],
})

export const getters = {
  chaperones: (state) => state.chaperones,
}

export const mutations = {
  setChaperones(state, chaperones) {
    state.chaperones = chaperones
  },

  addChaperone(state, chaperone) {
    state.chaperones.push(chaperone)
  },

  deleteChaperone(state, chaperone) {
    state.chaperones = state.chaperones.filter(
      (c) => c.externalId !== chaperone.externalId
    )
  },

  updateChaperone(state, chaperone) {
    const index = state.chaperones.findIndex(
      (c) => c.externalId === chaperone.externalId
    )
    state.chaperones.splice(index, 1, chaperone)
  },

  linkCustomerAccount(state, { chaperone, customerAccount }) {
    const index = state.chaperones.findIndex(
      (c) => c.externalId === chaperone.externalId
    )
    state.chaperones[index].customerAccounts.push(customerAccount)
  },

  unLinkCustomerAccount(state, { chaperone, customerAccount }) {
    const index = state.chaperones.findIndex(
      (c) => c.externalId === chaperone.externalId
    )
    state.chaperones[index].customerAccounts = state.chaperones[
      index
    ].customerAccounts.filter(
      (c) => c.customerAccountId !== customerAccount.customerAccountId
    )
  },
}

export const actions = {
  async fetchChaperones({ commit }) {
    const response = await axios.get(`${host}/chaperone`)
    commit(
      'setChaperones',
      response.data.chaperones.map((c) => new Chaperone(c))
    )
  },
  async addChaperone({ commit }, chaperone) {
    const response = await axios.post(`${host}/chaperone`, chaperone)

    const newChaperone = new Chaperone(response.data.chaperone)
    commit('addChaperone', newChaperone)

    return newChaperone
  },
  async deleteChaperone({ commit }, chaperone) {
    const response = await axios.delete(
      `${host}/chaperone/${chaperone.externalId}`
    )

    if (response.status === 200) {
      commit('deleteChaperone', chaperone)
    }
    // TODO: handle 4xx and 5xx responses
  },
  async updateChaperone({ commit }, chaperone) {
    const response = await axios.put(
      `${host}/chaperone/${chaperone.externalId}`,
      chaperone
    )
    commit('updateChaperone', response.data.chaperone)
  },
  async linkCustomerAccount({ commit }, { chaperone, customerAccount }) {
    const response = await axios.put(
      `${host}/chaperone/${chaperone.externalId}/customerAccounts/${customerAccount.customerAccountId}`
    )
    if (response.status === 200) {
      commit('linkCustomerAccount', { chaperone, customerAccount })
    }
  },
  async unLinkCustomerAccount({ commit }, { chaperone, customerAccount }) {
    const response = await axios.delete(
      `${host}/chaperone/${chaperone.externalId}/customerAccounts/${customerAccount.customerAccountId}`
    )
    if (response.status === 200) {
      commit('unLinkCustomerAccount', { chaperone, customerAccount })
    }
    // TODO: handle 4xx and 5xx responses
  },
}
