export const sort = function () {
  let arrayOfSort = []
  let esSort = {}
  const get = function () {
    return arrayOfSort
  }

  const formatProp = function (p = '') {
    return p.replace(/\//g, '.')
  }

  const add = function (sortItem) {
    const prop = formatProp(sortItem.prop)
    if (typeof prop === 'undefined' || !prop) {
      return
    }
    arrayOfSort = []
    arrayOfSort.push(['sort[0][field]', formatProp(sortItem.prop)])
    arrayOfSort.push(['sort[0][dir]', sortItem.direction])
    esSort = [
      {
        [prop]: {
          order: sortItem.direction,
        },
      },
    ]
  }

  const set = function (sortList) {
    arrayOfSort = sortList
  }

  const asQueryParams = function () {
    return arrayOfSort
      .filter((f) => f[0] !== null)
      .map((f) => `${f[0]}=${f[1]}`)
      .join('&')
  }

  const asESSort = function () {
    return esSort
  }

  return {
    get,
    set,
    add,
    asESSort,
    asQueryParams,
  }
}
