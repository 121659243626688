<template>
  <button
    :id="$attrs.id"
    :class="classes"
    :style="cssVariables"
    @click="onClick"
  >
    <div class="cr-btn__content">
      <slot />
    </div>
    <div v-if="loading" class="cr-btn__loader">
      <CRProgressCircular :size="loadingSize" :color="computedTextColor" />
    </div>
  </button>
</template>

<script>
import { toCamel } from '@/cr/utils/string'
import CRColor from '@/cr/color/CRColor'

const DEFAULT_TEXT_COLOR_BY_THEME = {
  isLight: new CRColor('black').toHexString(),
  isDark: new CRColor('white').toHexString(),
}
const FONT_SIZES = {
  xSmall: '10px',
  small: '12px',
  default: '14px',
  large: '18px',
  xLarge: '24px',
}
const BUTTON_SIZES = {
  xSmall: 20,
  small: 28,
  default: 36,
  large: 44,
  xLarge: 52,
}
export default {
  name: 'CRButton',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    textColor: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = 'cr-btn'
      classes = this.disabled ? `${classes} cr-btn--disabled` : classes
      classes = this.plain ? `${classes} cr-btn--plain` : classes
      classes = this.loading ? `${classes} cr-btn--loading` : classes
      classes =
        this.outline && !this.plain ? `${classes} cr-btn--outlined` : classes
      return classes
    },
    cssVariables() {
      return {
        '--btn-color': this.btnColor,
        '--text-color': this.computedTextColor,
        '--font-size': this.fontSize,
        '--button-size': this.buttonSize,
        '--button-min-width': `${Math.round(
          this.buttonSize * 1.777777777777778
        )}px`,
      }
    },
    crColor() {
      return new CRColor(this.color)
    },
    btnColor() {
      if (!this.hasBg) {
        return 'none'
      }
      if (this.disabled) {
        return this.disabledColor()
      }
      return this.crColor.toHexString()
    },
    hasBg() {
      return !this.outline && !this.plain
    },
    computedTextColor() {
      if (this.textColor) {
        return new CRColor(this.textColor).toHexString()
      }
      if (!this.hasBg) {
        return this.disabled ? this.disabledColor() : this.crColor.toHexString()
      }
      return DEFAULT_TEXT_COLOR_BY_THEME[
        this.crColor.isLight() ? 'isLight' : 'isDark'
      ]
    },
    fontSize() {
      return FONT_SIZES[toCamel(this.size)]
    },
    buttonSize() {
      return BUTTON_SIZES[toCamel(this.size)]
    },
    loadingSize() {
      return this.buttonSize * (2 / 3)
    },
  },
  methods: {
    disabledColor() {
      const lightenPercent = 25 * (this.crColor.isLight() ? -1 : 1)
      return this.crColor
        .clone()
        .desaturate(100)
        .lighten(lightenPercent)
        .toHexString()
    },
    onClick(event) {
      if (!this.disabled || !this.loading) {
        this.$emit('click', event)
        this.$el.blur()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$btn-color: var(--btn-color);
$btn-text-color: var(--text-color);
$btn-font-size: var(--font-size);
$btn-size: var(--button-size);
$btn-icon-size: calc(var(--button-size) * 0.67px);
$btn-min-width: var(--button-min-width);

$btn-transition-duration: 0.3s;
$btn-transition-function: cubic-bezier(0.4, 0, 0.2, 1);
$btn-transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);

$btn-border-radius: 5px;
$btn-font-weight: 500;
$btn-outline-border-width: thin;

$btn-focus-opacity: 0.24;
$btn-hover-opacity: 0.08;
$btn-active-opacity: 0.3;

.cr-btn {
  color: $btn-text-color !important; // temp
  background-color: $btn-color !important;
  align-items: center;
  border-radius: $btn-border-radius;
  display: inline-flex;
  flex: 0 0 auto;
  cursor: pointer;
  outline: 0;
  justify-content: center;
  position: relative;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  transition-duration: $btn-transition-duration;
  transition-property: background-color, box-shadow, transform, opacity;
  transition-timing-function: $btn-transition-function;
  font-weight: $btn-font-weight;
  font-size: $btn-font-size;

  &:before {
    background-color: currentColor;
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: $btn-transition;
  }

  &:hover::before {
    opacity: $btn-hover-opacity;
    transition: cubic-bezier(0.22, 0.81, 0.01, 0.99);
  }

  &:focus::before {
    opacity: $btn-focus-opacity;
  }

  &:active::before {
    opacity: $btn-active-opacity;
    transition: cubic-bezier(0.1, 0.9, 0.9, 0.1);
  }

  &:not(.cr-btn--round) {
    height: calc(var(--button-size) * 1px);
    padding: 0 calc(var(--button-size) * 0.4444444444px);
    min-width: $btn-min-width;
  }

  > .cr-btn__content .cr-icon {
    color: inherit;
  }

  .cr-btn__content {
    align-items: center;
    color: currentColor;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
    transition: inherit;
    transition-property: opacity;
    display: flex;
    align-items: center;

    .cr-icon {
      display: flex;
      height: $btn-icon-size;
      width: $btn-icon-size;
    }
  }

  .cr-btn__loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.cr-btn--disabled {
    cursor: default;
    pointer-events: none;
  }

  &.cr-btn--loading {
    pointer-events: none;
    transition: none;
    cursor: default;
    .cr-btn__content {
      opacity: 0 !important;
    }
  }

  &.cr-btn--outlined {
    border: $btn-outline-border-width solid currentColor;
  }

  &.cr-btn--plain {
    &::before {
      display: none;
    }

    &:not(.cr-btn--active):not(.cr-btn--loading):not(:focus):not(:hover) {
      .cr-btn__content {
        opacity: 0.62;
      }
    }
  }
}
</style>
