import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'

import { resolveEnvironment, EnvironmentName } from "@/utils/env";

function datadogEnvName(): string | null {
  if (process.env.VUE_APP_DD_ENV_OVERRIDE) {
    // This is useful when testing Datadog integration or setup during local development,
    // but shouldn't be used outside of that.
    return process.env.VUE_APP_DD_ENV_OVERRIDE
  }

  const environmentName: EnvironmentName = resolveEnvironment();

  if (environmentName === EnvironmentName.dev) {
    return 'dev'
  } else if (environmentName === EnvironmentName.staging) {
    return 'staging'
  } else if (environmentName === EnvironmentName.production) {
    return 'production'
  } else {
    return null
  }
}

/**
 * Update the Datadog current session with information about the active user.
 */
export function setUserContext(id: string | null, email: string | null, name: string | null) {
  datadogRum.setUser({
    id: id,
    email: email,
    name: name
  })
}

/**
 * Update the Datadog current session with information about the active user.
 */
export function clearUserContext() {
  datadogRum.stopSession()
  datadogRum.clearUser()
}

export function initDatadog(): void {
  const DATADOG_ENV = datadogEnvName()

  if (DATADOG_ENV !== null) {
    console.log("Initializing datadog for environment '" + DATADOG_ENV + "'")

    // we're running in one of our deployed environments, initialize datadog
    const DATADOG_APPLICATION_ID = 'b2871275-b42b-408b-b4dd-5c8a18ec2d61'
    const DATADOG_CLIENT_TOKEN = 'pub6465b6c152a8b68327f3a8ab2337ea4c'
    const DATADOG_SITE = 'us5.datadoghq.com'

    const DATADOG_SERVICE = 'coachrail-ui'
    const DATADOG_APP_VERSION = process.env.VUE_APP_BUILD_VERSION || 'unknown'

    console.log("App version '" + DATADOG_APP_VERSION + "'")

    const DATADOG_CONFIG: RumInitConfiguration & LogsInitConfiguration = {
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,

      service: DATADOG_SERVICE,
      env: DATADOG_ENV,
      version: DATADOG_APP_VERSION,

      // Browser RUM config
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',

      // Enable the client-side collection of split.io information
      enableExperimentalFeatures: ["feature_flags"],

      // Browser APM tracing config
      traceSampleRate: 20,
      traceContextInjection: 'sampled',
      allowedTracingUrls: [
        {
          match: /https:\/\/.*\.coachrail\.com/,
          propagatorTypes: ['datadog', 'tracecontext'],
        },
      ],

      // Logging configuration
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
      forwardReports: 'all'
    }

    // Initialize Datadog log collection
    datadogLogs.init(DATADOG_CONFIG);

    // Set up browser session tracking and APM
    datadogRum.init(DATADOG_CONFIG)
  } else {
    console.log('Skipping datadog instrumentation')
  }
}
