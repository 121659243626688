<template>
  <div class="cr-menu" :style="cssVars">
    <slot name="activator" :on="toggle"></slot>
    <transition name="fade">
      <div v-if="isOpen" class="cr-menu__dropdown">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'CRMenu',
  props: {
    offsetX: {
      type: Boolean,
      default: false,
    },
    offsetY: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      activatorHeight: 0,
      activatorWidth: 0,
    }
  },
  computed: {
    offsetXVariable() {
      let offset = '0'

      if (this.left) {
        if (this.offsetX) {
          offset = `calc(-100% + (-${this.activatorWidth}px))`
        } else {
          offset = '-100%'
        }
      } else if (!this.offsetX) {
        offset = `-${this.activatorWidth}px`
      }

      return offset
    },
    offsetYVariable() {
      let offset = '0'

      if (this.top) {
        if (this.offsetY) {
          offset = '-100%'
        } else {
          offset = '-66%'
        }
      } else if (this.bottom) {
        if (this.offsetY) {
          offset = `${this.activatorHeight}px`
        } else {
          offset = `${this.activatorHeight * 0.66}px`
        }
      }

      return offset
    },

    cssVars() {
      return {
        '--offset-x': this.offsetXVariable,
        '--offset-y': this.offsetYVariable,
      }
    },
  },
  mounted() {
    this.setActivatorDimensions()
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    },
    setActivatorDimensions() {
      let { clientHeight, clientWidth } = this.$el
      this.activatorHeight = clientHeight
      this.activatorWidth = clientWidth
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.cr-menu {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 14px;

  &__dropdown {
    background: $white;
    display: inline-block;
    box-shadow: 0px 5px 5px -3px rgba($black-base, 0.2),
      0px 8px 10px 1px rgba($black-base, 0.14),
      0px 3px 14px 2px rgba($black-base, 0.12);
    z-index: 100;
    position: absolute;
    transform: translateX(var(--offset-x)) translateY(var(--offset-y));

    // Replacement for v-list and v-list-tile; do we want to move these to their own class?
    ::v-deep ul {
      list-style-type: none;
      padding: 8px 0;
      white-space: nowrap;
      margin: 0;

      li {
        height: 24px;
        line-height: 24px;
        font-weight: 400;
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        transition: background-color 0.1s ease-in;
        cursor: pointer;

        &:hover {
          background-color: $background-hover;
        }

        a {
          color: $primary;
        }
      }
    }
  }
}
</style>
