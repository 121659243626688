<template>
  <div>
    <div
      ref="addressSelectorParent"
      class="cr-address-selector position-relative"
    >
      <CRTextField
        :id="$attrs.id || ''"
        :append-icon="addressFilled ? 'replay' : null"
        class="cr-address-selector"
        input-class="cr-address-selector-input"
        :readonly="addressFilled"
        :name="generateUUID()"
        :autocomplete="'nope'"
        :label="label"
        :error-messages="errorMessages"
        :value="addressValue"
        :rules="rules"
        :disabled="disabled"
        :placeholder="placeholder"
        :legacy-border="legacyBorder"
        @append-icon-click="clear"
        @input="addressAutoComplete"
        @blur="handleBlur"
        @focus="handleFocus"
        @keyup.native="handleKeyEvent"
      />
      <template v-if="shouldShowDropdownList">
        <div
          class="cr-address-selector__dropdown-items background-white position-absolute left-0 z-100"
          :style="dropdownStyle"
        >
          <v-window v-model="hierarchy.length">
            <v-window-item>
              <ul class="padding-y-2 padding-x-0 margin-a-0 white-space-nowrap">
                <CRAddressSelectorV2PrefillItem
                  v-for="(item, index) in filteredPrefillItems"
                  :key="`li-prefill-item-${index}`"
                  :item="item"
                  :selected="arrowPosition === index"
                  :prefill-numbering-key="prefillNumberingKey"
                  :index="index"
                  @click="selectPrefillItem(item)"
                />
                <v-divider
                  v-show="filteredPrefillItems.length > 0 && items.length > 0"
                  class="padding-x-4 margin-y-2"
                />
                <CRAddressSelectorV2AutocompleteItem
                  v-for="(item, index) in items"
                  :key="`li-autocomplete-item-${index}`"
                  :item="item"
                  :selected="
                    arrowPosition === index + filteredPrefillItems.length
                  "
                  :user-latitude="userLatitude"
                  :user-longitude="userLongitude"
                  @click="selectAutocompleteItem(item)"
                  @click:open-children="openChildren(item)"
                />
              </ul>
            </v-window-item>
            <v-window-item
              v-for="(level, levelIndex) in hierarchy"
              :key="`sub-address-selector-level-${levelIndex}`"
            >
              <v-layout
                align-center
                class="padding-a-3 cursor-pointer"
                @click.stop="backUp"
              >
                <CRIcon view-box="0 0 24 24" width="24" height="24">
                  keyboard_arrow_left
                </CRIcon>
                <span class="margin-a-0">Back</span>
              </v-layout>
              <CRAddressSelectorV2AutocompleteItem
                v-for="(child, childIndex) in level.childAddresses"
                :key="`autocomplete-item-child-${childIndex}-${index}`"
                :item="child"
                :user-latitude="userLatitude"
                :user-longitude="userLongitude"
                :selected="arrowPosition === childIndex"
                @click="selectAutocompleteItem(child)"
                @click:open-children="openChildren(child)"
              />
            </v-window-item>
          </v-window>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { v4 as generateUUID } from 'uuid'
import places from '@/services/places'
import axios from 'axios'
import { KeyCode } from '@/utils/enum'
import { deepClone } from '@/utils/deepClone'

export default {
  name: 'CRAddressSelectorV2',
  props: {
    disabled: Boolean,
    errorMessages: { type: Array, default: () => [] },
    extendItemBoundary: Boolean,
    label: { type: String, default: undefined },
    placeholder: { type: String, default: '' },
    prefillItems: { type: Array, default: () => [] },
    prefillNumberingKey: { type: String, default: undefined },
    rules: { type: Array, default: () => [] },
    displayField: { type: String, default: '' },
    legacyBorder: { type: Boolean },
  },
  data() {
    return {
      generateUUID,
      userLatitude: null,
      userLongitude: null,
      isDropdownOpen: false,
      isFocused: false,
      addressValue: '',
      items: [],
      arrowPosition: undefined,
      dropdownRightMargin: 0,
      focusedAddress: null,
      hierarchy: [],
      input: '',
    }
  },
  computed: {
    filteredPrefillItems() {
      if (this.hierarchy.length) {
        return []
      }
      const key = this.prefillNumberingKey || 'prefillNumberingKey'
      return this.prefillItems
        .map((item, i) => {
          item[key] = key ? item[key] || i : i
          return item
        })
        .filter((item) => {
          const matchTitle = item?.title
            ? item.title.toUpperCase().includes(this.input.toUpperCase())
            : false
          return (
            item?.addressName
              ?.toUpperCase()
              ?.includes(this.input.toUpperCase()) || matchTitle
          )
        })
    },
    shouldShowDropdownList() {
      return (
        this.isDropdownOpen &&
        !this.addressFilled &&
        (this.currentTierItems.length > 0 || this.prefillItems.length > 0)
      )
    },
    showDivider() {
      return (
        this.filteredPrefillItems.length > 0 && this.currentTierItems.length > 0
      )
    },
    dropdownStyle() {
      return `right: ${this.dropdownRightMargin}px`
    },
    addressFilled() {
      return !!this.addressValue
    },
    currentTierItems() {
      if (!this.hierarchy.length) {
        return this.items
      }
      return this.hierarchy[this.hierarchy.length - 1].childAddresses
    },
  },
  mounted() {
    this.calculateDropdownMargin()
    this.approximateUserLocation()
  },
  created() {
    this.addEventListeners()
  },
  beforeDestroy() {
    this.removeEventListeners()
  },
  methods: {
    addEventListeners() {
      window.addEventListener('resize', this.calculateDropdownMargin)
      window.addEventListener('click', this.outsideClickHandler)
    },
    removeEventListeners() {
      window.removeEventListener('resize', this.calculateDropdownMargin)
      window.removeEventListener('click', this.outsideClickHandler)
    },
    handleBlur() {
      this.hasFocus = false
      this.$emit('blur')
    },
    handleFocus() {
      this.isDropdownOpen = !this.addressFilled
      this.hasFocus = true
    },
    async approximateUserLocation() {
      try {
        const response = await axios.get('https://ipapi.co/json/')
        const { latitude, longitude } = response?.data
        this.userLatitude = latitude
        this.userLongitude = longitude
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },
    outsideClickHandler(e) {
      let { target } = e
      if (
        typeof target?.className === 'string' &&
        !target.className.includes('cr-address-selector')
      ) {
        this.isDropdownOpen = false
      }
    },
    calculateDropdownMargin() {
      const { innerWidth: pageWidth } = window
      if (pageWidth < 1263) {
        this.dropdownRightMargin = 0
        return
      }

      const stopTypeContainer = document.querySelector('.stop-type-container')
      if (!stopTypeContainer) {
        return
      }

      const { offsetWidth: width } = stopTypeContainer
      if (!width) {
        return
      }

      this.dropdownRightMargin = 0 - Math.abs(width)
    },
    selectItem(place, description, defaultAddressTitle) {
      place = deepClone(place)
      delete place?.childKnownAddressIds
      this.$nextTick(() => {
        this.$emit('change:place', {
          place,
          description,
          defaultAddressTitle,
        })
      })

      this.setDisplayValue(place, description)
      this.isDropdownOpen = false
    },
    async selectAutocompleteItem(placeItem) {
      if (!placeItem) {
        return
      }

      const { types } = placeItem
      const isStreetAddress =
        types.includes('street_address') || types.includes('premise')

      const defaultAddressTitle = isStreetAddress
        ? null
        : placeItem?.description?.split(',')[0]?.trim()

      const { description, id: placeId, source } = placeItem

      const placeReponse = await places.detailV2(placeId, source)
      const place = placeReponse?.data?.data

      this.selectItem(place, description, defaultAddressTitle)
    },
    selectPrefillItem(address) {
      const { addressName: description, title: defaultAddressTitle } = address
      const place = { ...address }
      this.selectItem(place, description, defaultAddressTitle)
    },
    setDisplayValue(place, description) {
      if (this.displayField && place?.[this.displayField]) {
        this.addressValue = place[this.displayField]
      } else {
        this.addressValue = description
      }
    },
    handleKeyEvent(event) {
      const maxPosition =
        this.filteredPrefillItems.length + this.currentTierItems.length - 1
      if (maxPosition < 0) {
        return
      }

      switch (event.keyCode) {
        case KeyCode.ArrowUp:
          this.handleArrowUp(maxPosition)
          break
        case KeyCode.ArrowDown:
          this.handleArrowDown(maxPosition)
          break
        case KeyCode.Enter:
          this.handleEnter()
          break
        case KeyCode.ArrowRight:
          this.handleArrowRight()
          break
        case KeyCode.ArrowLeft:
          this.handleArrowLeft()
          break
        default:
        // Do nothing
      }
    },
    handleArrowUp(maxPosition) {
      if (this.arrowPosition === undefined || this.arrowPosition <= 0) {
        this.arrowPosition = maxPosition
      } else {
        this.arrowPosition--
      }
    },
    handleArrowDown(maxPosition) {
      if (
        this.arrowPosition === undefined ||
        this.arrowPosition === maxPosition
      ) {
        this.arrowPosition = 0
      } else {
        this.arrowPosition++
      }
    },
    handleArrowRight() {
      if (this.arrowPosition < this.filteredPrefillItems.length) {
        return
      }

      const item = this.currentTierItems[
        this.arrowPosition - this.filteredPrefillItems.length
      ]
      if (!item?.childKnownAddressIds?.length) {
        return
      }

      this.openChildren(item)
    },
    handleArrowLeft() {
      this.backUp()
    },
    handleEnter() {
      if (this.arrowPosition < this.filteredPrefillItems.length) {
        this.selectPrefillItem(this.filteredPrefillItems[this.arrowPosition])
      } else {
        this.selectAutocompleteItem(
          this.currentTierItems[
            this.arrowPosition - this.filteredPrefillItems.length
          ]
        )
      }
    },
    async openChildren(item) {
      const childAddresses = await this.getChildAddresses(item)
      const updatedItem = { ...item, childAddresses }

      this.hierarchy.push(updatedItem)
      this.focusedAddress = updatedItem
      this.arrowPosition = 0
    },
    async getChildAddresses(item) {
      if (!item?.childKnownAddressIds?.length) {
        return []
      }
      const childAddressResponse = await places.addressAutoCompleteV2(
        '',
        this.userLatitude,
        this.userLongitude,
        item.id,
        -1
      )
      return childAddressResponse?.data?.data
    },
    backUp() {
      if (!this.hierarchy?.length) {
        return
      }
      this.hierarchy.pop()
      this.focusedAddress = this.hierarchy[this.hierarchy.length - 1]
    },
    clear() {
      this.addressValue = ''
      this.arrowPosition = undefined
      this.items = []
      this.$emit('place-cleared')
    },
    async addressAutoComplete(input) {
      this.input = input
      if (input.length === 0) {
        this.items = []
        this.arrowPosition = undefined
        return
      } else if (typeof input === 'undefined' || input === null) {
        return
      }

      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(async () => {
        this.loading = true
        this.arrowPosition = undefined
        let data
        if (input) {
          const addressDataResponse = await places.addressAutoCompleteV2(
            input,
            this.userLatitude,
            this.userLongitude,
            null,
            5
          )
          data = addressDataResponse?.data?.data
          this.loading = false
        }
        this.items = data || []
      }, 250)
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-address-selector {
  .cr-text-field {
    z-index: 3;
    position: relative;
  }

  &__dropdown-items {
    box-shadow: 0px 5px 5px -3px rgba($black-base, 0.2),
      0px 8px 10px 1px rgba($black-base, 0.14),
      0px 3px 14px 2px rgba($black-base, 0.12);
    top: calc(100% - 20px);
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
