import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { HotelRequirement } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Creates a trip hotel requirement.
   * @param payload - the HotelRequirement object.
   * @returns the HotelRequirement object that was created.
   */
  create(payload: HotelRequirement): Promise<AxiosResponse<HotelRequirement>> {
    const url = `https://${baseUrl()}/tripHotelRequirement`
    return httpService.post(url, payload)
  },
  /**
   * Updates a trip hotel requirement.
   * @param tripHotelRequirementId - the id of the hotel requirement to update.
   * @param payload - the HotelRequirement object with updated information.
   * @returns the HotelRequirement object that was updated.
   */
  update(tripHotelRequirementId: number, payload: HotelRequirement): Promise<AxiosResponse<HotelRequirement>> {
    const url = `https://${baseUrl()}/tripHotelRequirement/${tripHotelRequirementId}`
    return httpService.patch(url, payload)
  },
}
