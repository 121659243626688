<template>
  <div>
    <v-row dense>
      <v-col>
        <v-icon :color="color" small style="margin-bottom: 3px">
          {{ icon }}
        </v-icon>
      </v-col>
      <v-col style="padding-left: 0px; margin-top: 2px">
        <slot />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'directions_bus',
    },
    small: {
      type: Boolean,
    },
    color: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/scss/charterup/design';
</style>
