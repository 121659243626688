/**
 * Validates that the given trip has a trip type defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if the trip has a trip type ID, `false` otherwise.
 */
export const hasTripType = (trip: any): boolean => {
  return !!trip?.tripType?.id || !!trip?.tripTypeId
}

/**
 * Validates that all required vehicles within the given trip have quantities and types defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if all required vehicles within the trip have quantities and vehicle type IDs,
 * `false` otherwise.
 */
export const hasVehicleQuantitiesAndTypes = (trip: any): boolean => {
  return trip?.requiredVehicles?.every(
    (vehicle) =>
      !!vehicle?.quantity && (!!vehicle?.vehicleType?.id || !!vehicle?.vehicleTypeId)
  )
}

/**
 * Validates that at the pickup stop on the trip has an address and date defined.
 * @param trip - A trip within the quote form.
 * @returns `true` if the pickup stop on the trip has both an address and pickup or dropOff
 * date, `false` otherwise.
 */
export const hasPickupStopWithAddressAndDate = (trip: any): boolean => {
  return trip?.stops?.some((stop: any) =>
    stop?.address?.country && (stop.pickupDate || stop.dropoffDate)
  ) || false
}
