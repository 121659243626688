var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'cv-wrapper',
    'locale-' + _vm.languageCode(_vm.displayLocale),
    'locale-' + _vm.displayLocale,
    'y' + _vm.periodStart.getFullYear(),
    'm' + _vm.paddedMonth(_vm.periodStart),
    'period-' + _vm.displayPeriodUom,
    'periodCount-' + _vm.displayPeriodCount,
    {
      past: _vm.isPastMonth(_vm.periodStart),
      future: _vm.isFutureMonth(_vm.periodStart),
      noIntl: !_vm.supportsIntl,
    },
  ],attrs:{"aria-label":"Calendar"}},[_c('div',{staticClass:"cv-header-days"},[_vm._l((_vm.weekdayNames),function(label,index){return [_vm._t("dayHeader",function(){return [_c('div',{key:_vm.getColumnDOWClass(index),staticClass:"cv-header-day",class:_vm.getColumnDOWClass(index)},[_vm._v(" "+_vm._s(label)+" ")])]},{"index":_vm.getColumnDOWClass(index),"label":label})]})],2),_c('div',{staticClass:"cv-weeks"},[_c('div',{staticClass:"cv-week"},[_c('div',{staticClass:"cv-weekdays"},[_vm._l((_vm.daysOfWeek(_vm.showDate)),function(day,dayIndex){return _c('div',{key:_vm.getColumnDOWClass(dayIndex),class:[
            'cv-day',
            _vm.getColumnDOWClass(dayIndex),
            'd' + _vm.isoYearMonthDay(day),
            'd' + _vm.isoMonthDay(day),
            'd' + _vm.paddedDay(day),
            {
              today: _vm.isSameDate(day, _vm.today()),
              outsideOfMonth: !_vm.isSameMonth(day, _vm.defaultedShowDate),
              past: _vm.isInPast(day),
              future: _vm.isInFuture(day),
              last: _vm.isLastDayOfMonth(day),
              lastInstance: _vm.isLastInstanceOfMonth(day),
            },
            ...((_vm.dateClasses && _vm.dateClasses[_vm.isoYearMonthDay(day)]) || []),
          ],attrs:{"draggable":_vm.enableDateSelection,"aria-label":day.getDate().toString()}},[_c('div',{staticClass:"cv-day-number"},[_vm._v(_vm._s(day.getDate()))]),_vm._t("dayContent",null,{"day":day})],2)}),_vm._l((_vm.getItems()),function(i){return [_vm._t("item",null,{"value":i})]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }