import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const namespaced = true

export const state = {
  newCreateBlock: undefined,
  newRemoveBlock: undefined,
  newUpdateBlock: undefined,
  refreshDispatch: false,
  showBlockDialog: false,
  blockDialogMode: 'add',
  editBlock: undefined,
}

export const getters = {
  newCreateBlock() {
    return state.newCreateBlock
  },
  newRemoveBlock() {
    return state.newRemoveBlock
  },
  newUpdateBlock() {
    return state.newUpdateBlock
  },
  refreshDispatch() {
    return state.refreshDispatch
  },
  showBlockDialog() {
    return state.showBlockDialog
  },
  blockDialogMode() {
    return state.blockDialogMode
  },
  editBlock() {
    return state.editBlock
  },
}

export const mutations = {
  SET_NEW_CREATE_BLOCK(state, block = {}) {
    state.newCreateBlock = block
  },
  SET_NEW_REMOVE_BLOCK(state, block) {
    state.newRemoveBlock = block
  },
  SET_NEW_UPDATE_BLOCK(state, block) {
    state.newUpdateBlock = block
  },
  SET_EDIT_BLOCK(state, block) {
    state.editBlock = block
  },
  SET_REFRESH_DISPATCH(state, value) {
    state.refreshDispatch = value
  },
  SET_BLOCK_DIALOG_MODE(state, value) {
    state.blockDialogMode = value
  },
  TOGGLE_BLOCK_DIALOG(state, value) {
    state.showBlockDialog = value
  },
}

export const actions = {
  async getDispatchData(store, params) {
    const { payload, companyId } = params
    const host = baseUrl()
    const url = `https://${host}/tripAssignments/getDispatchView`
    return axios.post(url, payload)
  },
  async getDispatchDataAsBroker(store, params) {
    const { payload } = params
    const host = baseUrl()
    const url = `https://${host}/tripAssignments/v2/getDispatchViewAsBroker`
    return axios.post(url, payload)
  },
  async createBlockDispatch(store, blockPayload) {
    const host = baseUrl()
    const url = `https://${host}/dispatch/createBlock`
    return axios.post(url, blockPayload)
  },
  async removeBlockDispatch(store, blockPayload) {
    const host = baseUrl()
    const { dispatchId } = blockPayload
    const url = `https://${host}/dispatch/removeBlock/${dispatchId}`
    return axios.delete(url)
  },
  async updateBlockDispatch(store, blockPayload) {
    const host = baseUrl()
    const { dispatchId } = blockPayload
    const url = `https://${host}/dispatch/updateBlock/${dispatchId}`
    return axios.post(url, blockPayload)
  },
}
