import { getDatetimeFromDateAndTimeStrings } from '@/utils/time'
import { DateTime } from 'luxon'
import { Trip } from '@/classes/Trip'
import { TripAddress } from '@/classes/TripAddress'
import { Address } from '@/classes/Address'
import { Note } from '@/classes/Note'
import { TripVehicleGroup } from '@/classes/TripVehicleGroup'
import { TripVehicle } from '@/classes/TripVehicle'
import { TripDate } from '@/classes/TripDate'
import { store } from '@/state/store'
import { PaymentTypeId, PaymentStageKey, PricingMethod } from '@/utils/enum'
import { deepClone } from '@/utils/deepClone'
import {
  hasTripType,
  hasVehicleQuantitiesAndTypes,
  hasPickupStopWithAddressAndDate,
} from '@/utils/trip'

export const scrubQuoteForAPI = (
  quote,
  convertTripIdToIndex = false,
  isCapturePaymentStagesEnabled = false
) => {
  quote.customerId = quote.customer.id
  quote.priceForAllPricingMethods = true

  if (quote.customer && quote.customer.bookings) {
    if (quote.customer.bookings === '--') {
      quote.customer.bookings = null
    }
  }

  if (quote.leadSource && quote.leadSource.id) {
    quote.leadSourceTypeId = quote.leadSource.id
  }

  quote.leadSourceType = quote.leadSource

  if (quote.checkoutPage && quote.checkoutPage.id) {
    quote.checkoutPageId = quote.checkoutPage.id
  }

  let tripIndex = 0
  quote.trips.forEach((trip) => {
    delete trip.checkoutTypeId
    delete trip.tripContacts
    if (convertTripIdToIndex) {
      trip.tripId = tripIndex
    }

    if (quote.pricingMethod === 'single_price') {
      trip.vehicleNeededEntireTrip = false
    }

    trip.customerId = quote.customerId || trip.customerId
    trip.vehicles = trip.requiredVehicles
    for (const vehicle of trip.vehicles) {
      if (vehicle.vehicleType?.id) {
        vehicle.vehicleTypeId = vehicle.vehicleType?.id
      }
    }
    delete trip.requiredVehicles
    trip.tripContactId = trip?.tripContactIsCustomer
      ? quote.customerId
      : trip?.tripContact?.customerId
    for (const rate of trip.rates) {
      rate.rateTypeId = rate.rateType.id
    }
    for (const charge of trip.charges) {
      charge.chargeTypeId = charge.chargeType.id
    }
    if (trip?.stops) {
      for (const stop of trip.stops) {
        if (stop.address) {
          stop.address.name = stop.address.addressName

          if (stop.address.postalCode === null) {
            stop.address.postalCode = ''
          }
        }

        if (!stop.active) {
          stop.active = true
        }

        const timeZone = stop?.address?.timeZone
        if (stop.pickupDate) {
          stop.pickupDatetime = getDatetimeFromDateAndTimeStrings(
            stop.pickupDate,
            stop.pickupTime,
            timeZone
          )
        }
        if (stop.spotTime?.spotTime) {
          stop.spotTime.spotTime = DateTime.fromISO(stop.spotTime.spotTime)
            .setZone(timeZone, { keepLocalTime: true })
            .toISO()
        }

        if (stop.dropoffDate) {
          stop.dropoffDatetime = getDatetimeFromDateAndTimeStrings(
            stop.dropoffDate,
            stop.dropoffTime,
            timeZone
          )
        }
      }
    }

    if (isCapturePaymentStagesEnabled && trip.paymentType.id) {
      const isBalanceMethodsEnabled = isBalancePaymentMethodEnabled(trip)
      const checkoutPaymentMethods = trip?.stagePaymentMethods?.checkoutPaymentMethods || []
      const balancePaymentMethods = trip?.stagePaymentMethods?.balancePaymentMethods || []
      const allowedPaymentMethodsMap = {}

      for (const checkoutPaymentMethod of checkoutPaymentMethods) {
        if (typeof checkoutPaymentMethod.isAllowed === 'boolean') {
          checkoutPaymentMethod.isAllowed = checkoutPaymentMethod.isAllowed ? 1 : 0
        }
        checkoutPaymentMethod.paymentStage = PaymentStageKey.Checkout
        allowedPaymentMethodsMap[checkoutPaymentMethod.paymentMethodId] = checkoutPaymentMethod.isAllowed
      }

      if (isBalanceMethodsEnabled) {
        for (const balancePaymentMethod of balancePaymentMethods) {
          if (typeof balancePaymentMethod.isAllowed === 'boolean') {
            balancePaymentMethod.isAllowed = balancePaymentMethod.isAllowed ? 1 : 0
          }
          balancePaymentMethod.paymentStage = PaymentStageKey.RemainingBalance
        }
      } else {
        trip.stagePaymentMethods.balancePaymentMethods = deepClone(checkoutPaymentMethods)
        trip.stagePaymentMethods.balancePaymentMethods.forEach(
          (method) => { method.paymentStage = PaymentStageKey.RemainingBalance }
        )
      }

      for (const paymentMethod of trip.paymentMethods) {
        paymentMethod.paymentMethodTypeId = paymentMethod.paymentMethodType.id
        paymentMethod.isAllowed = allowedPaymentMethodsMap[paymentMethod.paymentMethodTypeId]
      }
    } else {
      delete trip.stagePaymentMethods
      if (trip?.paymentMethods) {
        for (const paymentMethod of trip.paymentMethods) {
          paymentMethod.paymentMethodTypeId = paymentMethod.paymentMethodType.id
          paymentMethod.isAllowed = paymentMethod.isAllowed ? 1 : 0
        }
      }
    }

    if (trip?.tripType?.id) {
      trip.tripTypeId = trip.tripType.id
    }
    if (trip?.paymenyType?.id) {
      trip.paymentTypeId = trip.paymentType.id
    }

    tripIndex++
  })
  return quote
}

export const convertTripDTOsToRouteTrips = (trips) => {
  if (!trips) {
    return []
  }
  let newTrips = []
  for (const tripData of trips) {
    const trip = new Trip()
    trip.tripId = tripData.tripId
    trip.routeName = tripData.routeName
    trip.tripTypeId = tripData.tripTypeId
    trip.passengerCount = tripData.passengerCount
    trip.requiredDrivers = tripData.requiredDrivers
    trip.description = tripData.description
    trip.notes = tripData.notes
    trip.isTripContactSelected = tripData.istripContactSelected
    trip.processingFeePercentage = tripData.processingFeePercentage
    trip.tripContact = tripData.tripContact
    trip.tripContactIsCustomer =
      tripData.tripContact?.customerId !== null &&
      tripData.tripContact?.customerId === tripData.customer?.customerId
    trip.tripNotes = tripData.tripNotes
    trip.rates = tripData.rates
    trip.charges = tripData.charges
    trip.recurrences = tripData.recurrences
    trip.paymentMethods = tripData.paymentMethods
    trip.paymentType = tripData.paymentType
    trip.paymentTypeId = tripData.paymentTypeId
    trip.dueDate = tripData.dueDate
    trip.depositPercentage = tripData.depositPercentage
    trip.pricingMarket = tripData.pricingMarket

    trip.tripAddresses = convertTripAddressDTOsToTripAddresses(
      tripData.tripAddresses
    )
    trip.tripVehicleGroups = convertTripVehicleGroupDTOsToTripVehicleGroups(
      tripData.tripVehicleGroups,
      tripData.stops
    )

    newTrips.push(trip)
  }
  return newTrips
}

export const convertTripAddressDTOsToTripAddresses = (tripAddressDTOs) => {
  const tripAddresses = []
  for (const [tripAddressIndex, tripAddressData] of tripAddressDTOs.entries()) {
    const tripAddressHash =
      tripAddressData.tripAddressHash || tripAddressData.tripAddressId
    const tripAddress = new TripAddress({
      tripAddressHash: tripAddressHash.toString(),
      orderIndex: tripAddressIndex,
      tripAddressId: tripAddressData.tripAddressId,
    })
    const address = new Address(tripAddressData.address)
    tripAddress.address = address
    if (tripAddressData.tripAddressNotes?.[0]) {
      tripAddress.tripAddressNotes[0] = new Note(
        tripAddressData.tripAddressNotes[0]
      )
    }
    tripAddresses.push(tripAddress)
  }
  return tripAddresses
}

export const convertTripVehicleGroupDTOsToTripVehicleGroups = (
  tripVehicleGroupDTOs,
  stopDTOs
) => {
  const vehicleTypes = store.getters['types/vehicleTypes']
  let tripVehicleGroups = []
  for (const vehicleGroupData of tripVehicleGroupDTOs) {
    const tripVehicleGroupHash =
      vehicleGroupData.tripVehicleGroupHash?.toString() ||
      vehicleGroupData.tripVehicleGroupId?.toString()
    const tripVehicleGroup = new TripVehicleGroup({
      tripVehicleGroupHash,
      tripVehicleGroupIndex: tripVehicleGroups.length,
      tripVehicleGroupId: vehicleGroupData.tripVehicleGroupId,
      parentTripVehicleGroupId: vehicleGroupData.parentTripVehicleGroupId,
    })
    let tripVehicles = []
    for (const tripVehicleData of vehicleGroupData.tripVehicles) {
      const tripVehicle = new TripVehicle({
        vehicleTypeId: tripVehicleData.vehicleTypeId,
        quantity: tripVehicleData.quantity,
        vehicleOrderIndex: null,
        vehicleTypes,
        tripVehicleId: tripVehicleData.tripVehicleId,
      })
      tripVehicles.push(tripVehicle)
    }
    tripVehicleGroup.tripVehicles = tripVehicles
    const filteredStops = stopDTOs.filter((stop) => {
      const id = stop.tripVehicleGroupHash || stop.tripVehicleGroupId
      return id?.toString() === tripVehicleGroupHash
    })
    tripVehicleGroup.tripVehicleGroupStops = filteredStops
    let datetimesByTripAddressHash = {}
    for (const stop of filteredStops) {
      const tripAddressHash =
        stop.tripAddressHash?.toString() || stop.tripAddressId?.toString()
      if (stop.pickupDate || stop.pickupDatetime) {
        if (
          tripAddressHash &&
          !datetimesByTripAddressHash?.hasOwnProperty(tripAddressHash)
        ) {
          datetimesByTripAddressHash[tripAddressHash] = {}
        }
        const date =
          stop.pickupDate ||
          DateTime.fromISO(stop.pickupDatetime, {
            zone: stop.address.timeZone,
          }).toFormat('yyyy-MM-dd')
        if (
          !datetimesByTripAddressHash[tripAddressHash]?.hasOwnProperty(date)
        ) {
          datetimesByTripAddressHash[tripAddressHash][date] = []
        }
        const time =
          stop.pickupTime ||
          DateTime.fromISO(stop.pickupDatetime, {
            zone: stop.address.timeZone,
          }).toFormat('HH:mm')
        datetimesByTripAddressHash[tripAddressHash][date].push(time)
      } else if (stop.dropoffDate || stop.dropoffDatetime) {
        const finalDropoffDate = {
          tripAddressHash: tripAddressHash,
          date:
            stop.dropoffDate ||
            DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            }).toFormat('yyyy-MM-dd'),
          time:
            stop.dropoffTime ||
            DateTime.fromISO(stop.dropoffDatetime, {
              zone: stop.address.timeZone,
            }).toFormat('HH:mm'),
        }
        tripVehicleGroup.finalDropoffDate = finalDropoffDate
      }
    }
    for (const [tripAddressHash, timesByDate] of Object.entries(
      datetimesByTripAddressHash
    )) {
      for (const [date, times] of Object.entries(timesByDate)) {
        let tripDate = new TripDate(tripAddressHash)
        tripDate.date = date
        tripDate.times = times
        tripVehicleGroup.tripDates.push(tripDate)
      }
    }
    tripVehicleGroup.name = vehicleGroupData.name
    tripVehicleGroups.push(tripVehicleGroup)
  }
  if (tripVehicleGroups.length) {
    tripVehicleGroups[0].selected = true
  }
  return tripVehicleGroups
}

export const isBalancePaymentMethodEnabled = (trip) => {
  return trip.paymentType.id === PaymentTypeId.DownPayment
}

export const isMarketplaceQuote = (quote) => {
  if (quote && quote.pricingMethod &&
      (quote.pricingMethod == PricingMethod.Bids ||
      quote.pricingMethod == PricingMethod.Category)) {
      return true
  }
  return false
}

/**
 * Determines whether a quote can be automatically priced in the quote form
 * using basic requirements of the included trips – each has a trip type, all required
 * vehicles have quantities and vehicle types, and there is at least one stop with
 * both an address and date.
 * @param quote – A quote being created in the quote form.
 * @returns `true` if a quote should be sent for pricing, `false` otherwise.
 */
export const shouldAutoPriceQuote = (quote) => {
  for (const trip of quote.trips) {
    if (
      !hasTripType(trip) ||
      !hasVehicleQuantitiesAndTypes(trip) ||
      !hasPickupStopWithAddressAndDate(trip)
    ) {
      return false
    }
  }
  return true
}
