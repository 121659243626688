import tripHotelRequirement from '@/services/tripHotelRequirement'
import { deepClone } from '@/utils/deepClone'

export const state = {
  hotelRequirement: null,
}

export const getters = {
  hotelRequirement(state) {
    return state.hotelRequirement
  },
  isRequired(state) {
    return state.hotelRequirement?.isRequired
  },
  checkIn(state) {
    return state.hotelRequirement?.checkIn
  },
  checkOut(state) {
    return state.hotelRequirement?.checkOut
  },
  tripId(state) {
    return state.hotelRequirement?.tripId
  },
}

export const mutations = {
  SET_HOTEL_REQUIREMENT(state, hotelRequirement) {
    state.hotelRequirement = hotelRequirement
  },
  SET_IS_REQUIRED(state, isRequired) {
    state.hotelRequirement.isRequired = isRequired
  },
  SET_CHECK_IN(state, checkIn) {
    state.hotelRequirement.checkIn = checkIn
  },
  SET_CHECK_OUT(state, checkOut) {
    state.hotelRequirement.checkOut = checkOut
  },
  SET_TRIP_ID(state, tripId) {
    state.hotelRequirement.tripId = tripId
  },
}

export const actions = {
  setHotelRequirement({ commit }, hotelRequirement) {
    hotelRequirement = {
      checkIn: null,
      checkOut: null,
      isRequired: null,
      tripId: null,
      ...hotelRequirement,
    }
    commit('SET_HOTEL_REQUIREMENT', hotelRequirement)
  },
  setIsRequired({ commit }, isRequired) {
    commit('SET_IS_REQUIRED', isRequired)
  },
  setCheckIn({ commit }, checkIn) {
    commit('SET_CHECK_IN', checkIn)
  },
  setCheckOut({ commit }, checkOut) {
    commit('SET_CHECK_OUT', checkOut)
  },
  setTripId({ commit }, tripId) {
    commit('SET_TRIP_ID', tripId)
  },
  async submit({ state, dispatch }) {
    const hotelRequirement = state?.hotelRequirement
    if (!hotelRequirement || !hotelRequirement.tripId) {
      return
    }
    if (hotelRequirement.tripHotelRequirementId) {
      return dispatch('update')
    } else {
      return dispatch('create')
    }
  },
  async update({ state }) {
    const payload = deepClone(state.hotelRequirement)
    const tripHotelRequirementId = state.hotelRequirement.tripHotelRequirementId
    return tripHotelRequirement.update(tripHotelRequirementId, payload)
  },
  async create({ state }) {
    const payload = deepClone(state.hotelRequirement)
    return tripHotelRequirement.create(payload)
  },
}
