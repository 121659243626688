import Vue from 'vue'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { setRequestConfig } from '../authTokenRepository'
import { store } from '@/state/store'

export class HttpService {
  private service = axios.create()

  constructor() {
    this.service = axios.create()
    this.service.interceptors.request.use(
      this.handleRequest,
      this.handleRequestError
    )
    this.service.interceptors.response.use(this.handleResponse, this.handleResponseError)
    this.service.defaults.headers.common['Application-Origin'] = 'coachrail'
  }

  handleRequest = async (config) => {
    // Do something before request is sent
    // If request is different than any of the URLS in urlsExcludedForBearerHeader
    // then send Authorization header with token from localstorage
    return await setRequestConfig(config)
  }

  handleRequestError = async (error) => Promise.reject(error)

  handleResponse = async (response) => response

  handleResponseError = async (error) => {
    if (error.response && error.response.status === 401) {
      // If we're still authenticated in the client, our refresh token must have expired
      if (Vue.prototype.$auth0.isAuthenticated && store) {
        store.dispatch('app/showAlert', {
          message: `Your credentials have expired. Please reauthenticate yourself.`,
          type: 'error',
          hasAction: true,
          action: async () => {
            await Vue.prototype.$auth0.loginWithPopup()
          },
        })
      } else {
        await store.dispatch('auth/logOut')
      }
    }

    return Promise.reject(error)
  }

  post<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.post<P, AxiosResponse<T>>(url, payload, config)
  }

  patch<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.patch<P, AxiosResponse<T>>(url, payload, config)
  }

  put<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.put<P, AxiosResponse<T>>(url, payload, config)
  }

  get<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.get<P, AxiosResponse<T>>(url, config)
  }

  delete<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.service.delete<P, AxiosResponse<T>>(url, config)
  }
}
