<template>
  <div>
    <div v-if="editor && !RichTextFeatureDisabled">
      <label>{{ label }}</label>
      <div
        :class="
          disabled ? 'rich-text-container disabled' : 'rich-text-container'
        "
      >
        <EditorContent :editor="editor" />
      </div>
      <div v-if="!readOnly && !disabled" class="menu-container">
        <CRIcon
          material
          :height="25"
          :width="25"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click.native="editor.chain().focus().toggleBold().run()"
        >
          bold
        </CRIcon>
        <CRIcon
          material
          :height="25"
          :width="25"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click.native="editor.chain().focus().toggleItalic().run()"
        >
          italic
        </CRIcon>
        <CRIcon
          material
          :height="25"
          :width="25"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click.native="editor.chain().focus().toggleStrike().run()"
        >
          strikethrough
        </CRIcon>
        <CRIcon
          material
          :height="25"
          :width="25"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click.native="editor.chain().focus().toggleBulletList().run()"
        >
          list
        </CRIcon>
        <CRIcon
          id="link-on"
          :height="25"
          :width="25"
          style="padding-top: 4px; padding-left: 5px"
          :class="{ 'is-active': editor.isActive('link') }"
          @click.native="
            editor.isActive('link')
              ? editor.chain().focus().unsetLink().run()
              : (link.showModal = true)
          "
        >
          link
        </CRIcon>
      </div>
      <v-dialog v-model="link.showModal" width="400px" persistent>
        <v-card class="cr-modal">
          <div>
            <v-card-title>
              <h2>Insert Link</h2>
            </v-card-title>
            <v-card-text>
              <v-layout padded row align-center>
                <v-flex xs12>
                  <CRInput
                    id="link-modal-text-input"
                    v-model="link.text"
                    label="Text"
                    type="text"
                    placeholder="Insert text"
                  />
                </v-flex>
              </v-layout>
              <v-layout padded row align-center>
                <v-flex xs12>
                  <CRInput
                    id="link-modal-url-input"
                    v-model="link.url"
                    label="Link"
                    type="text"
                    placeholder="Insert url"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-layout justify-end>
                <v-btn
                  id="link-modal-close"
                  class="btn-secondaryaction"
                  @click="
                    link.showModal = false
                    link.url = ''
                    link.text = ''
                  "
                >
                  Close
                </v-btn>
                <v-btn
                  id="link-modal-save"
                  class="mr-0 btn-primaryaction"
                  @click="setLink"
                >
                  Save
                </v-btn>
              </v-layout>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div v-else-if="RichTextFeatureDisabled">
      <CRTextArea
        v-model="inputVal"
        v-bind="$attrs"
        :placeholder="placeholder"
        :label="label"
        :disabled="disabled"
        :rows="$attrs.rows || 4"
      />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

export default {
  components: {
    EditorContent,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    note: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
      RichTextFeatureDisabled: false,
      link: {
        showModal: false,
        url: '',
        text: '',
      },
      textValue: '',
    }
  },

  computed: {
    inputVal: {
      get() {
        return this.note
      },
      set(val) {
        this.$emit('textchange', val)
      },
    },
    editable() {
      return !this.readOnly && !this.disabled
    },
  },

  watch: {
    note(value) {
      if (this.RichTextFeatureDisabled) {
        this.$emit('textchange', value)
        return
      }

      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
    editable(value) {
      this.editor.setOptions({ editable: value })
    },
    'link.showModal'(newVal, oldVal) {
      if (!oldVal) {
        const { from, to } = this.editor.state.selection
        this.link.text = this.editor.state.doc.textBetween(from, to, ' ')
      }
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.note,
      editable: !this.readOnly && !this.disabled,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: true,
          autolink: true,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        this.$emit('htmlchange', this.editor.getHTML())
        this.$emit('textchange', this.editor.getText())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeUnmount() {
    this.editor.destroy()
  },

  methods: {
    setLink() {
      let addHttp = this.link.url.includes('http') ? '' : 'http://'
      let linkToAdd =
        '<a href="' +
        addHttp +
        this.link.url +
        '" target="_blank">' +
        this.link.text +
        '</a>'
      this.editor.commands.insertContent(linkToAdd)

      this.link.showModal = false
      this.link.url = ''
      this.link.text = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.label {
  display: block;
  margin-bottom: 8px;
}

.rich-text-container {
  background-color: #f5f7fb !important;
  border: 1px solid #eaebee !important;
  color: $shofur-text !important;
  outline: none;
  box-shadow: none;
  margin-top: 4px;
  padding: 0px 12px 0px 12px;
}

.disabled {
  background-color: #accbe3 !important;
}

::v-deep .ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: $gray-medium-light;
  pointer-events: none;
  height: 0;
}

::v-deep .ProseMirror {
  margin: 12px 0px 12px 0px;
  padding: 7px 0px 8px 0px;
  outline: none;

  &:hover {
    border-color: $gray-light;
  }

  &:focus,
  &:focus:hover {
    border-color: $primary;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }
}
.menu-container {
  background-color: #f5f7fb !important;
  border: 1px solid #eaebee;
  border-top: none;
  color: $shofur-text !important;
  outline: none;
  box-shadow: none;
  padding-top: 6px;
  padding-left: 4px;
}

.cr-icon {
  background: none;
  padding: 0 0.2rem;
  opacity: 0.6;
  border-radius: 4px;

  &:hover,
  &.is-active {
    opacity: 1;
    background-color: $gray-mid-light;
  }
}
</style>
