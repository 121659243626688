import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult, KnownAddress } from '@/models/dto'
import { CoordinatesDistance } from '@/models/dto/CoordinatesDistance'

const httpService: HttpService = new HttpService()

export default {
  tableView(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<KnownAddress>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/knownAddress?${query}`)
  },
  create(knownAddressDTO: KnownAddress): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/knownAddress`
    return httpService.post(url, knownAddressDTO)
  },
  update(knownAddressId: number, knownAddressDTO: KnownAddress): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/knownAddress/${knownAddressId}`
    return httpService.patch(url, knownAddressDTO)
  },
  delete(knownAddressId: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/knownAddress/${knownAddressId}`
    return httpService.delete(url)
  },
  byId(knownAddressId: number): Promise<AxiosResponse<KnownAddress>> {
    const url = `https://${baseUrl()}/knownAddress/${knownAddressId}`
    return httpService.get(url)
  },
  parentsByDistanceFromLatLng(coordinatesDistanceDTO: CoordinatesDistance): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/knownAddress/parent/coordinates`
    return httpService.post(url, coordinatesDistanceDTO)
  },
  topLevelParentById(knownAddressId: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/knownAddress/parent/${knownAddressId}`
    return httpService.get(url)
  },
}
