var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"addressSelectorParent",staticClass:"cr-address-selector"},[_c('CRTextField',{staticClass:"cr-address-selector",attrs:{"id":_vm.$attrs.id ? _vm.$attrs.id : '',"append-icon":_vm.addressFilled ? 'replay' : null,"input-class":"cr-address-selector-input","readonly":_vm.addressFilled,"name":_vm.v4(),"autocomplete":'nope',"label":_vm.label,"error-messages":_vm.errorMessages,"value":_vm.addressValue,"rules":_vm.rules,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"legacy-border":_vm.legacyBorder},on:{"append-icon-click":_vm.clearField,"input":_vm.addressAutoComplete,"blur":() => {
          _vm.hasFocus = false
          _vm.$emit('blur')
        },"focus":() => {
          _vm.isOpen = !_vm.addressFilled
          _vm.hasFocus = true
        }},nativeOn:{"keyup":function($event){return _vm.handleKeyEvent.apply(null, arguments)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isOpen &&
        !_vm.addressFilled &&
        (_vm.autocompleteItems.length > 0 || _vm.prefillItems.length > 0)
      ),expression:"\n        isOpen &&\n        !addressFilled &&\n        (autocompleteItems.length > 0 || prefillItems.length > 0)\n      "}],staticClass:"cr-address-selector__dropdown-items",style:(`right: ${_vm.dropdownRightMargin}px`)},[_c('ul',{staticClass:"cr-address-selector__dropdown-items__list"},[_vm._l((_vm.displayedPrefillItems),function(item,index){return _c('li',{key:`li-prefill-item-${index}`,class:`cr-address-selector__dropdown-items__list__item ${
            _vm.arrowPosition === index
              ? 'cr-address-selector__dropdown-items__list__item--focused'
              : ''
          }`,staticStyle:{"padding":"0 16px 0 10px"},on:{"click":() => _vm.selectPrefillItem(_vm.displayedPrefillItems[index])}},[_c('div',{staticClass:"cr-address-selector__dropdown-items__list__item__icon"},[_c('p',[_vm._v(_vm._s(item.key ? item.key : index + 1))]),_c('CRIcon',{attrs:{"width":32,"height":32,"view-box":"0 0 24 24"}},[_vm._v(" location_stop_filled ")])],1),_c('p',{staticClass:"cr-address-selector__dropdown-items__list__item__description"},[(item.place.title)?_c('span',[_vm._v(_vm._s(item.place.title)+" -")]):_vm._e(),_vm._v(" "+_vm._s(item.description)+" ")])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.displayedPrefillItems.length > 0 && _vm.autocompleteItems.length > 0
          ),expression:"\n            displayedPrefillItems.length > 0 && autocompleteItems.length > 0\n          "}],staticClass:"cr-address-selector__dropdown-items__list__divider"}),_vm._l((_vm.autocompleteItems),function(item,index){return _c('li',{key:`li-autocomplete-item-${index}`,class:`cr-address-selector__dropdown-items__list__item ${
            _vm.arrowPosition === index + _vm.displayedPrefillItems.length
              ? 'cr-address-selector__dropdown-items__list__item--focused'
              : ''
          }`,on:{"click":() => _vm.selectAutocompleteItem(_vm.autocompleteItems[index])}},[_c('img',{staticClass:"cr-address-selector__dropdown-items__list__item__avatar",attrs:{"src":require("@/assets/images/dropoff_completed.png")}}),_c('p',{staticClass:"cr-address-selector__dropdown-items__list__item__description"},[_vm._v(" "+_vm._s(item.description)+" ")])])})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }