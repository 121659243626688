<template>
  <div :class="classes">
    <CRIcon v-if="overflowLeft" class="cr-tabs_scroll-left" @click.native="scrollLeft">
      keyboard_arrow_left
    </CRIcon>
    <div ref="tabs-bar" class="cr-tabs__bar">
      <slot />
      <span ref="tabs-highlighter" class="cr-tab__highlighter"></span>
    </div>
    <CRIcon
      v-if="overflowRight"
      class="cr-tabs_scroll-right"
      @click.native="scrollRight"
    >
      keyboard_arrow_right
    </CRIcon>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [],
      tabBar: null,
      overflowRight: 0,
      overflowLeft: 0,
      activeTab: null,
    }
  },
  computed: {
    classes() {
      let classes = 'cr-tabs'
      return classes
    },
  },
  watch: {
    value() {
      this.selectTab(this.value)
    },
    'tabs.length'() {
      this.selectTab(this.value)
      this.checkOverflow()
      this.tabs.forEach((tab) => {
        tab.$off('click')
        tab.$on('click', (event) => {
          this.updateValue(event)
        })
      })
    },
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.tabs = this.$children.filter((child) => {
      return child.classes.includes('cr-tab')
    })
    this.selectTab(this.value)
    this.tabBar = this.$refs['tabs-bar']
  },
  beforeDestroy() {
    this.tabs.forEach((tab) => {
      tab.$off('click')
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    selectTab(activeTabIndex) {
      if (this.tabs.length > 0) {
        if (activeTabIndex < 0) {
          this.updateValue(0)
        } else if (activeTabIndex >= this.tabs.length) {
          this.updateValue(this.tabs.length - 1)
        }
        this.tabs.forEach((tab, tabIndex) => {
          tab.activeTabIndex = activeTabIndex
          tab.index = tabIndex
        })
        this.activeTab = this.tabs[activeTabIndex]
        this.setHighlighterPositionAndWidth()
      }
    },
    updateValue(value) {
      this.$emit('input', value)
    },
    handleWindowResize() {
      this.setHighlighterPositionAndWidth()
      this.checkOverflow()
    },
    checkOverflow() {
      this.overflowLeft = this.tabBar.scrollLeft
      this.overflowRight =
        this.tabBar.scrollWidth -
        this.tabBar.clientWidth -
        this.tabBar.scrollLeft
    },
    setHighlighterPositionAndWidth() {
      const highlight = this.$refs['tabs-highlighter']
      highlight.style.width = `${this.activeTab.$el.clientWidth}px`
      highlight.style.left = `${this.activeTab.$el.offsetLeft}px`
    },
    scrollLeft() {
      const scroll =
        this.overflowLeft < this.tabBar.clientWidth
          ? this.overflowLeft
          : this.tabBar.clientWidth
      this.tabBar.scrollLeft -= scroll
      this.setHighlighterPositionAndWidth()
      this.checkOverflow()
    },
    scrollRight() {
      const scroll =
        this.overflowRight < this.tabBar.clientWidth
          ? this.overflowRight
          : this.tabBar.clientWidth
      this.tabBar.scrollLeft += scroll
      this.setHighlighterPositionAndWidth()
      this.checkOverflow()
    },
  },
}
</script>

<style lang="scss" scoped>
$tabs-bar-height: 48px !default;

.cr-tabs {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  align-items: center;
  width: 100%;

  .cr-tabs__bar {
    position: relative;
    border-radius: inherit;
    height: $tabs-bar-height;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  .cr-tab__highlighter {
    height: 2px;
    background-color: $primary;
    position: absolute;
    bottom: 2px;
    transition: all 0.25s ease;
  }
  .cr-tabs__scroll-left,
  .cr-tabs__scroll-right {
    display: flex;
  }
}
</style>
