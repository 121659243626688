
import { Vue, Component, Prop } from 'vue-property-decorator'
import { alwaysShowTenthsPlace } from '@/utils/string'
import StarRating from 'vue-star-rating'

@Component({
  components: {
    StarRating,
  },
})
export default class CharterUPRating extends Vue {
  @Prop({ type: Number, default: 0 }) readonly rating: number
  @Prop({ type: Number, default: 14 }) readonly starSize: number

  get formattedRating(): string {
    return alwaysShowTenthsPlace(this.rating)
  }
}
