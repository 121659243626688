import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  assignmentsLoading: false,
  timelineLoading: false,
  showConflictDetail: false,
  showConfirmationDetail: false,
  validateTripLoading: false,
  showSendDriverInfo: false,
  showAddStopNote: false,
  showAddContactNote: false,
  showAddTripContactNote: false,
  showAddTripContact: false,
  showAddTripNotes: false,
  dispatchActiveStop: {},
}

export const getters = {
  assignmentsLoading: (state) => state.assignmentsLoading,
  timelineLoading: (state) => state.timelineLoading,
  showConflictDetail: (state) => state.showConflictDetail,
  showConfirmationDetail: (state) => state.showConfirmationDetail,
  validateTripLoading: (state) => state.validateTripLoading,
  showSendDriverInfo: (state) => state.showSendDriverInfo,
  showAddStopNote: (state) => state.showAddStopNote,
  showAddContactNote: (state) => state.showAddContactNote,
  showAddTripContactNote: (state) => state.showAddTripContactNote,
  showAddTripContact: (state) => state.showAddTripContact,
  showAddTripNotes: (state) => state.showAddTripNotes,
  dispatchActiveStop: (state) => state.dispatchActiveStop,
}

export const mutations = {
  SET_ASSIGNMENTS_LOADING(state, { assignmentsLoading }) {
    state.assignmentsLoading = assignmentsLoading
  },
  SET_TIMELINE_LOADING(state, { timelineLoading }) {
    state.timelineLoading = timelineLoading
  },
  SET_SHOW_DISPATCH_SCREEN(state, showConflictDetail) {
    state.showConflictDetail = showConflictDetail
  },
  SET_SHOW_CONFIRMATION_DETAIL(state, showConfirmationDetail) {
    state.showConfirmationDetail = showConfirmationDetail
  },
  SET_VALIDATE_TRIP_LOADING(state, validateTripLoading) {
    state.validateTripLoading = validateTripLoading
  },
  SET_SHOW_SEND_DRIVER_INFO(state, showSendDriverInfo) {
    state.showSendDriverInfo = showSendDriverInfo
  },
  SET_SHOW_ADD_STOP_NOTE(state, showAddStopNote) {
    state.showAddStopNote = showAddStopNote
  },
  SET_SHOW_ADD_CONTACT_NOTE(state, showAddContactNote) {
    state.showAddContactNote = showAddContactNote
  },
  SET_SHOW_ADD_TRIP_CONTACT_NOTE(state, showAddTripContactNote) {
    state.showAddTripContactNote = showAddTripContactNote
  },
  SET_SHOW_ADD_TRIP_CONTACT(state, showAddTripContact) {
    state.showAddTripContact = showAddTripContact
  },
  SET_SHOW_ADD_TRIP_NOTES(state, showAddTripNotes) {
    state.showAddTripNotes = showAddTripNotes
  },
  SET_DISPATCH_ACTIVE_STOP(state, dispatchActiveStop) {
    state.dispatchActiveStop = dispatchActiveStop
  },
}

export const actions = {
  setLoading(
    store,
    { assignmentsLoading, timelineLoading, validateTripLoading }
  ) {
    if (assignmentsLoading != null) {
      store.commit('SET_ASSIGNMENTS_LOADING', { assignmentsLoading })
    }
    if (timelineLoading != null) {
      store.commit('SET_TIMELINE_LOADING', { timelineLoading })
    }

    if (validateTripLoading != null) {
      store.commit('SET_VALIDATE_TRIP_LOADING', { validateTripLoading })
    }
  },
  setShowConflictDetail(store, showConflictDetail) {
    store.commit('SET_SHOW_DISPATCH_SCREEN', showConflictDetail)
  },
  setShowConfirmationDetail(store, showConfirmationDetail) {
    store.commit('SET_SHOW_CONFIRMATION_DETAIL', showConfirmationDetail)
  },
  setShowSendDriverInfo(store, showSendDriverInfo) {
    store.commit('SET_SHOW_SEND_DRIVER_INFO', showSendDriverInfo)
  },
  setShowAddStopNote(store, showAddStopNote) {
    store.commit('SET_SHOW_ADD_STOP_NOTE', showAddStopNote)
  },
  setShowAddContactNote(store, showAddContactNote) {
    store.commit('SET_SHOW_ADD_CONTACT_NOTE', showAddContactNote)
  },
  setShowAddTripContactNote(store, showAddTripContactNote) {
    store.commit('SET_SHOW_ADD_TRIP_CONTACT_NOTE', showAddTripContactNote)
  },
  setShowAddTripContact(store, showAddTripContact) {
    store.commit('SET_SHOW_ADD_TRIP_CONTACT', showAddTripContact)
  },
  setShowAddTripNotes(store, showAddTripNotes) {
    store.commit('SET_SHOW_ADD_TRIP_NOTES', showAddTripNotes)
  },
  setDispatchActiveStop(store, dispatchActiveStop) {
    store.commit('SET_DISPATCH_ACTIVE_STOP', dispatchActiveStop)
  },
}
