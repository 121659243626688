import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  isNotAdminLocal: true,
  billingBaseUrl: '/billing',
}

export const getters = {
  getState() {
    return state
  },
}

export const actions = {
  getReservationArguments(store, reservationId) {
    return ['reservations/reservationById', reservationId]
  },
  getPaymentTypes(store) {
    return axios
      .get(`https://${baseUrl()}/types/billing-payment-types`)
      .then((data) =>
        data.data.map((dat, idx) => {
          dat.paymentType = idx
          return dat
        })
      )
  },
  getRefundTypes(store) {
    baseUrl()
    return axios.get(`https://${baseUrl()}/types/refund-types`).then((data) =>
      data.data.map((dat, idx) => {
        dat.refundType = idx
        return dat
      })
    )
  },
  getCurrentUser(store) {
    try {
      return store.rootState.auth.currentSession.user
    } catch (e) {
      return {
        userId: null,
        company: {
          id: null,
        },
      }
    }
  },
}
