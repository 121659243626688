import tiers from '@/services/tiers'
import { sort } from '@/utils/sort'
import * as logger from '@/utils/logger'

export const state = {
  tiers: [],
  tiersTVMap: [],
  predefinedTiersTVMap: [],
}

export const getters = {
  getTiers(state) {
    return state.tiers
  },
  getTiersTVMap(state) {
    return state.tiersTVMap
  },
  getPredefinedTiersTVMap(state) {
    return state.predefinedTiersTVMap
  },
}

export const mutations = {
  SET_TIERS(state, tiers) {
    state.tiers = tiers
  },
  SET_TIERS_TV_MAP(state, map) {
    state.tiersTVMap = map
  },
  SET_PREDEFINED_TIERS_TV_MAP(state, map) {
    state.predefinedTiersTVMap = map
  },
}

export const actions = {
  async fetchAllTiers({ commit }) {
    try {
      const sorts = sort()
      sorts.add({
        prop: 'level',
        direction: 'asc',
      })
      const params = { sorts: sorts.asQueryParams() }
      const response = await tiers.getTiers(params)
      if (response?.data?.resultList) {
        commit('SET_TIERS', response?.data?.resultList)
      }
    } catch (e) {
      logger.error(e)
    }
  },
  initializeTiersTVSupport({ state, commit }) {
    const tiersTVMap = state.tiers.map((tier) => {
      return { text: tier.label, value: tier.tierId }
    })
    const predefinedTiersTVMap = state.tiers.map((tier) => {
      return {
        text: tier.label,
        controlType: 'default-repeat',
        refreshOnSelect: true,
        withValue: true,
        value: tier.tierId,
      }
    })
    commit('SET_TIERS_TV_MAP', tiersTVMap)
    commit('SET_PREDEFINED_TIERS_TV_MAP', predefinedTiersTVMap)
  },
}
