import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Chat, ChatCompleteRequest } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getChats(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Chat>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}&visibility=ALL`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/chats?${query}`
    return httpService.get(url)
  },
  byId(chatId: number): Promise<AxiosResponse<Chat>> {
    const host = baseUrl()
    const url = `https://${host}/chats/${chatId}`
    return httpService.get(url)
  },
  getChatOutcomes(): Promise<AxiosResponse> {
    return httpService.get(`https://${baseUrl()}/chats/outcomes`)
  },
  getChatReasons(): Promise<AxiosResponse> {
    return httpService.get(`https://${baseUrl()}/chats/reasons`)
  },
  completeChat(conversationSid: string, chatCompleteRequest: ChatCompleteRequest) {
    return httpService.post(`https://${baseUrl()}/chats/complete/${conversationSid}`, chatCompleteRequest)
  }
}
