import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  types: {
    geofenceTypes: [],
    vehicleTypes: [],
    billingFrequencyTypes: [],
  },
}

export const getters = {
  geofenceTypes(state) {
    return state.types.geofenceTypes
  },
  vehicleTypes(state) {
    return state.types.vehicleTypes
  },
  billingPaymentTypes(state) {
    return state.types.billingFrequencyTypes
  },
}

export const mutations = {
  SET_GEOFENCE_TYPES(state, newValue) {
    state.types.geofenceTypes = newValue
  },
  SET_VEHICLE_TYPES(state, newValue) {
    state.types.vehicleTypes = newValue
  },
  SET_BILLING_FREQUENCY_TYPES(state, newValue) {
    state.types.billingFrequencyTypes = newValue
  }
}

export const actions = {
  async init({ dispatch }) {
    await dispatch('getGeofenceTypes')
    await dispatch('getBillingFrequencyTypes')
  },
  getVehicleTypes(params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/vehicleTypes?${query}`

    return axios.get(url)
  },
  getRateTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/rate-types`

    return axios.get(url)
  },
  getPaymentTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/payment-types`

    return axios.get(url)
  },
  getPaymentMethodTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/payment-method-types`

    return axios.get(url)
  },
  getRefundPaymentMethodTypes() {
    const host = baseUrl()
    const url = `https://${host}/refund/paymentMethods`

    return axios.get(url)
  },
  getChargeTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/charge-types`

    return axios.get(url)
  },
  getRefundTypes() {
    return axios.get(`https://${baseUrl()}/types/refund-types`)
  },
  async getBillingPaymentTypes() {
    let result = await axios.get(
      `https://${baseUrl()}/types/billing-payment-types`
    )
    if (result.data) {
      result.data = result.data.filter(
        (paymentType) => paymentType.label !== 'Account Credit'
      )
    }
    return result
  },
  getBillingFrequencyTypes({ commit }) {
    return axios.get(`https://${baseUrl()}/contracts/billingFrequencyTypes`).then(value => {
      commit('SET_BILLING_FREQUENCY_TYPES', value.data)
    })
  },
  getTripTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/trip-types`

    return axios.get(url)
  },
  getMarketRateTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/market-rate-types`

    return axios.get(url)
  },
  getSocialMediaTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/social-media-types`

    return axios.get(url)
  },
  getQuoteTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/quote-types`

    return axios.get(url)
  },
  getTicketTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/ticket-types`

    return axios.get(url)
  },
  getTicketStatusTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/ticket-status-types`

    return axios.get(url)
  },
  getTicketLevels() {
    const host = baseUrl()
    const url = `https://${host}/types/ticket-severity-types`

    return axios.get(url)
  },
  getreferralRejectionReason() {
    const host = baseUrl()
    const url = `https://${host}/types/referral-rejection-reason-types`

    return axios.get(url)
  },
  getPartnerTypes(params) {
    const { pageSize = 15, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/partnerTypes?${query}`

    return axios.get(url)
  },
  getEldTypes(params) {
    const { pageSize = -1, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/eldTypes?${query}`

    return axios.get(url)
  },
  async getGeofenceTypes({ commit }) {
    const url = `https://${baseUrl()}/types/geofence-type`
    const res = await axios.get(url)
    commit('SET_GEOFENCE_TYPES', res.data)
  },
  async setVehicleTypes({ commit }) {
    const pageSize = -1
    const page = 1
    let query = `page=${page}&pageSize=${pageSize}`
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/vehicleTypes?${query}`

    const res = await axios.get(url)
    commit('SET_VEHICLE_TYPES', res.data.resultList)
  },
  getLeadTemperatureTypes(store, params) {
    const { pageSize = -1, page = -1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`

    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/leadTemperatureTypes`
    return axios.get(url)
  },
  getRecurrenceTypes() {
    return axios.get(`/recurrenceTypes`)
  },
  getMarkupTypes() {
    const host = baseUrl()
    const url = `https://${host}/tables/markup-types`
    return axios.get(url)
  },
  getClassificationTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/classification-type`
    return axios.get(url)
  },
  getCompanyCancellationTypes(params) {
    const queryString = Object.keys(params)
      .map((param) => {
        if (!params[param]) {
          return null
        }
        return `${param}=${encodeURIComponent(params[param])}`
      })
      .filter(Boolean)
      .join('&')

    const url = `https://${baseUrl()}/metrics/getCompanyCancellationTypes?${queryString}`
    return axios.get(url)
  },
  getMarketCancellationTypes(params) {
    const queryString = Object.keys(params)
      .map((param) => {
        if (!params[param]) {
          return null
        }
        return `${param}=${encodeURIComponent(params[param])}`
      })
      .filter(Boolean)
      .join('&')

    const url = `https://${baseUrl()}/metrics/getMarketCancellationTypes?${queryString}`
  },
  getNPSResponseTypes() {
    const host = baseUrl()
    const url = `https://${host}/types/nps-type`
    return axios.get(url)
  },
  getRiskTypes() {
    const host = baseUrl()
    const url = `https://${host}/riskType`
    return axios.get(url)
  },
  getMarketVehicleAvailabilityTypes(params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/types/marketVehicleAvailability?${query}`

    return axios.get(url)
  },
}
