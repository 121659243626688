import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  sideBar: true,
}

export const getters = {
  getSideBar(store) {
    return store.sideBar
  },
}

export const mutations = {
  setSideBar(store, status) {
    store.sideBar = status
  },
}

export const actions = {
  unsoldQuotes(store, params) {
    return genericDashboard('dashboard/unsoldQuotes', params)
  },
  pendingReferrals(store, params) {
    return genericDashboard('dashboard/pendingReferrals', params)
  },
  profits(store, params) {
    return genericDashboard('dashboard/profits', params)
  },
  sales(store, params) {
    return genericDashboard('dashboard/sales', params)
  },
  leaderboard(store, params) {
    return genericDashboard('dashboard/leaderboard', params)
  },
  async getCompanyCounts(store) {
    const host = baseUrl()
    const url = `https://${host}/dashboard/count`
    return axios.get(url)
  },
}

function genericDashboard(path, params) {
  let query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
  query = encodeURI(query)
  const url = `https://${baseUrl()}/${path}?${query}`
  return axios.get(url)
}
