import axios from 'axios'

export const EnvironmentName = {
  unknown: 'unknown',
  local: 'local',
  dev: 'dev',
  staging: 'staging',
  production: 'production',
} as const

export type EnvironmentName = (typeof EnvironmentName)[keyof typeof EnvironmentName]

/**
 * Return the environment that this app is running in.
 */
export function resolveEnvironment(): EnvironmentName {
  // TODO: Use this everywhere we need to resolve the environment, to ensure
  // that we're using the same algorithm.

  // Use the hostname from the browser location bar.
  const browserHostname = window.location.host
  const hostParts = browserHostname.split(':')[0].split('.');
  let hostSubdomain = hostParts[0]
  // A special case - if the first element of the hostname is 'checkout', we should
  // use the second part of the hostname to determine the running environment
  if (hostSubdomain === 'checkout') {
    hostSubdomain = hostParts[1]
    if (['shofur', 'gogocharters', 'nationalbuscharter'].includes(hostSubdomain)) {
      return EnvironmentName.production
    }
  }

  if (hostSubdomain === 'localhost') {
    return EnvironmentName.local
  } else if (hostSubdomain === 'dev') {
    return EnvironmentName.dev
  } else if (hostSubdomain === 'auto') {
    return EnvironmentName.staging
  } else if (hostSubdomain === 'app') {
    return EnvironmentName.production
  } else {
    // Couldn't determine the environment!
    console.error("Could not determine environment name from browser location " + browserHostname)
    return EnvironmentName.unknown
  }
}

/**
 * Generates a string for the metadata title and environment indicator in non-production environments
 * @returns The prefix if not in production, otherwise an empty string
 */
export function envTitlePrefix(): string {
  const env = resolveEnvironment()
  return env === EnvironmentName.local ||
  env === EnvironmentName.dev ||
  env === EnvironmentName.staging
    ? `${env.toUpperCase()} `
    : ''
}

/**
 * Generates a prefixed host name if a prefix is provided.
 * @param prefix The optional prefix to prepend to the host name.
 * @returns The host name with prefix if provided, otherwise an empty string.
 */
export const hostPrefix = (prefix?: string): string => (prefix ? `${prefix}.` : '')

// Set up a global property for saveQuote if in development mode
declare global {
  interface Window {
    saveQuote?: boolean
  }
}

if (process.env.NODE_ENV === 'development') {
  // Only want to save quotes from CI right now while in dev mode.
  window.saveQuote = true
}

/**
 * Constructs the base URL for API requests.
 * @param prefix The prefix to prepend to the host, defaults to 'api'.
 * @returns The full base URL constructed based on the environment and optional prefix.
 */
export function baseUrl(prefix: string = 'api'): string {
  let host: string

  // VUE_APP_BASE_URL_OVERRIDE should only be used to connect a local instance
  // of coachrail-ci running at `localhost` or `127.0.0.1` to connect to a specific API
  if (
    process.env.VUE_APP_BASE_URL_OVERRIDE &&
    process.env.NODE_ENV === 'development'
  ) {
    host = process.env.VUE_APP_BASE_URL_OVERRIDE
  } else {
    host = window.location.host.split(':')[0]
    if (host === 'localhost') {
      // Assume a connection to the API through docker
      host = 'dev.coachrail.com'
    }
    if (host.split('.')[0] === 'checkout') {
      if (host.split('.')[1] === 'dev' || host.split('.')[1] === 'auto') {
        host = host.split('.')[1] + '.coachrail.com'
      } else {
        // must be prod
        host = 'app.coachrail.com'
      }
    }
  }

  axios.defaults.baseURL = `https://${hostPrefix(prefix)}${host}`
  return `${hostPrefix(prefix)}${host}`
}

/**
 * Determines the environment prefix based on the current host.
 * @returns The environment prefix based on the current host.
 */
export const environmentPrefix = (): string => {
  let host = window.location.host

  if (host.includes('localhost')) {
    return 'dev'
  }
  let hasHttpTest = /^((http|https|ftp):\/\/)/
  if (hasHttpTest.test(host)) {
    host = host.split('/').find((string) => {
      return string.includes('coachrail')
    }) || host
  }
  let split = host.split('.')
  if (split[0] === 'checkout') {
    return split[1]
  }
  return split[0]
}

/**
 * Returns the base URL for the current host.
 * @returns The base URL for the current host.
 */
export const hostBaseUrl = (): string => {
  const hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  let host = hostSubdomain === 'localhost' ? 'dev.coachrail.com' : `${hostSubdomain}.coachrail.com`
  return `https://${host}`
}

/**
 * Provides a split key based on the subdomain of the current host.
 * @returns A specific key string based on the subdomain.
 */
export const splitKey = (): string => {
  const hostSubdomain = getHostSubdomain()

  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return 'q65ir5g1n7pek8dn6f7rm4n0ua6patbsv69p'
  } else if (hostSubdomain === 'auto') {
    return 'q17bli4sobmq2riile3p8vm6vjdg9rkkcgr4'
  } else if (hostSubdomain === 'app') {
    return '4mfo5hbqg0cbkifhoocbtj26jukglmlbf1o9'
  }

  return ''

}

/**
 * Provides the Auth0 config based on the subdomain of the current host.
 * @returns The Auth0 config based on the subdomain.
 */
export const auth0Config = (): { domain: string, clientId: string } => {
  const hostSubdomain = getHostSubdomain()

  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      domain: 'charterup-dev.us.auth0.com',
      clientId: '6EVt5ZRMhXXNdJYBGUbP352SvBTFfQmB'
    }
  } else if (hostSubdomain === 'auto') {
    // TODO: update this to the correct config when we have it
    return {
      domain: 'charterup-staging.us.auth0.com',
      clientId: 'YFeEkfwzC6jhfvqVSOH4ddRVFOn9ogOm'
    }  } else if (hostSubdomain === 'app') {
    // TODO: update this to the correct config when we have it
    return {
      domain: 'charterup.us.auth0.com',
      clientId: 'tE2lIV5B6FsdO9LP61n7FJXNCzx5s5in'
    }  }

  return {
    domain: '',
    clientId: ''
  }
}

/**
 * Provides the Finix config based on the subdomain of the current host.
 * @returns an environment of either "sandbox" or "live" and and applicationId
 */
export const finixConfig = (): { environment: 'sandbox' | 'live', applicationId: string } => {
  const hostSubdomain = getHostSubdomain()

  if (hostSubdomain === 'localhost' || hostSubdomain === 'dev') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'auto') {
    return {
      environment: "sandbox",
      applicationId: "APigGDxtKyaBfwqMAoM2RVRB"
    }
  } else if (hostSubdomain === 'app') {
    return {
      environment: "live",
      applicationId: "APtkkr768DDXcQttsm2pahvz"
    }
  }

  return null
}

const getHostSubdomain = (): string => {
  let hostSubdomain = window.location.host.split(':')[0].split('.')[0]
  if (hostSubdomain === 'checkout') {
    hostSubdomain = window.location.host.split(':')[0].split('.')[1]
    if (['shofur', 'gogocharters', 'nationalbuscharter'].includes(hostSubdomain)) {
      hostSubdomain = 'app'
    }
  }
  return hostSubdomain
}

// Run once now to set up axios to automatically use the proper baseURL.
baseUrl()
