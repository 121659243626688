import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

import '@mdi/font/css/materialdesignicons.css'

import ReportingIcon from '../icons/ReportingIcon.vue'
import VehiclesIcon from '../icons/VehiclesIcon.vue'
import TicketsIcon from '../icons/TicketsIcon.vue'
import DriversIcon from '../icons/DriversIcon.vue'
import GaragesIcon from '../icons/GaragesIcon.vue'
import CompanyIcon from '../icons/CompanyIcon.vue'
import QuotesIcon from '../icons/QuotesIcon.vue'
import CustomersIcon from '../icons/CustomersIcon.vue'
import LinkIcon from '../icons/LinkIcon.vue'
import FilePDFIcon from '../icons/FilePDFIcon.vue'
import AdminMoneyIcon from '../icons/AdminMoneyIcon.vue'

// Brand (Broker) Checkout Icons
import GogoReliable from '../icons/GogoReliable.vue'
import GogoSafe from '../icons/GogoSafe.vue'
import GogoSupport from '../icons/GogoSupport.vue'

import ShofurHelp from '../icons/ShofurHelp.vue'
import ShofurInsurance from '../icons/ShofurInsurance.vue'
import ShofurSecure from '../icons/ShofurSecure.vue'

// CharterUP Mirroring
import CharterUpLogo from '../icons/charterup/CharterUpLogo.vue'
import TicketIcon from '../icons/charterup/TicketIcon.vue'
import EmailIcon from '../icons/charterup/EmailIcon.vue'
import CalendarIcon from '../icons/charterup/CalendarIcon.vue'
import PhoneIcon from '../icons/charterup/PhoneIcon.vue'
import ArrowSignIcon from '../icons/charterup/ArrowSignIcon.vue'
import TwoArrowSignIcon from '../icons/charterup/TwoArrowSignIcon.vue'
import ShuttleIcon from '../icons/charterup/ShuttleIcon.vue'
import CustomTripIcon from '../icons/charterup/CustomTripIcon.vue'
import QuoteIcon from '../icons/charterup/QuoteIcon.vue'
import InstagramFilledIcon from '../icons/charterup/InstagramFilledIcon.vue'
import TripTypeIcon from '../icons/charterup/TripTypeIcon.vue'

import CRIcon from '../cr/components/CRIcon.vue'

// Color Icon Imports
import ProgressColorIcon from '../icons/charterup/ProgressColorIcon.vue'
import MarkerColorIcon from '../icons/charterup/MarkerColorIcon.vue'
import PhoneColorIcon from '../icons/charterup/PhoneColorIcon.vue'

// Credit Card Imports
import CreditCardVisaIcon from '../icons/charterup/CreditCardVisaIcon.vue'
import CreditCardMastercardIcon from '../icons/charterup/CreditCardMastercardIcon.vue'
import CreditCardDinersIcon from '../icons/charterup/CreditCardDinersIcon.vue'
import CreditCardAmericanExpressIcon from '../icons/charterup/CreditCardAmericanexpressIcon.vue'
import CreditCardDiscoverIcon from '../icons/charterup/CreditCardDiscoverIcon.vue'
import CreditCardDefaultIcon from '../icons/charterup/CreditCardDefaultIcon.vue'

import colors from '@/scss/_colors-export.scss'

Vue.use(Vuetify, {
  iconfont: 'md',
  theme: {
    // Vuetify overrides
    primary: '#3b9cf1',
    secondary: '#edf4fa',
    accent: '#d4e3f2',
    error: '#e10055',
    info: '#1c70bd',
    success: '#7cd074',
    warning: '#ea7721',
    gray: '#606470',
    backgroundColor: '#f4f7fb',

    // CoachRail Brand Styles By Color
    crBlack: '#3e4351',
    crBlue: '#10406C',

    blueAlt: '#259cf7',
    inputBackgroundGray: '#f5f7fb',
    ...colors
  },
  icons: {
    reporting: { component: ReportingIcon },
    vehicles: { component: VehiclesIcon },
    tickets: { component: TicketsIcon },
    drivers: { component: DriversIcon },
    garages: { component: GaragesIcon },
    company: { component: CompanyIcon },
    quotes: { component: QuotesIcon },
    customers: { component: CustomersIcon },
    filePDFIcon: { component: FilePDFIcon },
    adminMoneyIcon: { component: AdminMoneyIcon },
    linkIcon: { component: LinkIcon },

    // Replacements for coachrail-icons.scss
    bookIt: {
      component: CRIcon,
      props: {
        fileName: 'BookIt',
        width: 17,
        height: 17,
      },
    },
    dateSingle: {
      component: CRIcon,
      props: {
        fileName: 'DateSingle',
        width: 15,
        height: 15,
      },
    },
    searchLarge: {
      component: CRIcon,
      props: {
        fileName: 'SearchLarge',
      },
    },
    search: {
      component: CRIcon,
      props: {
        fileName: 'Search',
        viewBox: '0 0 24 24',
      },
    },
    crIconVehicles: {
      component: CRIcon,
      props: {
        fileName: 'Vehicles',
      },
    },
    time: {
      component: CRIcon,
      props: {
        fileName: 'Time',
      },
    },
    fullPayment: {
      component: CRIcon,
      props: {
        fileName: 'FullPayment',
      },
    },
    accessTime: {
      component: CRIcon,
      props: {
        fileName: 'AccessTime',
        viewBox: '0 0 24 24',
      },
    },
    event: {
      component: CRIcon,
      props: {
        fileName: 'Event',
        viewBox: '0 0 24 24',
      },
    },

    // CharterUP Mirroring
    facebook: 'mdi-facebook-box',
    twitter: 'mdi-twitter',
    linkedin: 'mdi-linkedin',
    charterUp: { component: CharterUpLogo },
    ticket: { component: TicketIcon },
    email: { component: EmailIcon },
    calendar: { component: CalendarIcon },
    phone: { component: PhoneIcon },
    arrowSign: { component: ArrowSignIcon },
    twoArrowSign: { component: TwoArrowSignIcon },
    shuttle: { component: ShuttleIcon },
    custom: { component: CustomTripIcon },
    quoteIcon: { component: QuoteIcon },
    instagram: { component: InstagramFilledIcon },
    tripType: { component: TripTypeIcon },

    // Brand (Broker) Checkout Pages

    gogoReliable: { component: GogoReliable },
    gogoSafe: { component: GogoSafe },
    gogoSupport: { component: GogoSupport },
    shofurHelp: { component: ShofurHelp },
    shofurInsurance: { component: ShofurInsurance },
    shofurSecure: { component: ShofurSecure },

    // Color Icons
    'color-progress': { component: ProgressColorIcon },
    'color-marker': { component: MarkerColorIcon },
    'color-phone': { component: PhoneColorIcon },

    // Credit Cards
    //
    // Leave in kebob case as these are swapped out dynamically
    // based upon the string after the dash.
    'creditcard-visa': { component: CreditCardVisaIcon },
    'creditcard-mastercard': { component: CreditCardMastercardIcon },
    'creditcard-diners': { component: CreditCardDinersIcon },
    'creditcard-amex': { component: CreditCardAmericanExpressIcon },
    'creditcard-discover': { component: CreditCardDiscoverIcon },
    'creditcard-default': { component: CreditCardDefaultIcon },
  },
})
