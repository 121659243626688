<template>
  <router-view />
</template>

<script>
import { envTitlePrefix } from '@/utils/env'

export default {
  metaInfo: {
    titleTemplate: `${envTitlePrefix()}%s - CoachRail`
  },
}
</script>
