<template>
  <div :style="cssVariables" class="loader">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        :stroke-width="strokeWidth"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
import CRColor from '@/cr/color/CRColor'

export default {
  props: {
    size: {
      type: Number,
      default: 23,
    },
    strokeWidth: {
      type: Number,
      default: 5,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    cssVariables() {
      return {
        '--stroke-color': new CRColor(this.color).toHexString(),
        '--circle-size': this.size,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$stroke-color: var(--stroke-color);
$width: calc(var(--circle-size) * 1px);

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: $stroke-color;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
</style>
