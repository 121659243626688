export class Rate {
  constructor(rate) {
    this.custom = rate?.custom || null
    this.marketplace = rate?.marketplace || null
    this.rateLabel = rate?.rateLabel || null
    this.rateTypeKey = rate?.rateTypeKey || null
    this.toggled = !!rate?.custom
  }
  toObject() {
    const rate = Object.assign({}, this)
    return rate
  }
}
