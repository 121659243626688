import { DateTime, Duration } from 'luxon'
import * as logger from '@/utils/logger'
import { deepClone } from '@/utils/deepClone'
import trackingDevices from '@/services/trackingDevices'
import onTimeStatus from '@/services/onTimeStatus'

export const state = {
  distanceAndDuration:
    JSON.parse(window.localStorage.getItem('distanceAndDuration')) || {},
  devicesByVehicleIds: {},
}

export const mutations = {
  DISTANCE_AND_DURATION(state, { key, value }) {
    state.distanceAndDuration = deepClone({
      ...state.distanceAndDuration,
      [key]: value,
    })

    // preserve state between page page refreshes
    saveState('distanceAndDuration', state.distanceAndDuration)
  },

  UPDATE_DEVICES_BY_VEHICLE_IDS(state, axiosResponse) {
    const devices = {}
    for (const tracker of axiosResponse.data.devices) {
      devices[tracker.vehicleId] = tracker
    }

    // Use deepClone to force Vue to react to the state change
    state.devicesByVehicleIds = deepClone({
      ...state.devicesByVehicleIds,
      ...devices,
    })
  },
}

export const getters = {
  distanceAndDuration: (state) => (vehicleId, addressId) => {
    return state.distanceAndDuration[`${vehicleId}-${addressId}`]
  },
  devicesByVehicleIds: (state) => (vehicleId) => {
    return state.devicesByVehicleIds[vehicleId]
  },
}

export const actions = {
  async getTrackingDevicesByVehicleIdsV2(store, params) {
    const { requestBody, prioritizeEld } = params
    const response = await trackingDevices.fetchTrackingDevicesByVehicleIdsV2(
      requestBody,
      prioritizeEld
    )
    store.commit('UPDATE_DEVICES_BY_VEHICLE_IDS', response)
    return response
  },
  async getTrackingDevicesByCompanyId(store, companyId) {
    const response = await trackingDevices.fetchTrackingDevicesByCompanyId(
      companyId
    )
    return response
  },
  async calculateDistanceAndDuration(context, params) {
    const { tracker, address } = params

    let directionsService = new google.maps.DirectionsService()
    const addressLocation = {
      lat: address.lat,
      lng: address.lng,
    }

    const request = {
      origin: new google.maps.LatLng(tracker.lat, tracker.lng),
      destination: addressLocation,
      travelMode: 'DRIVING',
    }

    const directionsResult = await directionsService.route(request, function (
      res,
      status
    ) {
      if (status == 'OK') {
        return res
      }
    })

    if (!directionsResult) {
      logger.error('DirectionsService failure')
      return
    }

    const leg = directionsResult.routes[0].legs[0]

    const SECONDS_TO_MILLIS = 1000
    const durationDateTime = Duration.fromMillis(
      leg.duration.value * SECONDS_TO_MILLIS
    )
    const now = DateTime.local().setZone(address.timeZone)
    let arrivalTime = now
      .plus(durationDateTime)
      .setZone(address.timeZone)
      .toISO()
    let value = {
      vehicleId: tracker.vehicleId,
      stop: {
        arrivalTime,
        duration: leg.duration.value,
        timeZone: address.timeZone,
      },
      lastUpdated: DateTime.local().toISO(),
    }

    context.commit('DISTANCE_AND_DURATION', {
      key: `${tracker.vehicleId}-${address.addressId}`,
      value,
    })
  },
  async calculateDistanceAndDurationV2(context, params) {
    const { tracker, address, journeyStopId } = params
    const response = await onTimeStatus.byJourneyStopId(journeyStopId)
    const { eta } = response.data.data

    const MILLISECONDS_TO_SECONDS = 1000
    const duration =
      DateTime.fromISO(eta).diffNow().milliseconds / MILLISECONDS_TO_SECONDS

    let value = {
      vehicleId: tracker.vehicleId,
      stop: {
        arrivalTime: eta,
        duration,
        timeZone: address.timeZone,
      },
      lastUpdated: DateTime.local().toISO(),
    }

    context.commit('DISTANCE_AND_DURATION', {
      key: `${tracker.vehicleId}-${address.addressId}`,
      value,
    })
  },
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
