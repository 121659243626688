import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

// FIXME: env?
const API_KEY = 'AIzaSyBq5i5U_s8Zw-jzbGtNyPMO09_3fc7w13w'

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
    libraries: 'geometry',
  },
})
