import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TrackingSource, TrackingDeviceResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets the trak4 devices for a given list of vehicle IDs.
   * @param requestBody - a list of vehicle IDs.
   * @returns the list of TrackingDevice's.
   */
  fetchTrackingDevicesByVehicleIdsV2(requestBody: { vehicleIds: number[] }, prioritizeEld: boolean = true): Promise<AxiosResponse<TrackingDeviceResult>> {
    const url = `https://${baseUrl()}/v2/tracking/devices/getByVehicleIds?prioritizeEld=${prioritizeEld}`
    return httpService.post(url, requestBody)
  },
  /**
   * Gets the TrackingSources associated to a given companyId.
   * @param companyId - a companyId.
   * @returns a list of TrackingSources.
   */
  fetchTrackingDevicesByCompanyId(companyId: number): Promise<AxiosResponse<TrackingSource[]>> {
    const url = `https://${baseUrl()}/gpsData/view/company/${companyId}`
    return httpService.get(url)
  },
}
