<template>
  <div>
    <div ref="addressSelectorParent" class="cr-address-selector">
      <CRTextField
        :id="$attrs.id ? $attrs.id : ''"
        :append-icon="addressFilled ? 'replay' : null"
        class="cr-address-selector"
        input-class="cr-address-selector-input"
        :readonly="addressFilled"
        :name="v4()"
        :autocomplete="'nope'"
        :label="label"
        :error-messages="errorMessages"
        :value="addressValue"
        :rules="rules"
        :disabled="disabled"
        :placeholder="placeholder"
        :legacy-border="legacyBorder"
        @append-icon-click="clearField"
        @input="addressAutoComplete"
        @blur="
          () => {
            hasFocus = false
            $emit('blur')
          }
        "
        @keyup.native="handleKeyEvent"
        @focus="
          () => {
            isOpen = !addressFilled
            hasFocus = true
          }
        "
      />
      <div
        v-show="
          isOpen &&
          !addressFilled &&
          (autocompleteItems.length > 0 || prefillItems.length > 0)
        "
        class="cr-address-selector__dropdown-items"
        :style="`right: ${dropdownRightMargin}px`"
      >
        <ul class="cr-address-selector__dropdown-items__list">
          <li
            v-for="(item, index) in displayedPrefillItems"
            :key="`li-prefill-item-${index}`"
            :class="`cr-address-selector__dropdown-items__list__item ${
              arrowPosition === index
                ? 'cr-address-selector__dropdown-items__list__item--focused'
                : ''
            }`"
            style="padding: 0 16px 0 10px"
            @click="() => selectPrefillItem(displayedPrefillItems[index])"
          >
            <div class="cr-address-selector__dropdown-items__list__item__icon">
              <p>{{ item.key ? item.key : index + 1 }}</p>
              <CRIcon :width="32" :height="32" view-box="0 0 24 24">
                location_stop_filled
              </CRIcon>
            </div>
            <p
              class="cr-address-selector__dropdown-items__list__item__description"
            >
              <span v-if="item.place.title">{{ item.place.title }} -</span>
              {{ item.description }}
            </p>
          </li>
          <div
            v-show="
              displayedPrefillItems.length > 0 && autocompleteItems.length > 0
            "
            class="cr-address-selector__dropdown-items__list__divider"
          ></div>
          <li
            v-for="(item, index) in autocompleteItems"
            :key="`li-autocomplete-item-${index}`"
            :class="`cr-address-selector__dropdown-items__list__item ${
              arrowPosition === index + displayedPrefillItems.length
                ? 'cr-address-selector__dropdown-items__list__item--focused'
                : ''
            }`"
            @click="() => selectAutocompleteItem(autocompleteItems[index])"
          >
            <img
              class="cr-address-selector__dropdown-items__list__item__avatar"
              src="@/assets/images/dropoff_completed.png"
            />
            <p
              class="cr-address-selector__dropdown-items__list__item__description"
            >
              {{ item.description }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 } from 'uuid'
import places from '@/services/places'

export default {
  name: 'CRAddressSelector',
  props: {
    disabled: Boolean,
    errorMessages: {
      type: Array,
      default: undefined,
    },
    extendItemBoundary: Boolean,
    identifier: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    prefillItems: {
      type: Array,
      default: () => [],
    },
    prefillNumberingKey: {
      type: String,
      default: undefined,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    displayField: {
      type: String,
      default: '',
    },
    legacyBorder: {
      type: Boolean,
    },
    useFormattedDetail: Boolean,
  },
  data() {
    return {
      v4,
      isOpen: false,
      isFocused: false,
      addressValue: '',
      addressFilled: false,
      autocompleteItems: [],
      arrowPosition: undefined,
      displayedPrefillItems: [],
      dropdownRightMargin: 0,
    }
  },
  computed: {
    prefillItemsWithKey() {
      let prefills = this.prefillItems
      for (let i = 0; i < prefills.length; i++) {
        if (this.prefillNumberingKey) {
          let prefillKey = this.prefillItems[i][this.prefillNumberingKey]
          prefills[i].key = prefillKey ? prefillKey : i
        } else {
          prefills[i].key = i
        }
      }
      return prefills
    },
  },
  watch: {
    prefillItemsWithKey(newVal) {
      this.displayedPrefillItems = newVal
    },
  },
  mounted() {
    this.displayedPrefillItems = this.prefillItemsWithKey
    this.calculateDropdownMargin()
  },
  created() {
    window.addEventListener('resize', this.calculateDropdownMargin)
    window.addEventListener('click', this.outsideClickHandler)
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateDropdownMargin)
    window.removeEventListener('click', this.outsideClickHandler)
  },
  methods: {
    outsideClickHandler(e) {
      let { target } = e
      if (
        typeof target?.className === 'string' &&
        !target.className.includes('cr-address-selector')
      ) {
        this.isOpen = false
      }
    },
    calculateDropdownMargin() {
      let { innerWidth: pageWidth } = window
      let dropdownRightMargin = 0
      if (pageWidth > 1263) {
        let stopTypeContainer = document.querySelector('.stop-type-container')
        if (stopTypeContainer) {
          let { offsetWidth: width } = stopTypeContainer
          if (width && width > 0) {
            dropdownRightMargin = 0 - Math.abs(width)
          }
        }
      } else {
        this.dropdownRightMargin = 0
      }

      this.dropdownRightMargin = dropdownRightMargin
    },
    async selectAutocompleteItem(placeItem) {
      if (!placeItem) return

      const { types } = placeItem
      const isStreetAddress =
        types.includes('street_address') || types.includes('premise')

      const defaultAddressTitle = isStreetAddress
        ? null
        : placeItem.structured_formatting?.main_text

      const { description, place_id: placeId } = placeItem

      let placeData
      if (this.useFormattedDetail) {
        placeData = await places.placeDetailFormatted(placeId)
      } else {
        placeData = await places.placeDetail(placeId)
      }

      try {
        const place = this.useFormattedDetail ? placeData : placeData.data
        const { identifier } = this

        this.$nextTick(() => {
          this.$emit('place-selected', {
            identifier,
            place,
            description,
            defaultAddressTitle,
          })
        })

        if (this.displayField && place?.[this.displayField]) {
          this.addressValue = place[this.displayField]
        } else {
          this.addressValue = description
        }
      } catch (e) {
        console.error('Error emitting event while selecting address', e)
      }

      this.isOpen = false
      this.addressFilled = true
    },
    selectPrefillItem(placeItem) {
      const { identifier } = this
      const { description, place, defaultAddressTitle } = placeItem
      this.$nextTick(() => {
        this.$emit('place-selected', {
          identifier,
          place,
          description,
          defaultAddressTitle,
        })
      })

      if (this.displayField && place?.[this.displayField]) {
        this.addressValue = place[this.displayField]
      } else {
        this.addressValue = description
      }
      this.isOpen = false
      this.addressFilled = true
    },
    handleKeyEvent(event) {
      const maxPosition =
        this.displayedPrefillItems.length + this.autocompleteItems.length - 1
      if (maxPosition < 0) return

      // UP ARROW
      if (event.keyCode === 38) {
        if (
          typeof this.arrowPosition === 'undefined' ||
          this.arrowPosition <= 0
        ) {
          this.arrowPosition = maxPosition
        } else {
          this.arrowPosition--
        }
      }

      // DOWN ARROW
      if (event.keyCode === 40) {
        if (
          typeof this.arrowPosition === 'undefined' ||
          this.arrowPosition === maxPosition
        ) {
          this.arrowPosition = 0
        } else {
          this.arrowPosition += 1
        }
      }

      // ENTER
      if (event.keyCode === 13) {
        if (this.arrowPosition < this.displayedPrefillItems.length) {
          this.selectPrefillItem(this.displayedPrefillItems[this.arrowPosition])
        } else {
          this.selectAutocompleteItem(
            this.autocompleteItems[
              this.arrowPosition - this.displayedPrefillItems.length
            ]
          )
        }
      }
    },
    clearField() {
      this.addressValue = ''
      this.addressFilled = false
      this.arrowPosition = undefined
      this.autocompleteItems = []
      this.displayedPrefillItems = this.prefillItemsWithKey
      this.$emit('place-cleared', this.identifier)
    },
    async addressAutoComplete(input) {
      if (input.length === 0) {
        this.displayedPrefillItems = this.prefillItemsWithKey
        this.autocompleteItems = []
        this.arrowPosition = undefined
        return
      } else if (typeof input === 'undefined' || input === null) {
        return
      }

      this.displayedPrefillItems = this.prefillItemsWithKey.filter((item) => {
        const matchTitle = item?.place?.title
          ? item.place.title.toUpperCase().includes(input.toUpperCase())
          : false
        return (
          item.description.toUpperCase().includes(input.toUpperCase()) ||
          matchTitle
        )
      })

      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(async () => {
        this.loading = true
        this.arrowPosition = undefined
        const addressData = await places.addressAutoComplete(input)
        this.loading = false
        this.autocompleteItems = addressData.data || []
      }, 250)
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-address-selector {
  position: relative;

  .cr-text-field {
    z-index: 3;
    position: relative;
  }

  &__dropdown-items {
    background-color: $white;
    box-shadow: 0px 5px 5px -3px rgba($black-base, 0.2),
      0px 8px 10px 1px rgba($black-base, 0.14),
      0px 3px 14px 2px rgba($black-base, 0.12);
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    z-index: 100;
    max-height: 500px;
    overflow-y: auto;

    &__list {
      list-style-type: none;
      padding: 8px 0;
      white-space: nowrap;
      margin: 0;

      &__divider {
        width: calc(100% - 32px);
        margin: 5px 16px;
        height: 1px;
        border-bottom: 1px solid $gray-medium-light;
      }

      &__item {
        height: 24px;
        line-height: 24px;
        font-weight: 400;
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        transition: background-color 0.1s ease-in;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;

        &__icon {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-right: 9px;

          p {
            margin: 0 1px 3px 0;
            position: absolute;
            color: $white;
            font-weight: bold;
          }
        }

        &:hover {
          background-color: $background-hover;
        }

        a {
          color: $primary;
        }

        &__avatar {
          width: 24px;
          margin: 0 10px 0 0;
        }

        &__description {
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        &--focused {
          background-color: $background-hover;
        }
      }
    }
  }
}
</style>
