import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Charge, Lead } from '@/models/dto'
import { TwilioSyncAccessToken } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCalls(params) {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/calls?${query}`
    return httpService.get(url)
  },
  markCallReturned(callId) {
    const host = baseUrl()
    const url = `https://${host}/calls/returned/${callId}`
    return httpService.get(url)
  },
  getCallById(callId) {
    const host = baseUrl()
    const url = `https://${host}/calls/${callId}`
    return httpService.get(url)
  },
  editCallNote(callNote) {
    const host = baseUrl()
    const url = `https://${host}/calls/editNote`
    return httpService.post(url, callNote)
  },
  addCallNote(callNote) {
    const host = baseUrl()
    const url = `https://${host}/calls/addNote`
    return httpService.post(url, callNote)
  },
  getCallReasonTypes() {
    const host = baseUrl()
    const url = `https://${host}/calls/reasonTypes`
    return httpService.get(url)
  },
  getCallCompleteReasonTypes() {
    const host = baseUrl()
    const url = `https://${host}/calls/callCompleteReasonTypes`
    return httpService.get(url)
  },
  getCallSatisfactionTypes() {
    const host = baseUrl()
    const url = `https://${host}/calls/satisfactionTypes`
    return httpService.get(url)
  },
  setLeadOnCall(payload) {
    const host = baseUrl()
    const url = `https://${host}/calls/setLead`
    return httpService.post(url, payload)
  },
  completeCall(payload) {
    const host = baseUrl()
    const url = `https://${host}/calls/complete`
    return httpService.post(url, payload)
  },
  getCallIdByCallSid(params) {
    const host = baseUrl()
    const url = `https://${host}/calls/callId/${params.callSid}`
    return httpService.get(url)
  },
  getLastCompletedCallByPhoneNumber(phone) {
    const host = baseUrl()
    const url = `https://${host}/calls/phone/${phone}`
    return httpService.get(url)
  },
  callsTableExport(params) {
    const { sorts, filters } = params

    let query = ''
    if (sorts) {
      query += query.length ? `&${sorts}` : `${sorts}`
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/calls/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },

  getTwilioSyncAccessToken(): Promise<AxiosResponse<TwilioSyncAccessToken>> {
    const host = baseUrl()
    const url = `https://${host}/twilio/sync/token`
    return httpService.get(url)
  }
}
