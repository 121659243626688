import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  refresh: false,
}

export const mutations = {
  CAUSE_REFRESH(state) {
    state.refresh = true
  },
  END_REFRESH(state) {
    state.refresh = false
  },
}

export const actions = {
  causeRefresh({ commit, state, rootState }) {
    commit('CAUSE_REFRESH')
  },
  endRefresh({ commit, state, rootState }) {
    commit('END_REFRESH')
  },
  getDriverTimeOffRequests(store, params) {
    const {
      userId,
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/time-off/user/${userId}?${query}`
    return axios.get(url)
  },
  createRequest(store, params) {
    const host = baseUrl()
    const url = `https://${host}/time-off`

    return axios.post(url, params.payload)
  },
  getRequestById(store, id) {
    const host = baseUrl()
    const url = `https://${host}/time-off/${id}`
    return axios.get(url)
  },
  editRequest(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/time-off`
    return axios.patch(url, payload)
  },
  approveRequest(store, id) {
    const host = baseUrl()
    const url = `https://${host}/time-off/${id}/approve`
    return axios.get(url)
  },
  rejectRequest(store, id) {
    const host = baseUrl()
    const url = `https://${host}/time-off/${id}/reject`
    return axios.get(url)
  },
  deleteRequest(store, id) {
    const host = baseUrl()
    const url = `https://${host}/time-off/${id}`
    return axios.delete(url)
  },
}
