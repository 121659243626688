<template>
  <div :id="$attrs.id ? `${$attrs.id}-container` : ''">
    <slot name="label" />
    <label v-if="$attrs.label" :for="$attrs.id">{{ $attrs.label }}</label>
    <span v-if="$attrs.tooltip" class="ml-1">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="on">
            <CRIcon
              material
              height="16"
              width="16"
              color="primary"
              style="margin-bottom: -2px"
            >
              info
            </CRIcon>
          </span>
        </template>
        <span style="white-space: pre-wrap">
          {{ $attrs.tooltip }}
        </span>
      </v-tooltip>
    </span>
    <v-select
      :id="$attrs.id"
      flat
      solo
      :name="$attrs.name || $attrs.id"
      v-bind="$attrs"
      append-icon="keyboard_arrow_down"
      v-on="$listeners"
      @change="onSelectChange"
    >
      <template v-if="prependInnerIcon" slot="prepend-inner">
        <CRIcon height="18" width="18" color="grayMediumDark">
          {{ prependInnerIcon }}
        </CRIcon>
      </template>
      <template v-if="$attrs.all" #selection="{ index }">
        <span v-if="index === 0">All</span>
      </template>
      <template v-for="(index, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'CRSelect',
  inheritAttrs: false,
  computed: {
    prependInnerIcon() {
      return this.$attrs['prepend-inner-icon'] || null
    },
  },
  methods: {
    onSelectChange(value) {
      this.$emit('change', value)
    },
    // TODO: finish this and remove function above
    inputListeners() {
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: (value) => this.$emit('input', value),
          change: (value) => this.$emit('change', value),
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
