import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { deepClone } from '@/utils/deepClone'

export const namespaced = true

export const state = {
  referralsTVFilters: getSavedState('referralsTVFilters'),
  referralsTVFilterValues: getSavedState('referralsTVFilterValues'),
}

export const getters = {
  referralsTVFilters(state) {
    return state.referralsTVFilters
  },
  referralsTVFilterValues(state) {
    return state.referralsTVFilterValues
  },
}

export const mutations = {
  SAVE_REFERRALS_FILTERS(state, filters) {
    if (!filters) {
      state.referralsTVFilters = []
      deleteState('referralsTVFilters')
      return
    }
    state.referralsTVFilters = deepClone(filters)
    saveState('referralsTVFilters', filters)
  },
  CLEAR_REFERRALS_FILTERS(state) {
    state.referralsTVFilters = null
    deleteState('referralsTVFilters')
  },
  SAVE_REFERRALS_FILTER_VALUES(state, filterValues) {
    if (!filterValues) {
      state.referralsTVFilterValues = []
      deleteState('referralsTVFilterValues')
      return
    }
    state.referralsTVFilterValues = deepClone(filterValues)
    saveState('referralsTVFilterValues', filterValues)
  },
  CLEAR_REFERRALS_FILTER_VALUES(state) {
    state.referralsTVFilterValues = null
    deleteState('referralsTVFilterValues')
  },
}

export const actions = {
  referralsTV(store, params) {
    const {
      sorts,
      filters,
      pageSize = 10,
      page = 1,
      companyId = null,
      userId = null,
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (companyId) {
      query += `&operatorCompanyId=${companyId}`
    }
    if (userId) {
      query += `&bookingUserId=${userId}`
    }
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/reservations/referrals?${query}`
    let options = {
      headers: { 'Cache-Control': 'private, no-cache, no-store, max-age=0' },
    }
    if (typeof params.esQuery === 'object' && params.esQuery != null) {
      params.esQuery.track_total_hits = true
      params.esQuery.size = pageSize
      params.esQuery.from = pageSize * page - pageSize
      if (
        typeof params.esSort === 'object' &&
        params.esSort != null &&
        Object.keys(params.esSort).length > 0
      ) {
        params.esQuery.sort = params.esSort
      }
      return axios.post(
        `https://${host}/tables/search/referrals`,
        params.esQuery,
        options
      )
    }
    return axios.get(url, options)
  },
  setReferralsFilters({ commit }, filters) {
    return commit('SAVE_REFERRALS_FILTERS', filters)
  },
  clearReferralsFilters({ commit }) {
    return commit('CLEAR_REFERRALS_FILTERS')
  },
  setReferralsFilterValues({ commit }, filterValues) {
    return commit('SAVE_REFERRALS_FILTER_VALUES', filterValues)
  },
  clearReferralsFilterValues({ commit }) {
    return commit('CLEAR_REFERRALS_FILTER_VALUES')
  },
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function deleteState(key) {
  window.localStorage.removeItem(key)
}
