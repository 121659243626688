import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const actions = {
  getRefunds(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/refunds?${query}`
    return axios.get(url)
  },
  addReservationRefunds(store, params) {
    const url = `https://${baseUrl()}/refund/addReservationRefunds`

    return axios.post(url, params)
  },
  addReferralRefunds(store, params) {
    const url = `https://${baseUrl()}/refund/addReferralRefunds`

    return axios.post(url, params)
  },
  voidReservationRefund(store, params) {
    const url = `https://${baseUrl()}/refund/voidReservationRefund`

    return axios.post(url, {
      ...params,
    })
  },
  voidReferralRefund(store, params) {
    const url = `https://${baseUrl()}/refund/voidReferralRefund`

    return axios.post(url, {
      ...params,
    })
  },
}
