import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Classification } from '@/models/Classification'
import { sort } from '@/utils/sort'
import { filter } from '@/utils/filter'

const httpService: HttpService = new HttpService()

export default {
  getClassifications(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Classification>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/classifications?${query}`)
  },
  getClassificationsByType(params: { parentId: number, parentClassificationId: number, classificationTypeId: number, classificationTypeKey: number}): Promise<AxiosResponse<Classification[]>> {
    let parentClassificationIdQuery = ''
    if (params.parentId) {
      parentClassificationIdQuery = `?parentClassificationId=${params.parentClassificationId}`
    }
    const host = baseUrl()
    const url = `https://${host}/classifications/type/${params.classificationTypeKey}${parentClassificationIdQuery}`
    return httpService.get(url)
  },
  getTeamClassifications(additionalSorts = [], additionalFilters = []): Promise<AxiosResponse<Classification[]>> {
    const TEAM_CLASSIFICATON_TYPE_ID = 9

    const filterObjects = []
    const classificationSort = sort()
    const classificationFilter = filter()

    for (const sortObject of additionalSorts) {
      classificationSort.add(sortObject)
    }

    for (const filterObject of additionalFilters) {
      filterObjects.push(filterObject)
    }

    filterObjects.push({
      column: {
        _t_id: '4d64ac73-1be4-4cd3-ae81-b8fa0cfcd2f0',
        prop: 'classificationTypeId',
        type: 'number',
        filterType: 'eq',
        filter: true,
      },
      value: TEAM_CLASSIFICATON_TYPE_ID,
    })

    const parent = classificationFilter.createParent('and')
    filterObjects.forEach((fil) => {
      classificationFilter.add(parent, fil)
    })

    const filterParams = classificationFilter.asQueryParams()
    const sortParams = classificationSort.asQueryParams()

    return this.getClassifications({
      filters: filterParams,
      sort: sortParams,
      page: 1,
      pageSize: -1,
    })
  },
  getRefundClassifications(parentId: number): Promise<AxiosResponse<Classification[]>> {
    const host = baseUrl()
    const url = `https://${host}/refund/classifications?parentId=${parentId}`
    return httpService.get(url)
  },
  getRefundClassificationDetail(classificationId: number): Promise<AxiosResponse<Classification>> {
    const host = baseUrl()
    const url = `https://${host}/refund/classification/${classificationId}`
    return httpService.get(url)
  },
  getCancellationClassifications(parentId: number): Promise<AxiosResponse<Classification[]>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/cancel/classifications?parentId=${parentId}`
    return httpService.get(url)
  },
  getCancellationClassificationDetail(classificationId: number): Promise<AxiosResponse<Classification>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/cancel/classification/${classificationId}`
    return httpService.get(url)
  },
  getChargeClassifications(parentId: number): Promise<AxiosResponse<Classification[]>> {
    const host = baseUrl()
    const url = `https://${host}/charge/classifications?parentId=${parentId}`
    return httpService.get(url)
  },
  getChargeClassificationDetail(classificationId: number): Promise<AxiosResponse<Classification>> {
    const host = baseUrl()
    const url = `https://${host}/charge/classifications/${classificationId}`
    return httpService.get(url)
  },
  getOperatorCancellationClassifications(parentId: number): Promise<AxiosResponse<Classification[]>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/operatorCancel/classifications?parentId=${parentId}`
    return httpService.get(url)
  },
  getReferralRejectionReasonClassifications(parentId: number): Promise<AxiosResponse<Classification[]>> {
    const host = baseUrl()
    const url = `https://${host}/reservations/referralRejectionReason/classifications?parentId=${parentId}`
    return httpService.get(url)
  },
  createClassification(payload: Classification): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/classifications/create`
    return httpService.post(url, payload)
  },
  updateClassification(payload: Classification): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/classifications/update`
    return httpService.patch(url, payload)
  },
  deleteClassification(classificationId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/classifications/delete/${classificationId}`
    return httpService.delete(url)
  },
}
