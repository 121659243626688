import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { deepClone } from '@/utils/deepClone'

export const state = {
  userModel: {
    firstName: null,
    lastName: null,
    email: '',
    phone: '',
    groupName: '',
    companyName: '',
    groupId: null,
    companyId: null,
    isSDR: false,
    isRA: false,
    locale: 'en_US',
    driverInfoDTO: {
      phoneNumber: null,
      licenseNumber: null,
      licenseState: null,
      licenseExpirationMonth: null,
      licenseExpirationYear: null,
      drugTestNumber: null,
      drugTestExpiration: null,
      notes: '',
      driverSupportedVehiclesDTO: [],
    },
  },
  userSchedule: {},
}

export const mutations = {
  SET_INITIAL_USER(state, user) {
    state.userModel = deepClone(user)
    return state.userModel
  },
  SET_USER(state, user) {
    state.userModel = Object.assign(state.userModel, user)
    return state.userModel
  },
  SET_USER_DRIVER_INFO(state, info) {
    if (state.userModel.driverInfoDTO === null) {
      state.userModel.driverInfoDTO = info
      return state.userModel.driverInfoDTO
    }
    state.userModel.driverInfoDTO = Object.assign(
      state.userModel.driverInfoDTO,
      info
    )
    return state.userModel.driverInfoDTO
  },
}

export const getters = {
  getUser(state) {
    return state.userModel
  },
}

export const actions = {
  setUser({ commit }, user) {
    return commit('SET_USER', user)
  },
  setInitialUser({ commit }, user) {
    return commit('SET_INITIAL_USER', user)
  },
  setUserDriverInfoDTO({ commit }, info) {
    return commit('SET_USER_DRIVER_INFO', info)
  },
  getUsers(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return axios.get(`https://${host}/tables/users?${query}`)
  },
  getUser(store, userId) {
    const host = baseUrl()
    const url = `https://${host}/user/${userId}`

    return axios.get(url)
  },
  getUserByExternalId(store, externalUserId) {
    const host = baseUrl()
    const url = `https://${host}/user/externalId/${externalUserId}`

    return axios.get(url)
  },
  getUserGroups() {
    const host = baseUrl()
    const url = `https://${host}/users/groups`

    return axios.get(url)
  },
  updateUser(store, params) {
    const host = baseUrl()
    const url = `https://${host}/user/${params.id}`

    return axios.patch(url, params.payload)
  },
  updateUserTimezone(store, params) {
    const host = baseUrl()
    const url = `https://${host}/user/${params.id}/timezone`

    return axios.patch(url, params.payload)
  },
  createUser(store, params) {
    const host = baseUrl()
    const url = `https://${host}/user`

    return axios.post(url, params.payload)
  },
  determineRoles(store, params) {
    const host = baseUrl()
    const url = `https://${host}/user/determineRoles`

    return axios.put(url, params.payload)
  },
  deleteUser(store, userId) {
    const host = baseUrl()
    const url = `https://${host}/v3/drivers/${userId}`

    return axios.delete(url)
  },
  checkEmail(store, email) {
    const host = baseUrl()
    const url = `https://${host}/user/checkEmail/${email}`

    return axios.get(url, email)
  },
}
