import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'

const httpService = new HttpService()

export const state = {
  reservationsCache: {},
}

export const getters = {
  reservationsCache: (state) => {
    return state.reservationsCache
  },
}

export const mutations = {
  CACHE_RESERVATION(state, { reservation, reservationIdKey }) {
    const updatedReservationObject = {}
    updatedReservationObject[reservationIdKey] = reservation
    state.reservationsCache = Object.assign(
      {},
      state.reservationsCache,
      updatedReservationObject
    )
  },
}

export const actions = {
  reservationById(store, reservationId) {
    const host = baseUrl()
    const url = `https://${host}/reservations/v2/${reservationId}?viewRejectedReferrals=true`
    return axios.get(url)
  },
  syncReservation(store, { reservationId }) {
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/sync/${reservationId}`
    return axios.get(url)
  },
  companyReservations(store, companyId) {
    const host = baseUrl()
    const url = `https://${host}/companies/${companyId}/reservations`
    return axios.get(url)
  },
  providerReferralsTableView(store, params) {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/referrals/forProviderPayments?${query}`
    return axios.get(url)
  },
  reservationsTableView(store, params) {
    const {
      // companyId,
      // referrals,
      sorts,
      filters,
      pageSize = 10,
      page = 1,
    } = params

    // keep this one around ...
    // let query = `companyId=${companyId}&referrals=${referrals}&page=${page}&pageSize=${pageSize}`

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/referrals?${query}`
    let options = {
      headers: { 'Cache-Control': 'private, no-cache, no-store, max-age=0' },
    }
    return axios.get(url, options)
  },
  affiliatesReservationsTV(store, params) {
    const {
      sorts,
      filters,
      pageSize = 10,
      page = 1,
      affiliateCompanyId = null,
    } = params

    let query = `affiliateCompanyId=${affiliateCompanyId}&page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/reservations/affiliates?${query}`
    let options = {
      headers: { 'Cache-Control': 'private, no-cache, no-store, max-age=0' },
    }
    return axios.get(url, options)
  },
  reservationsTV(store, params) {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/reservations?${query}`
    let options = {
      headers: { 'Cache-Control': 'private, no-cache, no-store, max-age=0' },
    }
    if (typeof params.esQuery === 'object' && params.esQuery != null) {
      params.esQuery.track_total_hits = true
      params.esQuery.size = pageSize
      params.esQuery.from = pageSize * page - pageSize
      if (
        typeof params.esSort === 'object' &&
        params.esSort != null &&
        Object.keys(params.esSort).length > 0
      ) {
        params.esQuery.sort = params.esSort
      }
      return axios.post(
        `https://${host}/tables/search/reservations`,
        params.esQuery,
        options
      )
    }
    return axios.get(url, options)
  },
  reservationsTableExport(store, params) {
    const {
      // companyId,
      // referrals,
      sorts,
      filters,
      // pageSize = 10,
      // page = 1,
    } = params

    // keep this one around ...
    // let query = `companyId=${companyId}&referrals=${referrals}&page=${page}&pageSize=${pageSize}`

    let query = '' // `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += query.length ? `&${sorts}` : `${sorts}`
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/reservations/export?${query}`
    return axios.get(url, { responseType: 'blob' })
  },
  reservationAuditTVByReservationId(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/reservation-audits/${params.reservationId}/audits?${query}`
    return axios.get(url)
  },
  getReservationAudits(store, { reservationId }) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${reservationId}/audits`
    return axios.get(url)
  },
  async reservationByIdv2({ commit, store }, { reservationId, companyId }) {
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${reservationId}?companyId=${companyId}`
    const response = await axios.get(url)
    commit('CACHE_RESERVATION', {
      reservation: response.data,
      reservationIdKey: reservationId,
    })
    return response
  },
  reservationByIdv2Legacy(store, { reservationId, companyId }) {
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${reservationId}?companyId=${companyId}`
    return axios.get(url)
  },
  reservationv2ById(store, { reservationId, viewRejectedReferrals = false }) {
    const host = baseUrl()
    const url = `https://${host}/reservations/v2/${reservationId}?viewRejectedReferrals=${viewRejectedReferrals}`
    return axios.get(url)
  },
  async sendDriverInfo(store, { reservationId, payload }) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${reservationId}/sendDriverInfo`
    const response = await axios.post(url, payload)
    store.dispatch('refreshReservationById', { reservationId })
    return response
  },
  getReservationAudit(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return axios.get(`https://${host}/reservation-audits?${query}`)
  },
  async fetchReservationById(
    { commit, dispatch, state, rootGetters },
    { reservationId, viewRejectedReferrals, force = false }
  ) {
    // The difference between this and the v2 function above is that this one
    // leverages VueX.
    if (!force && state.reservationsCache[reservationId]) {
      return
    }

    if (viewRejectedReferrals === undefined) {
      viewRejectedReferrals = false
    }

    const host = baseUrl()
    const reservationUrl = `https://${host}/reservations/v2/${reservationId}?viewRejectedReferrals=${viewRejectedReferrals}`

    const reservationResponse = await axios.get(reservationUrl)
    const reservation = reservationResponse.data

    commit('CACHE_RESERVATION', {
      reservation,
      reservationIdKey: reservationId,
    })
    return reservation
  },
  async refreshReservationById(
    store,
    { reservationId, id, refetchId = null, viewRejectedReferrals = undefined }
  ) {
    await setTimeout(() => {}, 2000)
    // support for legacy payloads with id instead of reservationId
    return store.dispatch('fetchReservationById', {
      reservationId: refetchId || reservationId || id,
      viewRejectedReferrals,
      force: true,
    })
  },
  async resendConfirmation(store, { reservationId }) {
    await axios.get(
      `//${baseUrl()}/reservations/${reservationId}/resendConfirmation`
    )
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation confirmation has been resent.' },
      { root: true }
    )
  },
  async resendReceipt(store, { reservationId }) {
    await axios.get(
      `//${baseUrl()}/reservations/${reservationId}/resendReceipt`
    )
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation receipt has been resent.' },
      { root: true }
    )
  },
  async start(store, { reservationId, data }) {
    // TODO: read the response code
    await axios.get(`//${baseUrl()}/v2/reservations/${reservationId}/start`)
    store.dispatch('refreshReservationById', {
      reservationId: data.reservationId,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Trip started.' },
      { root: true }
    )
  },
  async end(store, { reservationId, data }) {
    // TODO: read the response code
    await axios.get(`//${baseUrl()}/v2/reservations/${reservationId}/end`)
    store.dispatch('refreshReservationById', {
      reservationId: data.reservationId,
    })
    store.dispatch('app/showAlert', { message: 'Trip ended.' }, { root: true })
  },
  async updateReservationStatus(
    store,
    { reservationId, reservationStatus, userId }
  ) {
    const payload = {
      reservationId: reservationId,
      reservationStatus: reservationStatus,
      userId: userId,
    }
    const response = await axios.patch(
      `https://${baseUrl()}/reservations/updateReservationStatusAudit`,
      payload
    )
    store.dispatch('refreshReservationById', { reservationId })
    store.dispatch('app/closeDialog', {}, { root: true })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation status updated.' },
      { root: true }
    )
    return response
  },
  async cancelReservation(
    store,
    { reservationId, sendEmail, classificationId }
  ) {
    const payload = {
      sendConfirmationEmail: sendEmail,
      classificationId: classificationId,
    }
    const url = `https://${baseUrl()}/v2/reservations/${reservationId}/createCancellation`
    httpService.post(url, payload)
    store.dispatch('refreshReservationById', { reservationId })
    store.dispatch('app/closeDialog', {}, { root: true })
  },
  async sendCancellationStartEmail(store, { reservationId }) {
    const url = `https://${baseUrl()}/v2/reservations/${reservationId}/sendCancellationStartEmail`
    const response = await axios.post(url)
    if (response.data) {
      store.dispatch('refreshReservationById', { reservationId })
      store.dispatch('app/closeDialog', {}, { root: true })
    }
    return response.data
  },
  async setEnterpriseTrue(store, { reservationId }) {
    const payload = {
      reservationId: reservationId,
      isEnterprise: true,
    }
    const url = `https://${baseUrl()}/reservations/enterprise`
    await axios.patch(url, payload)
    await store.dispatch('refreshReservationById', { reservationId })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation updated.' },
      { root: true }
    )
  },
  async setEnterpriseFalse(store, { reservationId }) {
    const payload = {
      reservationId: reservationId,
      isEnterprise: false,
    }
    const url = `https://${baseUrl()}/reservations/enterprise`
    await axios.patch(url, payload)
    await store.dispatch('refreshReservationById', { reservationId })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation updated.' },
      { root: true }
    )
  },
  verifyCC(store, reservationId) {
    const host = baseUrl()
    const payload = {
      verified: true,
    }
    const url = `https://${host}/reservations/${reservationId}/verify-credit-card`
    return axios.post(url, payload)
  },
  putReservation(store, reservation) {
    const host = baseUrl()
    const reservationId = reservation.id || reservation.reservationId
    const url = `https://${host}/reservations/${reservationId}`
    return axios.put(url, reservation)
  },
  operatorViewedReservation(store, reservationId) {
    const host = baseUrl()
    const url = `https://${host}/reservations/operatorViewedReservation/${reservationId}?applicationId=1`
    return axios.get(url)
  },
  async acceptReferral(store, params) {
    const {
      reservationId,
      refreshReservationId,
      referralAcceptanceESignature,
    } = params
    const response = await axios.get(
      `https://${baseUrl()}/reservations/acceptReferral/${reservationId}?referralAcceptanceESignature=${referralAcceptanceESignature}`
    )
    store.dispatch('refreshReservationById', {
      reservationId: refreshReservationId || reservationId,
      viewRejectedReferrals: true,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation Accepted.' },
      { root: true }
    )
    return response
  },
  async rejectReferral(store, params) {
    const { reservationId, refreshReservationId, body } = params
    let query = `?rejectReasonTypeId=${body.referralRejectionReasonTypeId}`
    if (body?.notes) {
      query += `&notes=${body.notes}`
    }
    query = encodeURI(query)
    const response = await axios.get(
      `https://${baseUrl()}/reservations/rejectReferral/${reservationId}${query}`
    )
    store.dispatch('refreshReservationById', {
      reservationId: refreshReservationId || reservationId,
      viewRejectedReferrals: true,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation Rejected.' },
      { root: true }
    )
    return response
  },
  async rejectReferralV2(store, body) {
    const url = `https://${baseUrl()}/reservations/rejectReferral`
    const response = await axios.post(url, body)
    store.dispatch('refreshReservationById', {
      reservationId: body.reservationId,
      viewRejectedReferrals: true,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation Rejected.' },
      { root: true }
    )
    return response
  },
  async unassignReferral(store, params) {
    const {
      reservationId,
      refreshReservationId,
      operatorCancellationClassificationId,
    } = params
    let query = ''
    if (operatorCancellationClassificationId) {
      query += `?operatorCancellationClassificationId=${operatorCancellationClassificationId}`
    }
    const response = await axios.get(
      `https://${baseUrl()}/reservations/unassignReferral/${reservationId}${query}`
    )
    store.dispatch('refreshReservationById', {
      reservationId: refreshReservationId || reservationId,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation Unassigned.' },
      { root: true }
    )
    return response
  },
  async unassignReferralV2(store, body) {
    const url = `https://${baseUrl()}/reservations/unassignReferral`
    const response = await axios.post(url, body)
    store.dispatch('refreshReservationById', {
      reservationId: body.reservationId,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation Unassigned.' },
      { root: true }
    )
    return response
  },
  async offerReferral(store, params) {
    const response = await axios
      .post(`https://${baseUrl()}/reservations/offerReferral`, params)
      .catch((r) => r.response)
    if (response.status != 200) {
      store.dispatch(
        'app/showAlert',
        { message: response?.data?.message, type: 'error' },
        { root: true }
      )
    } else {
      store.dispatch(
        'app/showAlert',
        { message: 'Reservation offered.' },
        { root: true }
      )
    }
    return response
  },
  async reofferReferral(store, params) {
    const response = await axios
      .post(`https://${baseUrl()}/reservations/reofferReferral`, params)
      .catch((r) => r.response)
    return response
  },
  async linkPreBookingAndOfferReferral(store, params) {
    const { preBookingId } = params
    const response = await axios.post(
      `https://${baseUrl()}/prebooking/${preBookingId}/offerAndAccept`,
      params
    )
    store.dispatch(
      'app/showAlert',
      { message: 'Reservation linked.' },
      { root: true }
    )
    return response
  },
  async addReservationComment(store, params) {
    const host = baseUrl()
    const { reservation, refreshId } = params
    const reservationId = reservation.id || reservation.reservationId
    const url = `https://${host}/reservations/${reservationId}/reservationComments`
    await axios.post(url, params)
    await store.dispatch('refreshReservationById', {
      reservationId: refreshId || reservationId,
      viewRejectedReferrals: true,
    })
    store.dispatch(
      'app/showAlert',
      { message: 'Comment added.' },
      { root: true }
    )
  },
  async cancelReservationsWithComment(store, params) {
    const host = baseUrl()
    const { reservation } = params
    const { id: reservationId } = reservation
    const url = `https://${host}/reservations/cancelReservationsWithComment`
    await axios.patch(url, { reservationId })

    const url2 = `https://${host}/reservations/${reservationId}/reservationComments`
    await axios.post(url2, params)
    store.dispatch('refreshReservationById', { reservationId })
    store.dispatch('app/closeDialog', {}, { root: true })
  },
  updateDrivers(store, params) {
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${params.reservationId}/drivers/assign`
    return axios.post(url, params.payload)
  },
  updateVehicles(store, params) {
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${params.reservationId}/vehicles/assign`
    return axios.post(url, params.payload)
  },
  async assignDrivers(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/drivers/assign`
    const response = await axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments added.' },
        { root: true }
      )
    }
    return response
  },
  async addDriver(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/drivers/add`
    const response = await axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments added.' },
        { root: true }
      )
    }
    return response
  },
  async assignVehicles(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/vehicles/assign`
    const response = await axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments added.' },
        { root: true }
      )
    }
    return response
  },
  async addVehicle(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/vehicles/add`
    const response = await axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments added.' },
        { root: true }
      )
    }
    return response
  },
  async addProviderVehicles(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/provider/${params.companyId}/vehicles/add`
    const response = axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments added.' },
        { root: true }
      )
    }
    return response
  },
  async unassignProviderDrivers(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/provider/${params.companyId}/drivers/unassign`
    const response = await axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments removed.' },
        { root: true }
      )
    }
    return response
  },
  async unassignProviderVehicles(store, params) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${params.reservationId}/provider/${params.companyId}/vehicles/unassign`
    const response = axios.post(url, params.payload)
    if (!params.suppressAlert) {
      store.dispatch(
        'app/showAlert',
        { message: 'Assignments removed.' },
        { root: true }
      )
    }
    return response
  },
  confirmCancellation(store, params) {
    const { payload = {}, reservationId } = params
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${reservationId}/confirm-cancellation`
    return axios.post(url, payload)
  },
  complaintFeedback(store, params) {
    const { payload = {}, reservationId } = params
    const host = baseUrl()
    const url = `https://${host}/v2/reservations/${reservationId}/complaint-feedback`
    return axios.post(url, payload)
  },
  async changeReferralAmount(store, { reservationId, payload }) {
    const host = baseUrl()
    const url = `https://${host}/reservations/${reservationId}/change-referral-amounts`
    // referrals: ;[{ id: 549, total: 33, newTotal: 333, balance: 0, newBalance: 300 }].post(
    const response = await axios.post(url, payload)
    store.dispatch('refreshReservationById', { reservationId })
    return response
  },
  async setManualReferralNeeded(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/reservations/setManualReferral`
    await axios.post(url, payload)
  },
  async markAsEnterprise(store, payload) {
    const host = baseUrl()
    const url = `https://${host}/reservations/enterpriseEnMasse`
    await axios.patch(url, payload)
  },
  async togglePendingConfirmation(store, reservationId) {
    const host = baseUrl()
    const url = `https://${host}/reservations/togglePendingConfirmation/${reservationId}`
    await axios.post(url)
  },
  getTripGPSCoordinates(store, params) {
    const { reservationId = null, journeyId = null, skip = 10 } = params
    const host = baseUrl('tracking')
    const url = `https://${host}/trip_detail/${reservationId}/${journeyId}?skip=${skip}`
    return axios.get(url)
  },
  getReservationSummary(store) {
    const host = baseUrl()
    const url = `https://${host}/reservations/summary`
    return axios.get(url)
  },
  reservationTimeSeriesCustomerData(store, { customerId, payload }) {
    return axios.post(
      `https://${baseUrl()}/reservations/timeSeries/${customerId}/customer`,
      payload
    )
  },
  getReservationBillingSummary(store) {
    const host = baseUrl()
    const url = `https://${host}/reservations/summary-billing`
    return axios.get(url)
  },
  getReservationBalanceSummary(store, { customerId }) {
    return axios.get(
      `https://${baseUrl()}/reservations/balanceSummary/${customerId}/customer`
    )
  },
  reinstateReservation(store, reservationId) {
    const host = baseUrl()
    const url = `https://${host}/reservations/reinstateReservation/${reservationId}`
    return axios.get(url)
  },
  getReservationTrackingStatuses(store, reservationIds) {
    const host = baseUrl()
    const url = `https://${host}/v2/tracking/status`
    return axios.post(url, reservationIds)
  },
}
