export class Note {
  constructor(note) {
    this.note = note?.note || null
    this.html = note?.html || null
    this.noteType = note?.noteType || null
    this.createdBy = note?.createdBy || null
  }

  toObject() {
    const note = Object.assign({}, this)
    return note
  }
}
