import { store } from '@/state/store'

export const buildBreakpoints = (options, firstLoad = false) => {
  const dimensions = {
    height: window.innerHeight,
    width: window.innerWidth,
  }
  const threshold = options.breakpoints
  const trueWidth = dimensions.width

  let isSidebarOpen = firstLoad
    ? dimensions.width > threshold.sm
    : store.getters['app/isSidebarOpen']
  if (isSidebarOpen) {
    dimensions.width -= options.sidebar.width
  }

  const conditionals = {}
  for (const name in conditionalsFunctions) {
    conditionals[name] = conditionalsFunctions[name](
      dimensions.width,
      threshold
    )
  }

  const breakpoints = {
    xs: conditionals.xsOnly,
    sm: conditionals.smOnly,
    md: conditionals.mdOnly,
    lg: conditionals.lgOnly,
    xl: conditionals.xlOnly,
  }
  const mobile = dimensions.width < threshold.mobile
  const sidebar = conditionalsFunctions[options.sidebar.threshold](
    trueWidth,
    threshold
  )
  let name = 'xs'
  for (const [key, value] of Object.entries(breakpoints)) {
    if (value) {
      name = key
    }
  }
  return {
    ...breakpoints,
    ...conditionals,
    mobile,
    name,
    ...dimensions,
    threshold,
    sidebar,
  }
}

const conditionalsFunctions = {
  xsOnly: (width, threshold) => {
    return width < threshold.xs
  },
  smOnly: (width, threshold) => {
    return width >= threshold.xs && width < threshold.sm
  },
  smAndDown: (width, threshold) => {
    return width < threshold.sm
  },
  smAndUp: (width, threshold) => {
    return width >= threshold.xs
  },
  mdOnly: (width, threshold) => {
    return width >= threshold.sm && width < threshold.md
  },
  mdAndDown: (width, threshold) => {
    return width < threshold.md
  },
  mdAndUp: (width, threshold) => {
    return width >= threshold.sm
  },
  lgOnly: (width, threshold) => {
    return width >= threshold.md && width < threshold.lg
  },
  lgAndDown: (width, threshold) => {
    return width < threshold.lg
  },
  lgAndUp: (width, threshold) => {
    return width >= threshold.md
  },
  xlOnly: (width, threshold) => {
    return width >= threshold.lg
  },
}
