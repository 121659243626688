import { KnownAddress } from "@/models/dto"

/**
 * Flattens a hierarchy of `KnownAddress` objects into a single, flat array. This function
 * recursively explores each `KnownAddress` and its children, appending them to a flat array.
 *
 * @param {KnownAddress[]} knownAddresses - An array of `KnownAddress` objects, potentially containing nested children.
 * @returns {KnownAddress[]} A flat array of all `KnownAddress` objects from the input, including nested children.
 *
 */
export const flattenKnownAddresses = (knownAddresses: KnownAddress[]): KnownAddress[] => {

  const flattenedAddresses: KnownAddress[] = []

  const flattenRecursive = (addresses: KnownAddress[]) => {
    for (const address of addresses) {
      flattenedAddresses.push(address)
      if (address.childKnownAddresses.length > 0) {
        flattenRecursive(address.childKnownAddresses)
      }
    }
  }

  flattenRecursive([...knownAddresses])

  return flattenedAddresses
}

/**
 * Builds a hierarchical structure of `KnownAddress` objects starting from a specific address,
 * identified by `knownAddressIdInput`, up to its highest ancestor. This function is useful
 * for constructing a path or breadcrumb trail from a child address up to its root.
 *
 * @param {KnownAddress[]} knownAddresses - A flat array of `KnownAddress` objects, without nested children.
 * @param {number | string} knownAddressIdInput - The ID of the `KnownAddress` to build the hierarchy from. Can be a number or a string that is parsable to a number.
 * @returns {KnownAddress[]} An array of `KnownAddress` objects representing the hierarchy from the specified address up to its root.
 *
 */
export const buildHierarchy = (knownAddresses: KnownAddress[], knownAddressIdInput: number | string): KnownAddress[] => {
  let knownAddressId = typeof knownAddressIdInput === 'string' ? parseInt(knownAddressIdInput, 10) : knownAddressIdInput
  const hierarchy: KnownAddress[] = []
  const flattenedKnownAddresses = flattenKnownAddresses(knownAddresses)
  let currentKnownAddress = flattenedKnownAddresses.find(
    (knownAddress) => knownAddress.knownAddressId === knownAddressId
  )

  while (currentKnownAddress) {
    hierarchy.unshift(currentKnownAddress)
    currentKnownAddress = flattenedKnownAddresses.find(
      (knownAddress) => knownAddress.knownAddressId === currentKnownAddress?.parentKnownAddressId
    )
  }

  return hierarchy
}
