import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { ValidatePasswordResponse, ValidatePasswordRequest } from '@/models/dto/Password'
import { UserProfileResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  requestJwtLogin(payload: { userId: number, delegatorId: number} ): Promise<AxiosResponse<{ token: string}>> {
    const url = `https://${baseUrl()}/auth/jwt/request`
    return httpService.post(url, payload)
  },
  getUserProfileWithToken(): Promise<AxiosResponse<UserProfileResult>> {
    const url = `https://${baseUrl()}/auth/jwt`
    return httpService.post(url, {})
  },

  /**
   * Validates password against endpoint in Auth service
   *
   * @param passwordCandidate - new password chosen by the user
   * @returns A promise with the result of validation
   */
  validatePassword(passwordCandidate: string): Promise<AxiosResponse<ValidatePasswordResponse>> {
    const url = `https://${baseUrl()}/password/validate`
    return httpService.post<ValidatePasswordResponse, ValidatePasswordRequest>(url, { newPassword: passwordCandidate })
  },

  /**
   * Retrieves the current user's roles and permissions.
   *
   * @returns A promise that resolves to the user's roles and permissions.
   */
  getUserRolesAndPermissions(): Promise<AxiosResponse<UserProfileResult>> {
    const url = `https://${baseUrl()}/userProfile`
    return httpService.get(url)
  },
}
