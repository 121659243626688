import Vue from 'vue'
import axios from 'axios'
import { store } from '@/state/store'
import { setRequestConfig } from '@/services/authTokenRepository'

export const handleRequest = async (config) => {
  // Do something before request is sent
  // If request is different than any of the URLS in urlsExcludedForBearerHeader
  // then send Authorization header with token from localstorage
  return await setRequestConfig(config)
}

export const handleRequestError = async (error) => Promise.reject(error)

export const handleResponse = async (response) => response

export const handleResponseError = async (error) => {
  if (error.response && error.response.status === 401) {
    // If we're still authenticated in the client, our refresh token must have expired
    if (Vue.prototype.$auth0.isAuthenticated && store) {
      store.dispatch('app/showAlert', {
        message: `Your credentials have expired. Please reauthenticate yourself.`,
        type: 'error',
        hasAction: true,
        action: async () => {
          await Vue.prototype.$auth0.loginWithPopup()
        },
      })
    } else {
      await store.dispatch('auth/logOut')
    }
  }

  return Promise.reject(error)
}

axios.defaults.headers.common['Application-Origin'] = 'coachrail'
// Until HttpService is more widely adopted, we'll need to maintain interceptors here
axios.interceptors.request.use(handleRequest, handleRequestError)
axios.interceptors.response.use(handleResponse, handleResponseError)

Plugin.install = () => {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios
      },
    },
    $axios: {
      get() {
        return axios
      },
    },
  })
}

Vue.use(Plugin)

export default Plugin
