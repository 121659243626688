export class TripDate {
  constructor(tripAddressHash) {
    this.date = null
    this.tripAddressHash = tripAddressHash || null
    this.times = []
  }

  toObject() {
    const tripDate = Object.assign({}, this)
    return tripDate
  }

  toJSON() {
    const tripDate = Object.assign({}, this)
    return tripDate
  }
}
