<template>
  <div class="menu-parent">
    <v-text-field
      ref="autoCompleteTextField"
      v-bind="$attrs"
      :autofocus="autoFocus"
      :value="search"
      :disabled="disabled"
      :error-messages="errorMessages"
      :rules="rules"
      :clearable="clearable"
      flat
      :append-icon="appendIcon ? appendIcon : ''"
      solo
      @blur="blurInput"
      @input="addressAutoComplete"
      @keyup.native="handleKeyEvent"
      @focus="focusHandler"
      @click:append="appendIconHandler"
    />
    <div v-if="loading" class="menu-child">
      <v-progress-linear :indeterminate="true" height="1" />
    </div>
    <div
      v-if="autocompleteItems.length && showOptions && manualToggle"
      class="menu-child"
    >
      <v-card>
        <v-list>
          <v-list-tile
            v-for="(item, index) in autocompleteItems"
            :key="index"
            :class="arrowPosition === index ? 'hover-auto-complete-item' : ''"
            @click="() => selectPlace(item)"
          >
            <img
              class="location-avatar"
              src="@/assets/images/dropoff_completed.png"
            />
            <v-list-tile-title>
              {{ item.description }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import places from '@/services/places'
import * as logger from '@/utils/logger'

export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    manualControl: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focusHandler: {
      type: Function,
      default: () => null,
    },
    showOptions: {
      type: Boolean,
      default: true, // eslint-disable-line
    },
    identifier: {
      type: String,
      default: () => undefined,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      default: () => undefined,
    },
    appendIconHandler: {
      type: Function,
      default: () => true,
    },
    initialSearch: {
      type: String,
      default: () => undefined,
    },
    useFormattedDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      search: undefined,
      debounce: undefined,
      addressSearchText: undefined,
      autocompleteItems: [],
      arrowPosition: undefined,
      manualToggle: true,
      errorMessages: [],
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.addressAutoComplete(val)
      }
    },
    initialSearch(val) {
      this.search = val
    },
  },
  mounted() {
    this.search = this.initialSearch
  },
  methods: {
    handleKeyEvent(event) {
      if (this.manualControl) {
        this.manualToggle = true
        this.errorMessages = []
      }
      const maxPosition = this.autocompleteItems.length
      // UP ARROW
      if (event.keyCode === 38) {
        if (typeof this.arrowPosition === 'undefined') {
          this.arrowPosition = maxPosition
        } else {
          this.arrowPosition = this.arrowPosition - 1
        }
      }
      // DOWN ARROW
      if (event.keyCode === 40) {
        if (typeof this.arrowPosition === 'undefined') {
          this.arrowPosition = 0
        } else if (this.arrowPosition + 1 === maxPosition) {
          this.arrowPosition = 0
        } else {
          this.arrowPosition = this.arrowPosition + 1
        }
      }

      // ENTER
      if (event.keyCode === 13) {
        if (typeof this.autocompleteItems[this.arrowPosition] !== 'undefined') {
          this.selectPlace(this.autocompleteItems[this.arrowPosition])
        }
      }
    },
    async addressAutoComplete(input) {
      if (
        typeof input === 'undefined' ||
        input === null ||
        input.length === 0
      ) {
        this.clearPlace()
        return
      }
      if (this.debounce) {
        window.clearTimeout(this.debounce)
      }
      this.debounce = window.setTimeout(async () => {
        this.loading = true
        this.arrowPosition = undefined
        const addressData = await places.addressAutoComplete(input)
        this.loading = false
        this.autocompleteItems = addressData.data || []
      }, 250)
    },
    async selectPlace(placeItem) {
      if (!placeItem) {
        return
      }
      const placeItemTypes = placeItem.types
      const placeItemIsStreetAddress =
        placeItemTypes.includes('street_address') ||
        placeItemTypes.includes('premise')

      const defaultAddressTitle = placeItemIsStreetAddress
        ? null
        : placeItem.structured_formatting?.main_text
      const { description, place_id: placeId } = placeItem
      let placeData
      if (this.useFormattedDetail) {
        placeData = await places.placeDetailFormatted(placeId)
      } else {
        placeData = await places.placeDetail(placeId)
      }

      try {
        const place = this.useFormattedDetail ? placeData : placeData.data
        place.name = place.addressName
        const { identifier } = this
        this.$nextTick(() => {
          this.$emit('place-selected', {
            identifier,
            place,
            description,
            defaultAddressTitle,
          })
        })

        if (this.manualControl) {
          this.search = place.addressName
          this.manualToggle = false
        }
      } catch (e) {
        logger.warn('Error emitting event')
        logger.warn(e)
      }
    },
    clearPlace() {
      const { identifier } = this
      this.autocompleteItems = []
      this.$emit('place-cleared', identifier)
    },
    blurInput() {
      const { identifier } = this
      setTimeout(() => {
        this.autocompleteItems = []
        this.$emit('exit', identifier)
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.location-avatar {
  width: 22px;
  height: 24px;
}

.menu-parent {
  position: relative;
}

.menu-child {
  position: absolute;
  top: 50px;
  z-index: 500;
  width: 100%;
}

.hover-auto-complete-item {
  background-color: rgba(202, 205, 211, 0.25);
}
</style>
