import { store } from '../state/store.js'
import { pluralize } from '@/utils/string'

export class TripVehicle {
  constructor({ vehicleTypeId = null, quantity = 0, tripVehicleId = null }) {
    this.tripVehicleId = tripVehicleId
    this.vehicleTypeId = vehicleTypeId
    this.quantity = quantity
  }

  get name() {
    return `${this.quantity} ${pluralize(this.quantity, this.vehicleTypeLabel)}`
  }

  get vehicleTypeLabel() {
    return (
      store.getters['types/vehicleTypes'].find(
        (vt) => vt.id === this.vehicleTypeId
      )?.label ?? 'Vehicle'
    )
  }

  toObject() {
    const tripVehicle = Object.assign({}, this)
    tripVehicle.vehicleType = this.vehicleType
    tripVehicle.name = this.name
    return tripVehicle
  }

  toJSON() {
    return this.toObject()
  }

  get vehicleType() {
    return {
      id: this.vehicleTypeId,
      label: this.vehicleTypeLabel,
    }
  }
}
