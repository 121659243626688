import { Address } from './Address'
import { CustomerAccountDefaults } from './CustomerAccountDefaults'

export class CustomerAccount {
  constructor(customerAccount) {
    if (!customerAccount) {
      return
    }
    this.id = customerAccount.customerAccountId || null
    this.purpose = customerAccount.purpose || null
    this.name = customerAccount.name || null
    this.accountExecutive = customerAccount.accountExecutive || null
    this.address = new Address(customerAccount?.address)
    this.email = customerAccount.email || null
    this.phone = customerAccount.phone || null
    this.nameApprovedForUserInterface =
      customerAccount.nameApprovedForUserInterface || false
    this.notes = customerAccount.notes || null
    this.industryId = customerAccount.industryId || null
    this.industryDisplay = customerAccount.industries || null
    this.supportEmail = customerAccount.supportEmail || null
    this.supportPhone = customerAccount.supportPhone || null
    this.hasRiderTracking = customerAccount.hasRiderTracking || false
    this.hasRiderGroups = customerAccount.hasRiderGroups || false
    this.hasRequireRiderGroups = customerAccount.hasRequireRiderGroups || false
    this.hasRiderCapacityLimits =
      customerAccount.hasRiderCapacityLimits || false
    this.hasRiderIdTickets = customerAccount.hasRiderIdTickets || false
    this.staticSiteUrl = customerAccount.staticSiteUrl || null
    this.lightLogoUrl = customerAccount.lightLogoUrl || null
    this.enterpriseAccountId = customerAccount.enterpriseAccountId || null
    this.enterpriseAccountName = customerAccount.enterpriseAccountName || null
    this.defaults = new CustomerAccountDefaults(customerAccount.defaults)
    this.effectiveDefaults = new CustomerAccountDefaults(
      customerAccount.effectiveDefaults
    )
    this.tier = customerAccount.tier
    this.canSelfServeCancel = customerAccount.canSelfServeCancel
    this.billingFrequencyTypeId = customerAccount.billingFrequencyTypeId ?? null
  }
  toObject() {
    const customerAccount = Object.assign({}, this)
    customerAccount.defaults = this.defaults.toObject()
    customerAccount.effectiveDefaults = this.effectiveDefaults.toObject()
    return customerAccount
  }
}
