// src/cr/plugin/index.ts
import { buildBreakpoints } from '@/cr/plugin/breakpoints';
import { buildTheme } from '@/cr/plugin/theme';
import { buildSidebar } from '@/cr/plugin/sidebar';

export default {
  install(Vue, options: any) {
    Vue.prototype.$cr = {
      breakpoint: buildBreakpoints(options, true),
      theme: buildTheme(options),
      sidebar: buildSidebar(options),
    };
    Vue.observable(Vue.prototype.$cr);
    window.addEventListener('resize', () => {
      Vue.prototype.$cr.breakpoint = buildBreakpoints(options);
    });
  },
};
