/**
 * Function to recursively deep clone any object.
 * @template T - The type of the object being cloned.
 * @param {T} object - The object to be cloned.
 * @returns {T} - The deep cloned object.
 */
export const deepClone = function deepClone<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

/**
 * Function to deep clone an object, including handling primitives, non-object types, Date objects, and Array objects.
 * @template T - The type of the object being cloned.
 * @param {T} obj - The object to be cloned.
 * @returns {T} - The deep cloned object.
 */
export const deepCloneClass = <T>(obj: T): T => {
  // Handle primitives and non-object types
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // Type guard for Date objects
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as T;
  }

  // Type guard for Array objects
  if (Array.isArray(obj)) {
    return obj.map(deepCloneClass) as unknown as T;
  }

  // Create a clone of the object with the same prototype
  const clone = Object.create(Object.getPrototypeOf(obj));

  // Recursively clone each property
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clone[key] = deepCloneClass((obj as any)[key]);
    }
  }

  return clone as T;
}
