<template>
  <v-dialog
    :value="value"
    :width="fullScreen ? '' : 'unset'"
    transition="slide-x-reverse-transition"
    :persistent="persistent"
    @update:returnValue="$emit('input', false)"
  >
    <v-layout column class="cr-sidebar-dialog">
      <div class="cr-sidebar-dialog__header">
        <div class="cr-sidebar-dialog__header__content">
          <v-layout row>
            <h1><slot name="title"></slot></h1>
            <span class="ml-2"><slot name="chip"></slot></span>
          </v-layout>
          <v-btn flat solo icon @click="$emit('input', false)">
            <CRIcon view-box="0 0 24 24" :width="32" :height="32">close</CRIcon>
          </v-btn>
        </div>
      </div>

      <slot></slot>
    </v-layout>
  </v-dialog>
</template>
<script>
export default {
  provide: {
    isInSidebarDialog: true,
  },
  props: {
    value: {
      type: Boolean,
    },
    persistent: {
      type: Boolean,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.v-dialog__content {
  display: flex;
  justify-content: flex-end !important;
}

::v-deep .v-dialog {
  border-radius: 0 !important;
  height: 100%;
  max-height: 100%;
  margin: 0;
  background: white;
}

.cr-sidebar-dialog {
  border-radius: 0 !important;
  min-width: 500px;
  background: white;
  height: 100%;
  overflow-y: auto;

  &__header {
    border-bottom: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 0 12px 0 48px;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      h1 {
        margin: 0;
      }
    }
  }
}
</style>
