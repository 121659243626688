import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const state = {
  featureToggles: getSavedState('featureToggles'),
}

export const mutations = {
  SET_FEATURE_TOGGLES(state, newValue) {
    state.featureToggles = newValue
    saveState('featureToggles', newValue)
  },
  UPDATE_FEATURE_TOGGLES(state, id) {
    const featureToggles = getResultList(state) || []
    const featureIdx = featureToggles.findIndex((item) => item.id === id)
    state.featureToggles = {
      featureToggles: {
        featureToggles: [
          ...featureToggles.slice(0, featureIdx),
          {
            ...featureToggles[featureIdx],
            enabled: !featureToggles[featureIdx].enabled,
          },
          ...featureToggles.slice(featureIdx + 1),
        ],
      },
    }
    saveState('featureToggles', state.featureToggles)
  },
}

export const getters = {
  featureToggles(state) {
    return getResultList(state) || []
  },
  isMassAssignmentEnabled(state) {
    return isFeatureEnabled(state, 'isMassAssignmentEnabled')
  },
  isRiderManualAddEnabled(state) {
    return isFeatureEnabled(state, 'isRiderManualAddEnabled')
  },
  isReferralRejectionReasonV2Enabled(state) {
    return isFeatureEnabled(state, 'isReferralRejectionReasonV2Enabled')
  },
  isShuttleCreateQuoteEnabled(state) {
    return isFeatureEnabled(state, 'isShuttleCreateQuoteEnabled')
  },
  isChargePenaltyOnCancelEnabled(state) {
    return isFeatureEnabled(state, 'isChargePenaltyOnCancelEnabled')
  },
  isRiderIdTicketsEnabled(state) {
    return isFeatureEnabled(state, 'isRiderIdTicketsEnabled')
  },
  isVehicleLinkDataEnabled(state) {
    return isFeatureEnabled(state, 'isVehicleLinkDataEnabled')
  },
  isAvailabilityV3Enabled(state) {
    return isFeatureEnabled(state, 'isAvailabilityV3Enabled')
  },
  isAccountExecutiveAndOpportunityIdEnabled(state) {
    return isFeatureEnabled(state, 'isAccountExecutiveAndOpportunityIdEnabled')
  },
  isStopDatetimeValidationEnabled(state) {
    return isFeatureEnabled(state, 'isStopDatetimeValidationEnabled')
  },
}

export const actions = {
  init({ state, dispatch }) {
    dispatch('getFeatureToggles')
  },

  getFeatureToggles({ commit, dispatch, getters }) {
    const pageSize = -1

    const query = `pageSize=${pageSize}`

    const host = baseUrl()
    const url = `https://${host}/featureToggle?${query}`
    return axios
      .get(url)
      .then((response) => {
        const featureToggles = response.data
        commit(
          'SET_FEATURE_TOGGLES',
          Object.assign({}, state.featureToggles || {}, {
            featureToggles,
          })
        )
        return featureToggles
      })
      .catch((error) => {
        return error
      })
  },
  getFeatureToggleTable({ getters }) {
    // shape data for BaseTableWrapper
    const resultList = getters.featureToggles
    return { data: { resultList } }
  },
  async updateFeatureToggle({ commit, getters }, id) {
    const featureToggle = getters.featureToggles.find((item) => item.id === id)
    if (featureToggle) {
      const payload = {
        ...featureToggle,
        enabled: !featureToggle.enabled,
      }
      const url = `https://${baseUrl()}/featureToggle`
      await axios.patch(url, payload)
      commit('UPDATE_FEATURE_TOGGLES', id)
    }
  },
}

// ===
// Private helpers
// ===

const isFeatureEnabled = (state, featureName) => {
  const resultList = getResultList(state) || []
  const feature =
    resultList.find((resultItem) => resultItem?.name === featureName) || {}
  return feature.enabled === true
}

const getResultList = (state) => {
  return state?.featureToggles?.featureToggles?.featureToggles || []
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
