import * as logger from './logger'
import uuid from 'uuid'
import { deepClone } from '@/utils/deepClone'

export function filter() {
  let filterList = []

  // `f` is always the filter object

  function newParent() {
    return {
      column: {
        _t_id: uuid.v4(),
      },
    }
  }

  function createParent(method, childOf) {
    const parent = newParent()
    if (childOf) {
      parent.parent = childOf.column._t_id
    }
    parent.method = method
    filterList.push(parent)
    return parent
  }

  function filterById(b) {
    return (a) => a.column._t_id === b.column._t_id
  }

  function find(f) {
    return filterList.find(filterById(f))
  }

  function or(f, addToBeginning) {
    return andOr('or', f, addToBeginning)
  }

  function and(f, addToBeginning) {
    return andOr('and', f, addToBeginning)
  }

  function andOr(method, f, addToBeginning) {
    const exists = parent(f)
    const _parent = exists || newParent()
    if (!exists) {
      _parent.method = method
      if (addToBeginning) {
        _parent.topLevel = true
        filterList.unshift(_parent)
      } else {
        filterList.push(_parent)
      }
    }
    return {
      add: (...criteria) => add(_parent, ...criteria),
      addChild: (...criteria) => addChild(_parent, ...criteria),
    }
  }

  function add(parent, f) {
    f.parent = parent.column._t_id
    const childExists = find(f)
    if (!childExists) {
      // logger.info(`Adding child with id ${f.column._t_id}`, f.parent)
      filterList.push(f)
    } else {
      logger.warn(`Child already exists with id ${childExists.column._t_id}`)
    }
    return parent
  }

  function remove(f) {
    const _parent = parent(f)
    filterList = filterList.filter(
      (item) => item.column._t_id !== f.column._t_id
    )
    if (_parent) {
      const _children = children(_parent)
      const _childrenWithChildren = childrenWithChildren(_parent)
      if (_children.length === 0 && _childrenWithChildren.length === 0) {
        remove(_parent)
      }
    } else {
      logger.warn(' No Parent')
    }
  }

  function children(f) {
    return filterList.filter(
      (child) => child.parent === f.column._t_id && !child.method
    )
  }

  function childrenWithChildren(f) {
    return filterList.filter(
      (child) => child.parent === f.column._t_id && child.method
    )
  }

  function addChild(parent, child, method) {
    const parentExists = find(parent)
    const childExists = find(child)
    if (!parentExists) {
      return logger.error(
        `Error parent with ID ${parent.column._t_id} does not exist`
      )
    }
    if (childExists) {
      return logger.error(
        `Error child with ID ${parent.column._t_id} already exists`
      )
    }
    const newChild = Object.assign(
      { parent: parent.column._t_id, method },
      { ...child }
    )
    filterList.push(newChild)
    return {
      add: (...criteria) => add(newChild, ...criteria),
      addChild: (...criteria) => addChild(newChild, ...criteria),
    }
  }

  function parent(f) {
    return filterList.find((p) => {
      return p.column._t_id === f.parent
    })
  }

  function parents() {
    return filterList.filter((f) => !f.parent)
  }

  function get() {
    return filterList
  }
  function set(rebuiltFilterList) {
    filterList = rebuiltFilterList
  }
  function clear() {
    filterList = []
  }
  function formatProp(p = '') {
    return p.replace(/\//g, '.')
  }

  function asQueryParams() {
    const queryParams = [['shortHand', '1']]
    const _parents = parents()
    const recurse = (_p, _parentBase) => {
      // eslint-disable-next-line
      _p.forEach((_parent, _p_idx) => {
        let parentBase
        if (_parentBase) {
          parentBase = `${_parentBase}fs${_p_idx}` // eslint-disable-line
        } else {
          parentBase = _parent.topLevel ? `filter` : `filterfs${_p_idx}` // eslint-disable-line
        }
        const _children = children(_parent)
        queryParams.push([`${parentBase}l`, _parent.method])
        // eslint-disable-next-line
        _children.forEach((child, _c_idx) => {
          const addSingleFilter = (
            item,
            value,
            replacementProp,
            overrideIdx,
            overrideFilterType
          ) => {
            const childIdx = overrideIdx || _c_idx // eslint-disable-line
            queryParams.push([
              `${parentBase}fs${childIdx}fd`,
              formatProp(replacementProp || item.column.prop),
            ])
            queryParams.push([
              `${parentBase}fs${childIdx}op`,
              overrideFilterType || child.column.filterType,
            ])
            queryParams.push([`${parentBase}fs${childIdx}v`, value])
          }
          const { selectedPredefined } = child
          if (selectedPredefined) {
            const { controls = [] } = selectedPredefined
            queryParams[queryParams.length - 1][1] =
              selectedPredefined.childMethod ||
              child.column.childMethod ||
              'and'

            let predefinedCount = 0
            return controls.forEach((_control) => {
              const control = deepClone(_control)
              if (
                typeof control.value === 'undefined' ||
                control.value === null
              ) {
                return
              }
              const { overrideProp } = control
              const queryParts =
                control.filterAsIs === true
                  ? [control.value]
                  : control.value.split(' ')

              if (
                control.value.split(' ').length === 1 &&
                control.splitByProp
              ) {
                queryParams[queryParams.length - 1][1] = 'or'
                control.splitByProp = false
              }

              queryParts.forEach((queryPart, queryPartIndex) => {
                let propArray = child.column.prop
                if (Array.isArray(child.column.filterProp)) {
                  propArray = child.column.filterProp
                }
                const prop = propArray[queryPartIndex]
                if (Array.isArray(propArray)) {
                  if (control.splitByProp) {
                    addSingleFilter(
                      child,
                      queryPart,
                      prop,
                      predefinedCount++,
                      control.filterType
                    )
                  } else {
                    propArray.forEach((prop) =>
                      addSingleFilter(
                        child,
                        queryPart,
                        prop,
                        predefinedCount++,
                        control.filterType
                      )
                    )
                  }
                } else {
                  addSingleFilter(
                    child,
                    queryPart,
                    overrideProp ||
                      child.column.filterProp ||
                      child.column.prop,
                    predefinedCount,
                    control.filterType
                  )
                  predefinedCount++
                }
              })
            })
          }
          if (child.value === null || typeof child.value === 'undefined') {
            return
          }
          if (typeof child.value === 'string') {
            const queryParts =
              child.column.filterAsIs === true
                ? [child.value]
                : child.value.split(' ')
            if (queryParts.length > 1 || Array.isArray(child.column.prop)) {
              queryParams[queryParams.length - 1][1] = 'or'
            }
            let overrideIdx = 0
            queryParts.forEach((queryPart, partIndex) => {
              if (
                child.column.filterProp &&
                !Array.isArray(child.column.filterProp)
              ) {
                let filterType =
                  (child.column.computedFilterType &&
                    child.column.computedFilterType(queryPart)) ||
                  child.column.filterType
                addSingleFilter(
                  child,
                  queryPart,
                  child.column.filterProp,
                  partIndex,
                  filterType
                )
              } else if (
                Array.isArray(child.column.prop) ||
                Array.isArray(child.column.filterProp)
              ) {
                const propsArray = child.column.filterProp || child.column.prop
                propsArray.forEach((prop) =>
                  addSingleFilter(child, queryPart, prop, overrideIdx++)
                )
              } else {
                addSingleFilter(child, queryPart, undefined, partIndex)
              }
            })
          } else {
            addSingleFilter(
              child,
              child.value,
              child.column.filterProp || child.column.prop
            )
          }
        })
        const grandChildren = childrenWithChildren(_parent)
        if (grandChildren.length) {
          recurse(grandChildren, parentBase)
        }
      })
    }
    recurse(_parents)
    return queryParams
      .filter((f) => f[0] !== null)
      .map((f) => `${f[0]}=${encodeURIComponent(f[1])}`)
      .join('&')
  }

  function asESQuery() {
    const esTopLevel = {
      query: {
        bool: {
          must: [],
        },
      },
    }

    const recurse = (_parents, parentsList) => {
      for (const _parent of _parents) {
        const _children = children(_parent)

        // FIRST LEVEL CHILDREN
        for (const child of _children) {
          const prop = child.column?.filterProp || child.column?.prop
          const filterType = child.column?.filterType || 'eq'

          const isControl = child.selectedPredefined?.controls?.length > 0
          if (isControl) {
            const controls = child.selectedPredefined?.controls
            const controlsWithValues = controls.filter((c) => c.value)
            const controlsWithFilterType = controlsWithValues
              .filter((c) => c.filterType)
              .filter((c) => c.filterType !== 'eq')
            const controlsWithoutFilterType = controlsWithValues.filter(
              (c) => !c.filterType || c.filterType == 'eq'
            )

            for (const controlWithFilterType of controlsWithFilterType) {
              const filterType = controlWithFilterType.filterType
              const controlValue = formatColumnValue(
                controlWithFilterType,
                controlWithFilterType.value,
                child
              )
              const termsQuery = createQuery(prop, controlValue, filterType)
              parentsList.push(termsQuery)
            }

            const controlValues = controlsWithoutFilterType.map((c) =>
              formatColumnValue(c, c.value, child)
            )
            if (controlValues.length > 0) {
              // CREATE TERMS QUERY
              const termsQuery = createQuery(prop, controlValues, filterType)
              parentsList.push(termsQuery)
            }
            continue
          }

          if (child.value === null || typeof child.value === 'undefined') {
            return
          }
          if (typeof child.value === 'string') {
            const queryValues = getSplitQueryValues(child, filterType)
            const termsQuery = createQuery(prop, queryValues, filterType)
            parentsList.push(termsQuery)
          } else {
            const childValue = formatColumnValue(child, child.value, child)
            const termsQuery = createQuery(prop, childValue, filterType)
            parentsList.push(termsQuery)
          }
        }

        // RECURSE IF NECESSARY
        const grandChildren = childrenWithChildren(_parent)
        if (grandChildren.length) {
          const newParentsList = {
            bool: {
              must: [],
            },
          }
          parentsList.push(newParentsList)
          recurse(grandChildren, newParentsList.bool.must)
        }
      }
    }

    recurse(parents(), esTopLevel.query.bool.must)
    console.log('esQuery', esTopLevel)
    return esTopLevel
  }

  function createQuery(prop, value, filterType) {
    const filterProperty = formatProp(prop)
    switch (filterType) {
      case 'eq':
        if (!Array.isArray(value)) {
          value = [value]
        }
        return {
          terms: {
            [filterProperty]: value,
          },
        }
      case 'neq':
        return {
          bool: {
            must_not: [
              {
                term: {
                  [filterProperty]: value,
                },
              },
            ],
          },
        }
      case 'contains':
        return {
          wildcard: {
            [filterProperty]: {
              value: `*${value.replace(/\s/g, '*')}*`,
              case_insensitive: true,
            },
          },
        }
      case 'startswith':
        return {
          wildcard: {
            [filterProperty]: {
              value: `${value}*`,
              case_insensitive: true,
            },
          },
        }
      case 'endswith':
        return {
          wildcard: {
            [filterProperty]: {
              value: `*${value}`,
              case_insensitive: true,
            },
          },
        }
      case 'lte':
      case 'gte':
        return {
          range: {
            [filterProperty]: {
              [filterType]: value,
            },
          },
        }
      case 'lt':
      case 'gt':
        return {
          range: {
            [filterProperty]: {
              [filterType]: value,
            },
          },
        }
    }
  }

  function formatColumnValue(filter, value, childFilter) {
    let column = filter
    if (filter.column) {
      column = filter.column
    }

    if (column.type === 'number') {
      return parseInt(value)
    }

    if (typeof childFilter?.column?.filterValueFormatter === 'function') {
      return childFilter.column.filterValueFormatter(value, filter)
    }
    return value
  }

  function getSplitQueryValues(filter, filterType) {
    if (['lte', 'gte', 'contains'].includes(filterType)) {
      return formatColumnValue(filter, filter.value, filter)
    }

    return filter.column?.filterAsIs === true
      ? [formatColumnValue(filter, filter.value)]
      : filter.value.split(' ').map((v) => formatColumnValue(filter, v, filter))
  }

  return {
    asQueryParams,
    createParent,
    asESQuery,
    children,
    parents,
    parent,
    remove,
    clear,
    find,
    get,
    set,
    or,
    and,
    add,
  }
}
