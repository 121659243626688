import { v4 } from 'uuid'
import { Address } from './Address'

export class TripAddress {
  constructor({ tripAddressHash = null, orderIndex, tripAddressId = null }) {
    this.tripAddressHash = tripAddressHash || v4()
    this.tripAddressId = tripAddressId
    this.tripAddressNotes = []
    this.address = new Address()
    this.orderIndex = orderIndex
  }

  toObject() {
    const tripAddress = Object.assign({}, this)
    tripAddress.address = this.address.toObject()
    tripAddress.tripAddressNotes = this.tripAddressNotes.map((note) =>
      note.toObject()
    )
    return tripAddress
  }

  toJSON() {
    const tripAddress = Object.assign({}, this)
    tripAddress.address = this.address.toObject()
    tripAddress.tripAddressNotes = this.tripAddressNotes.map((note) =>
      note.toObject()
    )
    return tripAddress
  }
}
