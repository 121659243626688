<template>
  <div :class="classes" @click="clickHandler">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CRTab',
  props: {
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      index: 0,
    }
  },
  computed: {
    isActive() {
      return this.activeTabIndex === this.index
    },
    classes() {
      let classes = 'cr-tab'
      classes = this.isActive ? `${classes} cr-tab--active` : classes
      classes = this.disabled ? `${classes} cr-tab--disabled` : classes
      return classes
    },
  },
  methods: {
    clickHandler() {
      this.$emit('click', this.index)
    },
  },
}
</script>

<style lang="scss" scoped>
$tab-disabled-opacity: 0.5 !default;
$tab-font-size: 14px;
$tab-font-weight: 500;
$tab-line-height: normal !default;
$tabs-bar-height: 48px !default;
$tabs-icons-and-text-bar-height: 72px !default;
$tabs-item-max-width: 360px !default;
$tabs-item-min-width: 90px !default;
$tabs-item-padding: 0 16px !default;

.cr-tab {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  flex-basis: auto;
  flex-grow: 1;
  font-size: $tab-font-size;
  font-weight: $tab-font-weight;
  justify-content: center;
  line-height: $tab-line-height;
  min-width: $tabs-item-min-width;
  max-width: $tabs-item-max-width;
  outline: none;
  padding: $tabs-item-padding;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: none;
  user-select: none;
  color: inherit;

  &:before {
    background-color: currentColor;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
  }

  &--active {
    color: $primary;
    z-index: 5;

    &:not(:focus)::before {
      opacity: 0;
    }

    .cr-icon,
    .cr-btn.v-btn--flat {
      color: inherit;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: $tab-disabled-opacity;
  }
}
</style>
