// /tables/systemParameters

// Using some boilerplate from from:
// https://github.com/chrisvfritz/vue-enterprise-boilerplate

import axios from 'axios'
import { baseUrl } from '@/utils/env'
import op from 'simple-object-path'

export const state = {
  currentParameters: getSavedState('system.parameters'),
}

export const mutations = {
  SET_SYSTEM_PARAMS(state, newValue) {
    state.currentParameters = newValue
    saveState('system.parameters', newValue)
  },
}

export const getters = {
  systemParameters(state) {
    return state.currentParameters
  },
  accountingIntegration(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const accountingTables =
      resultList.find(
        (resultItem) => op(resultItem, 'name') === 'accountingIntegration'
      ) || {}

    if (!accountingTables.value) {
      return true
    }

    return accountingTables.value === '0'
  },
  availabilityDisabled() {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const availabilityItem =
      resultList.find(
        (resultItem) =>
          op(resultItem, 'name') === 'coachrailAvailabilityEnabled'
      ) || {}
    return availabilityItem.value === '0'
  },
  preTripArrivalTimeDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const tripItineraryItem =
      resultList.find(
        (resultItem) => op(resultItem, 'name') === 'preTripArrivalTime'
      ) || {}
    return tripItineraryItem.value === '0'
  },
  useNewResDetail(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const newResDetail =
      resultList.find(
        (resultItem) => op(resultItem, 'name') === 'newResDetail'
      ) || {}
    return newResDetail.value === '1'
  },
  reservationsTV(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const reservationsTVParam =
      resultList.find(
        (resultItem) => op(resultItem, 'name') === 'reservationsTV'
      ) || {}

    return reservationsTVParam.value === 'new'
  },
  contractTrackingTabDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const contractTrackingItem =
      resultList.find(
        (resultItem) => resultItem?.name === 'isContractTrackingTabEnabled'
      ) || {}
    return contractTrackingItem.value === 'false'
  },
  contractRiderTrackingDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const samsungFeatures =
      resultList.find(
        (resultItem) => resultItem?.name === 'isContractRiderTrackingEnabled'
      ) || {}
    return samsungFeatures.value === 'false'
  },
  isCharterUpScoreFactorsDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const charterUpScoreParam =
      resultList.find(
        (resultItem) => resultItem?.name === 'isCharterUpScoreFactorsEnabled'
      ) || {}
    return charterUpScoreParam.value === 'false'
  },
  isRALeadSourceButtonDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const leadSourceParam =
      resultList.find(
        (resultItem) => resultItem?.name === 'isRALeadSourceButtonEnabled'
      ) || {}
    return leadSourceParam.value === 'false'
  },
  isCallCenterCallStatusDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const leadSourceParam =
      resultList.find(
        (resultItem) => resultItem?.name === 'isCallCenterCallStatusEnabled'
      ) || {}
    return leadSourceParam.value === 'false'
  },
  isQuoteExpirationAutosetEnabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const expirationAutosetParam =
      resultList.find(
        (resultItem) => resultItem?.name === 'isQuoteExpirationAutosetEnabled'
      ) || {}
    return expirationAutosetParam.value === 'true'
  },
  isQuotesAutoSetPaymentDisabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const leadSourceParam =
      resultList.find(
        (resultItem) => resultItem?.name === 'isQuotesAutoSetPaymentEnabled'
      ) || {}
    return leadSourceParam.value === 'false'
  },
  isCompanyBlocksEnabled(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const reviewsPage =
      resultList.find(
        (resultItem) =>
          op(resultItem, 'name') === 'isCupopsQuickAddAvailabilityEnabled'
      ) || {}

    return reviewsPage.value === 'true'
  },
  twilioFlexInstanceOverride(state) {
    const resultList =
      op(state, 'currentParameters/systemParameters/resultList') || []
    const instanceOverride =
      resultList.find(
        (resultItem) => op(resultItem, 'name') === 'twilioFlexInstanceOverride'
      ) || {}

    return instanceOverride?.value || null
  },
}

export const actions = {
  init({ state, dispatch }) {
    dispatch('getSystemParameters')
  },

  getSystemParameters({ commit, dispatch, getters }) {

    const host = baseUrl()
    const url = `https://${host}/tables/systemParameters`
    return axios
      .get(url)
      .then((response) => {
        const systemParameters = response.data
        commit(
          'SET_SYSTEM_PARAMS',
          Object.assign({}, state.currentParameters || {}, {
            systemParameters,
          })
        )
        return systemParameters
      })
      .catch((error) => {
        return error
      })
  },
}

// ===
// Private helpers
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
