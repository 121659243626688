import { Customer } from './Customer'
import { Trip } from './Trip'

export class Quote {
  constructor({ vehicleTypes }) {
    this.quoteId = null
    this.managedId = null
    this.checkoutPageId = null
    this.leadSourceTypeId = null
    this.contractId = null
    this.charterUpQuote = false
    this.decisionDate = null
    this.expirationDate = null
    this.expirationTimezone = null
    this.preferred = false
    this.dueDate = null
    this.allowEcheckout = false
    this.attachPdfToEmail = false
    this.leadTemperatureTypeId = null
    this.leadFollowUpDate = null
    this.hasReccurence = false
    this.isPendingConfirmation = false
    this.isEnterprise = false
    this.leadSourceType = null
    this.checkoutPage = {}
    this.pricingMethod = null
    this.sentBy = null
    this.trips = [new Trip({ vehicleTypes })]
    this.quoteNotes = []
    this.customer = new Customer()
    this.customerId = null
    this.isActive = true
    this.leadSource = {
      id: 188,
      label: 'CharterUP',
      key: 'charterup',
      description: '',
      partnerCompanyId: 2,
      company: { id: 1 },
    }
    this.isPrebooking = false
    this.currencyType = 'USD'
    this.total = null
    this.createdBy = {}
    this.recurringTotal = null
    this.requiredDeposit = null
    this.createdOn = null
    this.updatedOn = null
    this.hash = null
    this.participatingInEmailCampaigns = false
    this.productClassificationId = null
    this.sourcingTeamClassificationId = null
    this.supportTeamClassificationId = null
    this.minQuality = null
    this.accountExecutiveId = null
    this.tierId = null
    this.tier = null
  }

  toObject() {
    const quote = Object.assign({}, this)
    quote.trips = this.trips.map((trip) => trip.toObject())
    return quote
  }
}
