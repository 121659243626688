import { deepClone } from './deepClone'

/**
 * Constructs an array of address type objects from given keys.
 *
 * @param types - An array of keys (string) for generating address types.
 * @returns An array of address type objects, deeply cloned.
 */
export const buildAddressTypes = (
  types: string[] | null | undefined
): any[] => {
  const addressTypes = (types || []).map((key) => ({
    addressTypeId: null,
    label: null,
    key,
    description: null,
  }))
  return deepClone(addressTypes)
}

/**
 * Checks whether the 'airport' address type is present in the given types.
 *
 * @param types - An array of strings or objects (with a 'key' property) representing address types.
 * @returns `true` if 'airport' is found among the types, `false` otherwise.
 */
export const isAirportAddress = (
  types: (string | { key?: string })[] | null | undefined
): boolean => {
  if (!types || types.length === 0) {
    return false
  }

  return types.some((type) => {
    // Using type guard to differentiate between string and object with 'key'
    if (typeof type === 'string') {
      return type === 'airport'
    } else {
      return type.key === 'airport'
    }
  })
}

export const buildAddressName = (address): string => {
  let addressName = ''
  if (address?.street1 && address?.street1 !== ' ') {
    addressName = `${address.street1}, `
  }
  if (address?.city) {
    addressName = `${addressName}${address.city}, `
  }
  if (address?.state) {
    addressName = `${addressName}${address.state}`
  }
  return addressName
}
