<template>
  <v-layout
    align-center
    class="overflow-hidden cursor-pointer text-overflow-ellipsis hover:background-background-hover line-height-1 padding-y-0 padding-x-4 h-48 transition-duration-100 transition-all transition-ease-in"
    :class="{
      'background-background-hover': selected,
    }"
    @click="$emit('click', item)"
  >
    <CRIcon
      v-if="source === 'charterup'"
      :key="`item-${index}-${item.source}-${color}`"
      :width="24"
      :height="24"
      view-box="0 0 24 24"
      class="min-w-24 w-24 h-24 margin-r-2"
      :color="color"
    >
      {{ icon }}
    </CRIcon>
    <img
      v-else
      class="w-24 margin-r-2"
      src="@/assets/images/dropoff_completed.png"
    />
    <p class="overflow-hidden text-overflow-ellipsis margin-a-0">
      {{ item.description }}
    </p>
    <v-spacer />
    <div
      v-if="hasChildAddresses"
      class="h-24 margin-l-3 padding-x-1"
      @click.stop="$emit('click:open-children')"
    >
      <CRIcon view-box="0 0 24 24" width="24" height="24" color="blue">
        keyboard_arrow_right
      </CRIcon>
    </div>
  </v-layout>
</template>

<script>
import places from '@/services/places'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: 0,
    },
    userLatitude: {
      type: Number,
      default: null,
    },
    userLongitude: {
      type: Number,
      default: null,
    },
  },
  computed: {
    color() {
      const source = this.item?.source
      if (source === 'google') {
        return 'black'
      }
      if (source === 'charterup') {
        return 'blue'
      }
      return ''
    },
    source() {
      return this.item?.source
    },
    icon() {
      if (this.source === 'google') {
        return 'google_g'
      }
      if (this.source === 'charterup') {
        return 'charter_up_c'
      }
      return ''
    },
    hasChildAddresses() {
      return this.item?.childKnownAddressIds?.length > 0
    },
  },
}
</script>
