<template>
  <div ref="cr-dropdown" class="cr-dropdown" :style="cssVars">
    <div v-if="label" class="cr-dropdown__label">
      <label :for="$attrs.id || $attrs.name">{{ label }}</label>
    </div>

    <!-- Dropdown Input -->
    <div style="position: relative">
      <div :class="classes" @click="toggleSelectIsOpen">
        <template v-if="chips && itemDisplay">
          <div
            v-if="Array.isArray(itemDisplay)"
            class="cr-dropdown__value-box__chips"
          >
            <span
              v-for="(item, itemIndex) in itemDisplay"
              :key="`chip-item--${itemIndex}`"
              class="cr-dropdown__value-box__chip"
            >
              {{ item }}
            </span>
          </div>
          <span v-else class="cr-dropdown__value-box__chip">
            {{ itemDisplay }}
          </span>
        </template>
        <input
          ref="input"
          class="cr-dropdown__value-box__input"
          readonly
          tabindex="0"
          :disabled="disabled"
          :value="chips ? (itemDisplay ? ' ' : '') : itemDisplay"
          :placeholder="placeholder"
          v-bind="$attrs"
          v-on="inputListeners"
        />
        <CRIcon
          :class="`cr-dropdown__value-box__icon ${
            isOpen ? 'rotate-arrow' : ''
          }`"
          view-box="0 0 24 24"
        >
          arrow_drop_down
        </CRIcon>
        <div v-if="loading" class="cr-dropdown__loader">
          <div class="cr-dropdown__loader__bar"></div>
        </div>
      </div>

      <!-- Dropdown items -->
      <div v-if="isOpen" class="cr-dropdown__items">
        <ul>
          <li
            v-for="(item, index) in multipleItems"
            :id="`${$attrs.id ? `${$attrs.id}-` : ''}${getIdOfItem(index)}`"
            :key="`${getKebab(textOfItem(item))}-${index}`"
            :ref="`item${index}`"
            tabindex="0"
            @click="() => handleClick(index, item.selected)"
            @keyup.enter="() => handleClick(index, item.selected)"
            @keyup.space="() => handleClick(index, item.selected)"
          >
            <template v-if="multiple">
              <input
                type="checkbox"
                class="cr-dropdown__items__checkbox"
                :checked="item.selected"
              />
            </template>
            {{ textOfItem(item) }}
          </li>
        </ul>
      </div>
    </div>

    <!-- Error Messages -->
    <div
      v-if="errorsList.length > 0 && !hideDetails"
      class="cr-dropdown__detail"
    >
      <transition name="fade">
        <template v-if="errorsList.length > 0">
          <p
            v-for="err in errorsList"
            :key="err"
            class="cr-dropdown__detail__error"
          >
            {{ err }}
          </p>
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { toKebab } from '@/cr/utils/string'
import CRColor from '@/cr/color/CRColor'

export default {
  inheritAttrs: true,
  props: {
    backgroundColor: {
      type: String,
      default: 'inputBackgroundGray',
    },
    chips: {
      type: Boolean,
    },
    color: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: undefined,
    },
    itemValue: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
    },
    returnObject: {
      type: Boolean,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    validateOnBlur: {
      type: Boolean,
    },
    value: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      hasBlur: false,
      isFocused: false,
      selectedValue: null,
      keyboardInput: '',
      timeLastPressed: null,
      isOpen: false,
      currentItemFocus: -1,
      ruleErrors: [],
      multipleItems: [],
      didValidateInput: false,
    }
  },
  computed: {
    classes() {
      let classStr = 'cr-dropdown__value-box'
      if (this.isFocused) {
        classStr = `${classStr} cr-dropdown__value-box__focused`
      }
      if (this.hasError) {
        classStr = `${classStr} cr-dropdown__value-box__error`
      }
      if (this.disabled) {
        classStr = `${classStr} cr-dropdown__value-box__disabled`
      }
      return classStr
    },
    cssVars() {
      return {
        '--bg-color': CRColor(this.backgroundColor).toHexString(),
        '--main-color': CRColor(this.color).toHexString(),
      }
    },
    errorsList() {
      if (typeof this.errorMessages === 'string') {
        return [...this.ruleErrors, this.errorMessages]
      }

      if (Array.isArray(this.errorMessages)) {
        return [...this.ruleErrors, ...this.errorMessages]
      }

      return [...this.ruleErrors]
    },
    itemTextKey() {
      return this.itemText ? this.itemText : 'text'
    },
    itemValueKey() {
      return this.itemValue ? this.itemValue : 'value'
    },
    itemType() {
      if (!this.items?.length) {
        return 'empty'
      }
      return typeof this.items[0]
    },
    itemDisplay() {
      if (!this.selectedValue) {
        return ''
      }

      // If multiple and a chip display, return an array of text values
      // If multiple and not a chip display, return a comma delimited string;
      if (this.multiple) {
        if (this.chips) {
          return this.selectedValue.map((item) => this.textOfItem(item))
        }

        let displayString = ''

        for (let i = 0; i < this.selectedValue.length; i++) {
          displayString += this.textOfItem(this.selectedValue[i])
          displayString += i !== this.selectedValue.length - 1 ? ', ' : ''
        }

        return displayString
      }

      return this.textOfItem(this.selectedValue)
    },
    inputListeners() {
      var vm = this
      return Object.assign({}, this.$listeners, {
        blur: function () {
          vm.isFocused = false
          vm.hasBlur = true
          vm.$emit('blur')
        },
        focus: function () {
          vm.isFocused = true
          vm.$emit('focus')
        },
      })
    },
    hasError() {
      if (this.validateOnBlur && !this.hasBlur && !this.didValidateInput) {
        return false
      }

      return this.error || this.errorsList.length > 0 || !this.testRules()
    },
  },
  watch: {
    keyboardInput: function (newInput) {
      if (this.itemType === 'empty') {
        return
      }

      let itemIndex = this.items.findIndex((elt) =>
        this.textOfItem(elt).toUpperCase().startsWith(newInput.toUpperCase())
      )

      if (itemIndex < 0) {
        return
      }

      if (this.isOpen) {
        this.focusOnItem(itemIndex)
      }
      this.selectItem(itemIndex)
    },
    isOpen: function (open) {
      if (open) {
        this.isFocused = true
      } else {
        this.currentItemFocus = -1
      }
    },
    selectedValue: function () {
      this.testRules()
    },
    value: function () {
      let field = 'value'
      if (this.itemValue) {
        field = this.itemValue
      }
      if (this.selectedValue?.[field] !== this.value) {
        const itemIndex = this.items.findIndex(
          (item) => item[field] === this.value
        )
        if (itemIndex) {
          this.selectItem(itemIndex)
        }
      }
    },
  },
  created() {
    window.addEventListener('click', this.handleOutsideClick)
  },
  destroyed() {
    window.removeEventListener('click', this.handleOutsideClick)
  },
  mounted() {
    // For selecting multiple--map the original items to an array that tracks
    // whether an item has been selected or not
    this.items.forEach((item) => {
      this.multipleItems =
        this.itemType === 'object'
          ? [...this.multipleItems, { ...item, selected: false }]
          : [
              ...this.multipleItems,
              { text: item, value: item, selected: false },
            ]
    })

    window.addEventListener('keydown', this.handleKeyDown)
  },
  methods: {
    handleKeyDown(e) {
      let key = e.key

      if (!this.isFocused && !this.isOpen) return
      if (this.disabled || this.readonly) return
      if (key === 'Shift') return

      if (this.isFocused && !this.isOpen) {
        switch (key) {
          case 'Enter':
          case ' ':
          case 'Spacebar':
            e.preventDefault()
            this.isOpen = true
            break
          case 'ArrowDown':
          case 'ArrowUp':
            e.preventDefault()
            this.isOpen = true
            if (this.items.length > 0) {
              this.currentItemFocus =
                key === 'ArrowUp' ? this.items.length - 1 : 0
              this.$nextTick(() => {
                this.focusOnItem(this.currentItemFocus)
              })
            }
            break

          // For character keys, check if we should concatenate existing
          // keyboard input or whether we should reset it
          default:
            this.setKeyboardInput(key)
            break
        }
      } else if (this.isFocused && this.isOpen) {
        switch (key) {
          case 'Escape':
          case 'Enter':
          case ' ':
          case 'Spacebar':
            this.isOpen = false
            break

          case 'ArrowUp':
          case 'ArrowDown':
            if (this.items.length > 0) {
              e.preventDefault()
              let i = key === 'ArrowUp' ? this.items.length - 1 : 0
              this.currentItemFocus = i
              this.$nextTick(() => {
                this.focusOnItem(this.currentItemFocus)
              })
            }
            break

          default:
            this.setKeyboardInput(key)
            break
        }
      } else if (!this.isFocused && this.isOpen) {
        switch (key) {
          case 'Escape':
            this.isOpen = false
            break
          case 'ArrowUp':
            if (this.currentItemFocus > -1) {
              e.preventDefault()
              this.currentItemFocus--

              this.$nextTick(() => {
                if (this.currentItemFocus >= 0) {
                  this.focusOnItem(this.currentItemFocus)
                } else {
                  let inputRef = this.$refs.input.focus()
                  if (inputRef) inputRef.focus()
                }
              })
            }
            break
          case 'ArrowDown':
            if (this.currentItemFocus < this.items.length - 1) {
              e.preventDefault()
              this.currentItemFocus++
              this.$nextTick(() => {
                this.focusOnItem(this.currentItemFocus)
              })
            }
            break

          default:
            this.setKeyboardInput(key)
            break
        }
      }
    },
    setKeyboardInput(input) {
      if (
        this.timeLastPressed &&
        this.shouldReset(this.timeLastPressed, DateTime.local())
      ) {
        this.keyboardInput = ''
      }
      this.keyboardInput += input
      this.timeLastPressed = DateTime.local()
    },
    handleOutsideClick(e) {
      let element = this.$refs['cr-dropdown']
      if (e.target !== element && !element.contains(e.target)) {
        this.isOpen = false
      }
    },
    selectItem(index) {
      if (index >= this.items.length || index < 0) return
      this.selectedValue = this.items[index]

      if (this.returnObject) {
        this.$emit('input', this.items[index])
      } else {
        this.$emit('input', this.valueOfItem(this.items[index]))
      }
    },
    selectMultipleItems(index) {
      if (index >= this.multipleItems.length || index < 0) return

      this.multipleItems[index].selected = true
      this.selectedValue = this.selectedValue
        ? [...this.selectedValue, this.items[index]]
        : [this.items[index]]

      if (this.returnObject) {
        this.$emit('input', this.selectedValue)
      } else {
        this.$emit(
          'input',
          this.selectedValue.map((item) => this.valueOfItem(item))
        )
      }
    },
    deselectItem(index) {
      if (index >= this.multipleItems.length || index < 0) return

      this.multipleItems[index].selected = false

      // Remove item from selectedValue array
      let firstOccurrenceIndex = this.selectedValue.findIndex((item) => {
        let itemValue = this.valueOfItem(item)
        return itemValue === this.valueOfItem(this.items[index])
      })
      if (firstOccurrenceIndex >= 0) {
        this.selectedValue.splice(firstOccurrenceIndex, 1)
      }

      // Emit the resulting array
      if (this.returnObject) {
        this.$emit('input', this.selectedValue)
      } else {
        this.$emit(
          'input',
          this.selectedValue.map((item) => this.valueOfItem(item))
        )
      }
    },
    shouldReset(start, end) {
      var diffInSeconds = end.diff(start, 'seconds').toObject()
      return diffInSeconds.seconds > 0.5
    },
    handleClick(index, isSelected = false) {
      if (!this.multiple) {
        this.selectItem(index)
      } else if (isSelected) {
        this.deselectItem(index)
      } else {
        this.selectMultipleItems(index)
      }

      if (!this.multiple) {
        this.$refs.input.focus()
        this.isOpen = false
      }
    },
    focusOnItem(index) {
      let ref = this.$refs[`item${index}`][0]
      if (ref) ref.focus()
    },
    close() {
      this.isOpen = false
    },
    toggleSelectIsOpen(e) {
      if (e.target === this) return

      if (!this.disabled && !this.readonly) {
        this.isOpen = !this.isOpen
      }
    },
    testRules() {
      let flagError = false
      let ruleErrors = []

      const falseOrString = (rule) => {
        if (typeof rule === 'string') {
          flagError = true
          ruleErrors.push(rule)
          return true
        } else if (typeof rule === 'boolean' && !rule) {
          flagError = true
          return true
        }
        return false
      }

      for (let i = 0; i < this.rules.length; i++) {
        let rule = this.rules[i]

        if (falseOrString(rule) || typeof rule !== 'function') {
          continue
        }

        if (this.selectedValue && this.multiple) {
          this.selectedValue.forEach((item) => {
            let result = rule(this.valueOfItem(item))
            falseOrString(result)
          })
        } else {
          let result = rule(this.valueOfItem(this.selectedValue))
          falseOrString(result)
        }
      }
      this.ruleErrors = ruleErrors
      return !flagError
    },
    textOfItem(item) {
      if (!item) {
        return null
      }
      return typeof item === 'string' ? item : item[this.itemTextKey]
    },
    valueOfItem(item) {
      if (!item) {
        return null
      }
      return typeof item === 'string' ? item : item[this.itemValueKey]
    },
    getKebab(str) {
      return toKebab(str)
    },
    getIdOfItem(index) {
      let kebabOfValue = toKebab(this.valueOfItem(this.items[index]))
      let numInstances = 0

      // Check if this ID already exists; if so, append the n-th instance
      // that this is of it
      for (let i = 0; i < index; i++) {
        if (toKebab(this.valueOfItem(this.items[i])) === kebabOfValue) {
          numInstances++
        }
      }

      return numInstances > 0 ? `${kebabOfValue}-${numInstances}` : kebabOfValue
    },
    validate() {
      this.didValidateInput = true
      return !this.error && !this.errorsList.length > 0 && this.testRules()
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-dropdown {
  position: relative;

  .rotate-arrow {
    transform: rotate(180deg);
  }

  svg {
    transition: 0.2s ease-in-out all;
  }

  &__label {
    margin-bottom: 4px;
  }

  &__value-box {
    margin-bottom: 8px;
    background-color: var(--bg-color);
    border: 1px solid $border-gray;
    border-radius: 5px;
    padding: 0 12px;
    min-height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: $gray-mid-light;
    }

    &__chips {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-basis: 100%;
      margin: 5px 0;
    }

    &__chip {
      background-color: $input-background-gray;
      border-radius: 5px;
      padding: 2px 8px;
      white-space: nowrap;
      margin: 2px 2px;
      font-size: 13px;
    }

    &__disabled {
      background: $blue-dull !important;
      border-color: $blue-dull !important;
      cursor: auto;

      svg {
        color: $gray;
      }
    }

    &__error {
      background: $error-pale !important;
      border-color: $error !important;

      svg {
        color: $error;
      }

      input {
        color: $error !important;
      }
    }

    &__input {
      background-color: inherit;
      border-radius: 5px;
      font-size: 14px;
      color: $gray;
      outline: none;
      padding: 8px 0;
      width: 100%;
      min-width: 10px;
      cursor: inherit;
      user-select: none;
      flex-shrink: 6;
    }

    svg {
      color: rgba($gray, 0.6);
      flex-shrink: 0;
    }
  }

  &__value-box__focused {
    border-color: var(--main-color);
    svg {
      color: var(--main-color);
      flex-shrink: 0;
    }
  }

  &__loader {
    height: 2px;
    width: 100%;
    position: absolute;
    top: calc(100% - 22px);
    left: 0;
    overflow: hidden;

    &__bar {
      width: 100%;
      position: absolute;
      height: 2px;
      background-color: var(--main-color);
      animation-name: loader-animation;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }
  }

  &__detail {
    padding: 0 12px;
    margin-bottom: 8px;
    min-height: 12px;

    p {
      font-size: 12px;
      margin: 0;
      line-height: 1.25;
    }

    &__error {
      color: $error;
    }
  }

  &__items {
    background: $white;
    top: calc(100% + 1px);
    left: 0;
    width: 100%;
    max-height: 380px;
    overflow-y: scroll;
    box-shadow: 0px 5px 5px -3px rgba($black-base, 0.2),
      0px 8px 10px 1px rgba($black-base, 0.14),
      0px 3px 14px 2px rgba($black-base, 0.12);
    z-index: 100;
    position: absolute;
    transition: 0.2s ease-in-out all;

    ul {
      list-style-type: none;
      padding: 8px 0;
      white-space: nowrap;
      margin: 0;

      li {
        height: 24px;
        line-height: 24px;
        font-weight: 400;
        height: 48px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        transition: background-color 0.1s ease-in;
        cursor: pointer;

        &:hover {
          background-color: $background-hover;
        }

        &:focus {
          background-color: $background-hover;
          outline: none;
        }

        &:active {
          background-color: $gray-mid-light;
        }

        a {
          color: $primary;
        }
      }
    }
  }
}

input[type='checkbox'] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid $gray;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  margin-right: 20px;
}

input[type='checkbox']:checked {
  background-color: $primary;
  border-color: $primary;
  background-image: url('./icons/IconDone.svg');
  color: $white;
  background-position: center;
  background-size: 19px 19px;
}

// Animations
@keyframes loader-animation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-5px);
  opacity: 0;
}
</style>
