<template>
  <div :class="classes">
    <div
      :class="{
        'padding-t-1 padding-b-1': padLabelVertical,
        'padding-l-1 padding-r-1': padLabelHorizontal,
      }"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CRTag',
  props: {
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'green',
    },
    padLabelVertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    padLabelHorizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      return `tag tag--${this.color}`
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  background: $green;
  color: $white;
  font-size: 12px;
  border-radius: 5px;
  margin-right: 5px;
  padding: 3px 8px;
  width: fit-content;
  pointer-events: none;
  &--blue {
    background: $blue;
  }
  &--green {
    background: $green;
  }
  &--red {
    background: $red;
  }
  &--yellow {
    background: $yellow;
  }
  &--purple {
    background: $purple;
  }
  &--gray {
    background: $gray;
  }
  &--blueDarkerNew {
    background: $blue-darker-new;
    color: $blue-darker;
  }
  &--yellowNewLighter {
    background: $yellow-new-lighter;
    color: $yellow-new-darker;
  }
  &--purpleLighter {
    background: $purple-lighter;
    color: $purple;
  }
}
</style>
