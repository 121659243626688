<template>
  <v-layout
    align-center
    class="overflow-hidden cursor-pointer text-overflow-ellipsis hover:background-background-hover line-height-1 padding-y-0 padding-x-4 h-48 transition-duration-100 transition-all transition-ease-in"
    :class="{ 'background-background-hover': selected }"
    @click="$emit('click', item)"
  >
    <div class="position-relative display-flex margin-r-2">
      <p class="position-absolute left-12 top-6 text-white font-weight-bold">
        {{ item[prefillNumberingKey] ? item[prefillNumberingKey] : index + 1 }}
      </p>
      <CRIcon :width="32" :height="32" view-box="0 0 24 24">
        location_stop_filled
      </CRIcon>
    </div>
    <p class="overflow-hidden text-overflow-ellipsis margin-a-0">
      <span v-if="item.title">{{ item.title }} -</span>
      {{ item.addressName }}
    </p>
  </v-layout>
</template>

<script>
export default {
  name: 'CRAddressSelectorV2PrefillItem',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    prefillNumberingKey: {
      type: String,
      default: 'prefillNumberingKey',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.cr-address-selector {
  .cr-text-field {
    z-index: 3;
    position: relative;
  }

  &__dropdown-items {
    box-shadow: 0px 5px 5px -3px rgba($black-base, 0.2),
      0px 8px 10px 1px rgba($black-base, 0.14),
      0px 3px 14px 2px rgba($black-base, 0.12);
    top: calc(100% - 20px);
    max-height: 500px;
    overflow-y: auto;
  }
}
</style>
