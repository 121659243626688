<template>
  <div>
    <form class="cr-form">
      <slot></slot>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      childInputs: [],
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.childInputs = this.$children.filter((c) =>
        this.isInput(c.constructor.options.name)
      )
    })
  },
  updated() {
    this.$nextTick(function () {
      this.childInputs = this.$children.filter((c) =>
        this.isInput(c.constructor.options.name)
      )
    })
  },
  methods: {
    validate() {
      let formIsValid = true
      this.childInputs.forEach((input) => {
        let inputValidate = input.validate()
        formIsValid = formIsValid && inputValidate
      })
      return formIsValid
    },
    isInput(name) {
      switch (name) {
        case 'CRTextField':
        case 'CRTextArea':
        case 'CRDropdown':
          return true
        default:
          return false
      }
    },
  },
}
</script>
