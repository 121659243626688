import axios from 'axios'
import { baseUrl } from '@/utils/env'
import { PaymentGatewayTypeKey } from '@/utils/enum'

export const state = {}

export const actions = {
  getPayments(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/payments?${query}`
    return axios.get(url)
  },
  getCustomerPaymentProfiles(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/customerPaymentProfiles?${query}`
    return axios.get(url)
  },
  getOperatorPaymentProfiles(store, companyId) {
    const url = `https://${baseUrl()}/operatorPaymentProfile/${companyId}`
    return axios.get(url)
  },
  addReservationPayment(store, params) {
    const url = `https://${baseUrl()}/payment/addReservationPayment`

    return axios.post(url, {
      ...params,
    })
  },
  voidReservationPayment(store, params) {
    const url = `https://${baseUrl()}/payment/voidReservationPayment`

    return axios.post(url, {
      ...params,
    })
  },
  addReferralPayment(store, params) {
    const url = `https://${baseUrl()}/payment/addReferralPayment`

    return axios.post(url, {
      ...params,
    })
  },
  voidReferralPayment(store, params) {
    const url = `https://${baseUrl()}/payment/voidReferralPayment`

    return axios.post(url, {
      ...params,
    })
  },
  collectPayment(store, params) {
    const url = `https://${baseUrl()}/payment/addReservationsPayment`
    return axios.post(url, params.payload)
  },
  collectPaymentCustomer(store, params) {
    const url = `https://${baseUrl()}/payment/addReservationsPayment/customer?customerUserId=${
      params.customerUserId
    }`
    return axios.post(url, params.payload)
  },
  getDefaultPaymentGateway(store, companyId) {
    const url = `https://${baseUrl(
      'api'
    )}/paymentGateway/company/${companyId}/default`
    return axios.get(url)
  },
  getCheckoutPagePaymentGateway(store, params) {
    const { companyId, checkoutPageId } = params
    const url = `https://${baseUrl(
      'api'
    )}/paymentGateway/company/${companyId}/checkoutPage/${checkoutPageId}`
    return axios.get(url)
  },
  getAllPaymentGateways(store) {
    const url = `https://${baseUrl('api')}/paymentGateway`
    return axios.get(url)
  },
  addPaymentGateway(store, payload) {
    const url = `https://${baseUrl('api')}/paymentGateway`
    return axios.post(url, payload)
  },
  updatePaymentGateway(store, payload) {
    const url = `https://${baseUrl('api')}/paymentGateway`
    return axios.put(url, payload)
  },
  setDefaultPaymentGateway(store, paymentGatewayId) {
    const url = `https://${baseUrl(
      'api'
    )}/paymentGateway/default/${paymentGatewayId}`
    return axios.get(url)
  },
  createOperatorPaymentProfile(store, payload) {
    let url = `https://${baseUrl()}/operatorPaymentProfile/create`
    if (payload?.payment_gateway?.key == PaymentGatewayTypeKey.Finix) {
      url += '?paymentGatewayTypeKey=finix'
    }
    return axios.post(url, payload)
  },
}
