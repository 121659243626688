<template>
  <div :id="$attrs.id ? `${$attrs.id}-container` : ''" class="cr-input">
    <div>
      <slot name="label" />
      <label
        v-if="$attrs.label || $attrs.floatinglabel"
        :class="`${nowrap ? 'nowrap' : ''}`"
        :for="$attrs.id"
      >
        {{ $attrs.label || $attrs.floatinglabel }}
        <small v-if="$attrs.sublabel">- {{ $attrs.sublabel }}</small>
        <v-tooltip top>
          <template #activator="{ on }">
            <span v-if="$attrs.tooltip" style="max-height: 16px" v-on="on">
              <CRIcon
                :color="$attrs.color || 'primary'"
                :class="$attrs.color ? `icon-${$attrs.color}` : 'icon-primary'"
                :width="16"
                :height="16"
                style="margin-bottom: -3px"
                view-box="0 0 24 24"
                icon-name="Info"
              >
                info
              </CRIcon>
            </span>
          </template>
          <span :style="$attrs['tooltip-style']">{{ $attrs.tooltip }}</span>
        </v-tooltip>
      </label>
    </div>

    <AutoCompleteAddress
      v-if="type === 'address'"
      :id="$attrs.id"
      v-bind="$attrs"
      v-on="inputListeners"
    />

    <v-menu
      v-else-if="type === 'v-time'"
      :id="$attrs.id"
      ref="timeMenuRef"
      v-model="timeMenu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="value"
      transition="scale-transition"
      offset-y
      full-width
      max-width="290px"
      min-width="290px"
    >
      <template #activator="vtime">
        <v-text-field
          v-model="value"
          v-bind="$attrs"
          prepend-inner-icon="access_time"
          readonly
          solo
          flat
          v-on="vtime.on"
        />
      </template>
      <v-time-picker
        v-if="timeMenu"
        v-model="value"
        full-width
        format="ampm"
        :ampm-in-title="true"
        @click:minute="$refs.timeMenuRef.save(value)"
      />
    </v-menu>

    <v-text-field
      v-else-if="type === 'currency'"
      :id="$attrs.id"
      v-model.lazy="formattedValue"
      v-money="money"
      solo
      flat
      :value="value"
      v-bind="$attrs"
      v-on="inputListeners"
    />

    <v-autocomplete
      v-else-if="type === 'autocomplete'"
      :id="$attrs.id"
      :label="slotLabel"
      v-bind="$attrs"
      :search-input.sync="search"
      :value="value"
      cache-items
      flat
      solo
      :append-icon="appendIcon === null ? '' : 'keyboard_arrow_down'"
      hide-no-data
      hide-details
      :background-color="backgroundColor"
      v-on="inputListeners"
    />

    <v-menu
      v-else-if="type === 'date'"
      :id="$attrs.id"
      v-model="isDateMenuOpen"
      :close-on-content-click="true"
      :nudge-right="dateNoOffset ? -22 : 40"
      lazy
      transition="scale-transition"
      offset-y
      full-width
      min-width="290px"
    >
      <template #activator="{ on }">
        <v-text-field
          :id="$attrs.id ? `${$attrs.id}-input` : 'id'"
          v-model="value"
          :class="classes"
          solo
          flat
          persistent-hint
          :value="formattedValue"
          v-bind="$attrs"
          :mask="'####-##-##'"
          :hide-details="hideDetails"
          return-masked-value
          :prepend-inner-icon="prependInnerEventIcon"
          :prepend-icon="prependIcon"
          :append-icon="appendIcon"
          @blur="date = parseDate(formattedValue)"
          @click:prepend-inner="toggleIsDateMenuOpen"
          @click:append="$emit('click-append')"
          @keyup="possiblyToggleDatePicker"
          @input="(e) => $emit('input', e)"
          v-on="on"
        />
      </template>
      <v-date-picker
        :id="$attrs.id"
        v-model="date"
        no-title
        v-on="inputListeners"
        @input="isDateMenuOpen = false"
      />
    </v-menu>

    <v-text-field
      v-else
      :id="$attrs.id"
      solo
      flat
      v-bind="$attrs"
      :value="value"
      :type="typeInternal"
      :hide-details="hideDetails"
      :background-color="backgroundColor"
      :readonly="readonly"
      :append-icon="appendIcon"
      :prepend-icon="prependIcon"
      :autocomplete="browserAutocomplete"
      @click:append="(evt) => ($attrs.appendCb ? $attrs.appendCb(evt) : null)"
      v-on="inputListeners"
    />
  </div>
</template>

<script>
import { VMoney } from 'v-money'

import AutoCompleteAddress from './AutoCompleteAddress.vue'

export default {
  name: 'CRInput',
  components: {
    AutoCompleteAddress,
  },
  directives: {
    money: VMoney,
  },
  inheritAttrs: false,
  props: {
    value: { type: [Number, String, Object, Date], default: null },
    type: { type: String, default: 'text' },
    required: { type: Boolean },
    mustBeEmail: { type: Boolean },
    hideDetails: { type: Boolean },
    autocompleteItems: { type: Array, default: () => [] },
    // For validation
    modelPath: { type: String, default: '' },
    defaultDate: { type: String, default: undefined },
    browserAutocomplete: { type: String, default: undefined },
    dateNoOffset: Boolean,
    nowrap: { type: Boolean, default: false },
    backgroundColor: { type: String, default: '' },
    classes: { type: String, default: '' },
  },
  data() {
    return {
      timeMenu: false,
      search: undefined,
      formattedValue: '',
      date: null,
      isDateMenuOpen: false,
      specialTypeIcons: {
        date: 'calendar_today',
      },
      specialTypePrefixes: {
        dollar: '$',
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 2,
      },
    }
  },
  computed: {
    prependInnerEventIcon() {
      if (this.$attrs['prepend-inner-event'] === false) {
        return null
      }

      return '$vuetify.icons.dateSingle'
    },
    appendIcon() {
      if (this.$attrs['append-icon'] === 'null') {
        return null
      }
      return (
        this.$attrs['append-icon'] || this.specialTypeIcons[this.$attrs.type]
      )
    },
    slotLabel() {
      if (this.$attrs?.items.length > 0 && this.$attrs?.items[0]?.slotLabel) {
        return this.$attrs?.items[0]?.slotLabel
      }
      return this.$attrs?.label
    },
    prependIcon() {
      return this.$attrs['prepend-icon'] || null
    },
    prefix() {
      return this.$attrs.prefix || this.specialTypePrefixes[this.$attrs.type]
    },
    readonly() {
      return !!this.$attrs.readonly
    },
    typeInternal() {
      if (this.type === 'date') {
        return 'date'
      }
      return this.type
    },
    computedDateFormatted() {
      return this.formatDate(this.value)
    },
    inputListeners() {
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: (value) => {
            // This is where we could change this to currency value
            let returnValue = value
            if (this.type === 'currency') {
              if (!returnValue) {
                returnValue = 0
              } else {
                returnValue = parseFloat(value.replace(/\$|,/g, ''))
              }
            } else if (this.type === 'date') {
              returnValue = this.date
            }
            this.$emit('input', returnValue)
            return returnValue
          },
          change: (value) => {
            // This is where we could change this to currency value
            this.$emit('change', value)
          },
          blur: (value) => {
            this.$emit('blur', value)
          },
        }
      )
    },
  },
  watch: {
    value() {
      if (this.type === 'date') {
        this.date = this.value
      }
    },
    date() {
      if (this.type === 'date') {
        this.formattedValue = this.formatDate(this.date)
      }
    },
    defaultDate() {
      if (this.defaultDate) {
        let splitDate = this.defaultDate.split('-')

        if (splitDate.length === 3 && !this.value) {
          this.date = `${splitDate[0]}-${splitDate[1]}`
        }
      }
    },
  },
  mounted() {
    if (this.value) {
      this.date = this.value
    } else if (this.defaultDate) {
      this.date = this.defaultDate
    }
  },

  methods: {
    toggleIsDateMenuOpen() {
      this.isDateMenuOpen = !this.isDateMenuOpen
    },
    possiblyToggleDatePicker(event) {
      if (event.key === 'Enter') {
        this.toggleIsDateMenuOpen()
      }
    },
    formatDate(date) {
      if (!date || typeof date !== 'string') {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },
}
</script>

<style lang="scss" scoped>
// adds border to all CRInputs that is in the designs
// waiting to uncomment this until I have time to go through every page
// ::v-deep .v-input__slot {
//   border: 1px solid $border-gray !important;
// }

.cr-input {
  label {
    display: block;
    margin-bottom: 4px;

    .v-icon {
      font-size: 1.2em;
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  input {
    color: $gray;
    background: $secondary;
    border: 2px solid $secondary;
    outline: none;
    box-shadow: none;

    &::placeholder {
      color: $gray-medium-light;
    }

    &:hover {
      border-color: $gray-light;
    }

    &:focus,
    &:focus:hover {
      border-color: $primary;
    }
  }

  .dropdown-item {
    font-size: 1rem;
  }

  ::v-deep {
    .v-icon {
      color: $primary;
    }

    .icon-date-single {
      font-size: 15px;
    }

    .icon-book-it {
      font-size: 17px;
    }
  }
}

.icon-primary {
  color: $primary;
}

::v-deep .white-input-slot.v-input.v-text-field--solo {
  .v-input__control {
    .v-input__slot {
      background-color: $white !important;
    }
  }
}
</style>
