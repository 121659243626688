<template>
  <div class="crm-actions-sidebar">
    <div class="crm-actions-sidebar--content">
      <TeamSelection
        v-if="showTeamSelections"
        ref="quote-form-classifications"
        mode="edit"
        :existing-product-classification-id="productClassificationId"
        :existing-sourcing-team-classification-id="sourcingTeamClassificationId"
        :existing-support-team-classification-id="supportTeamClassificationId"
        @product-input="changeClassification('product', $event)"
        @sourcing-team-input="changeClassification('sourcing-team', $event)"
        @support-team-input="changeClassification('support-team', $event)"
      />
      <QuoteFormLeadFollowUpDetail
        ref="quote-form-lead-follow-up"
        :quote="quote"
        @set-lead-temperature="setLeadTemperature"
        @set-lead-follow-up-date="setLeadFollowUpDate"
        @set-quote-note-text="setQuoteNoteText"
        @set-quote-note-html="setQuoteNoteHtml"
      />
      <AutoCompleteUser
        label="Account Executive"
        placeholder="Select Account Executive"
        class="margin-t-4"
        areAccountExecutives="true"
        :initial-user="quote.accountExecutiveId"
        hide-details
        @user-selected="accountExecutiveSelected"
      />
      <CRInput
        v-model="quote.opportunityId"
        label="Opportunity ID"
        class="margin-t-4"
        hide-details
      />
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="crm-actions-sidebar-save-btn"
        :loading="submitting"
        class="crm-actions-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import QuoteFormLeadFollowUpDetail from '@/components/QuoteFormLeadFollowUpDetail.vue'
import AutoCompleteUser from '@/components/AutoCompleteUser.vue'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import TeamSelection from '@/components/TeamSelection.vue'

export default {
  components: {
    TeamSelection,
    QuoteFormLeadFollowUpDetail,
    AutoCompleteUser,
  },
  props: {
    quoteId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      quote: {},
      leadTemperatureType: null,
      leadFollowUpDate: null,
      newQuoteNoteText: null,
      newQuoteNoteHtml: null,
      productClassificationId: null,
      sourcingTeamClassificationId: null,
      supportTeamClassificationId: null,
    }
  },
  computed: {
    showTeamSelections() {
      return this.quote?.isConverted
    },
  },
  watch: {
    'quote.productClassificationId'(val) {
      if (val) {
        this.productClassificationId = val
      }
    },
    'quote.sourcingTeamClassificationId'(val) {
      if (val) {
        this.sourcingTeamClassificationId = val
      }
    },
    'quote.supportTeamClassificationId'(val) {
      if (val) {
        this.supportTeamClassificationId = val
      }
    },
  },
  async mounted() {
    const quoteResult = await this.$store.dispatch(
      'quotes/getV2Quote',
      this.quoteId
    )
    this.quote = quoteResult.data.quote
    this.leadTemperatureType = this.quote?.leadTemperatureTypeId
    this.leadFollowUpDate = this.quote?.leadFollowUpDate
  },

  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    setLeadTemperature(leadTemperatureTypeId) {
      this.leadTemperatureType = leadTemperatureTypeId
    },
    setLeadFollowUpDate(leadFollowUpDate) {
      this.leadFollowUpDate = leadFollowUpDate
    },
    setQuoteNoteText(value) {
      this.newQuoteNoteText = value
    },
    setQuoteNoteHtml(value) {
      this.newQuoteNoteHtml = value
    },
    accountExecutiveSelected(user) {
      this.quote.accountExecutiveId = user.userId
      this.quote.accountExecutiveName = `${user.firstName} ${user.lastName}`
    },
    changeClassification(typeKey, classificationId) {
      if (typeKey === 'product') {
        this.productClassificationId = classificationId
      } else if (typeKey === 'sourcing-team') {
        this.sourcingTeamClassificationId = classificationId
      } else if (typeKey === 'support-team') {
        this.supportTeamClassificationId = classificationId
      }
    },
    async submit() {
      if (!this.$refs['quote-form-lead-follow-up'].validate()) {
        return
      }
      if (
        this.showTeamSelections &&
        !this.$refs['quote-form-classifications'].validate()
      ) {
        return
      }
      const payload = {
        quoteId: this.quote.quoteId,
        leadTemperatureTypeId: this.leadTemperatureType,
        leadFollowUpDate: this.leadFollowUpDate,
        quoteNotes: deepClone(this.quote.quoteNotes),
        accountExecutiveId: this.quote.accountExecutiveId,
        accountExecutiveName: this.quote.accountExecutiveName,
        opportunityId: this.quote.opportunityId,
      }
      if (this.newQuoteNoteText) {
        payload.quoteNotes.push({
          note: this.newQuoteNoteText,
          html: this.newQuoteNoteHtml,
        })
      }
      const classificationsPayload = {
        quoteId: this.quote.quoteId,
        productClassificationId: this.productClassificationId,
        sourcingTeamClassificationId: this.sourcingTeamClassificationId,
        supportTeamClassificationId: this.supportTeamClassificationId,
      }
      try {
        if (this.showTeamSelections) {
          await Promise.all(
            this.$store.dispatch('quotes/updateQuoteCRMDetails', payload),
            this.$store.dispatch(
              'quotes/updateConvertedQuote',
              classificationsPayload
            )
          )
        } else {
          await this.$store.dispatch('quotes/updateQuoteCRMDetails', payload)
        }

        EventBus.$emit('refreshQuote')
        EventBus.$emit('refresh-quotes')
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error updating lead follow up details.',
        })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.crm-actions-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed !important;
    flex-direction: column;
    font-size: 18px !important;
    bottom: 0 !important;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0 !important;
    background: $primary;
    z-index: 3;
  }
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
