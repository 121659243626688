import { Auth0Plugin } from '@/auth'
import Vue from 'vue'
import { auth0Config } from '@/utils/env'

const { domain, clientId } = auth0Config()
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  redirectUri: window.location.origin + '/ci/auth-callback',
});
