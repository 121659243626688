<template>
  <div :id="$attrs.id ? `${$attrs.id}-container` : ''">
    <label v-if="$attrs.label" :for="$attrs.id">{{ $attrs.label }}</label>
    <v-layout class="padding-t-1" row wrap>
      <v-flex
        v-for="v in value"
        :key="v"
        class="padding-y-1 padding-x-2 margin-b-2 margin-r-2 border-radius-5 max-w-fit d-flex align-center background-primary text-white"
      >
        {{ v }}
        <CRIcon
          @click.native="removeValue(v)"
          viewBox="0 0 24 24"
          width="16"
          height="16"
          class="cursor-pointer margin-l-1"
        >
          close
        </CRIcon>
      </v-flex>
    </v-layout>
    <v-form ref="multi-text-form">
      <CRInput
        v-model="textInput"
        :id="$attrs.id"
        type="text"
        :placeholder="$attrs.placeholder"
        append-icon="add"
        :rules="$attrs.rules"
        :hide-details="$attrs['hide-details']"
        @keydown.enter="addValue"
        @click:append="addValue"
      />
    </v-form>
  </div>
</template>

<script>
import CRIcon from '@/cr/components/CRIcon.vue'
export default {
  components: { CRIcon },
  name: 'CRMultiTextInput',
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      textInput: '',
    }
  },
  methods: {
    removeValue(value) {
      this.$emit(
        'input',
        this.value.filter((v) => v !== value)
      )
    },
    addValue() {
      if (!this.$refs['multi-text-form'].validate()) {
        return
      }
      if (this.textInput) {
        this.$emit('input', [...this.value, this.textInput])
        this.textInput = ''
      }
    },
  },
}
</script>
