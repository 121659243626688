<template>
  <div
    aria-label="Calendar"
    :class="[
      'cv-wrapper',
      'locale-' + languageCode(displayLocale),
      'locale-' + displayLocale,
      'y' + periodStart.getFullYear(),
      'm' + paddedMonth(periodStart),
      'period-' + displayPeriodUom,
      'periodCount-' + displayPeriodCount,
      {
        past: isPastMonth(periodStart),
        future: isFutureMonth(periodStart),
        noIntl: !supportsIntl,
      },
    ]"
  >
    <div class="cv-header-days">
      <template v-for="(label, index) in weekdayNames">
        <slot :index="getColumnDOWClass(index)" :label="label" name="dayHeader">
          <div
            :key="getColumnDOWClass(index)"
            :class="getColumnDOWClass(index)"
            class="cv-header-day"
          >
            {{ label }}
          </div>
        </slot>
      </template>
    </div>
    <div class="cv-weeks">
      <div class="cv-week">
        <div class="cv-weekdays">
          <div
            v-for="(day, dayIndex) in daysOfWeek(showDate)"
            :key="getColumnDOWClass(dayIndex)"
            :draggable="enableDateSelection"
            :class="[
              'cv-day',
              getColumnDOWClass(dayIndex),
              'd' + isoYearMonthDay(day),
              'd' + isoMonthDay(day),
              'd' + paddedDay(day),
              {
                today: isSameDate(day, today()),
                outsideOfMonth: !isSameMonth(day, defaultedShowDate),
                past: isInPast(day),
                future: isInFuture(day),
                last: isLastDayOfMonth(day),
                lastInstance: isLastInstanceOfMonth(day),
              },
              ...((dateClasses && dateClasses[isoYearMonthDay(day)]) || []),
            ]"
            :aria-label="day.getDate().toString()"
          >
            <div class="cv-day-number">{{ day.getDate() }}</div>
            <slot :day="day" name="dayContent" />
          </div>
          <template v-for="i in getItems()">
            <slot :value="i" name="item"></slot>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deepClone } from '@/utils/deepClone'

const WEEKDAY_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
export default {
  props: {
    showDate: { type: Date, default: undefined },
    displayPeriodUom: { type: String, default: 'week' },
    displayPeriodCount: { type: Number, default: 1 },
    locale: { type: String, default: undefined },
    weekdayNameFormat: { type: String, default: 'short' },
    startingDayOfWeek: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
  },
  computed: {
    // Props cannot default to computed/method returns, so create defaulted version of this
    // property and use it rather than the bare prop (Vue Issue #6013).
    displayLocale() {
      return this.locale || this.getDefaultBrowserLocale()
    },
    // ShowDate, but defaulted to today. Needed both for periodStart below and for the
    // "outside of month" class. Any time component passed as part of showDate is discarded.
    defaultedShowDate() {
      return this.showDate ? this.dateOnly(this.showDate) : this.today()
    },
    // Given the showDate, defaulted to today, computes the beginning and end of the period
    // that the date falls within.
    periodStart() {
      return this.beginningOfPeriod(
        this.defaultedShowDate,
        this.displayPeriodUom,
        this.startingDayOfWeek
      )
    },
    weekdayNames() {
      let display = []
      let alteredWeekdayNames = deepClone(WEEKDAY_NAMES)
      alteredWeekdayNames = alteredWeekdayNames.concat(
        alteredWeekdayNames.splice(0, this.startingDayOfWeek - 1)
      )
      for (const i of Array(Number.parseInt(this.displayPeriodCount))) {
        display = display.concat(alteredWeekdayNames)
      }
      return display
    },
    // Ensure all item properties have suitable default
    fixedItems() {
      if (!this.items) return []
      return this.items.map((item) => this.normalizeItem(item, false))
    },
    numberOfDays() {
      return this.weekdayNames.length
    },
  },
  mounted() {},
  methods: {
    // ******************************
    // UI Events
    // ******************************
    getColumnDOWClass(dayIndex) {
      return 'dow' + (dayIndex + this.startingDayOfWeek)
    },
    // ******************************
    // Calendar Items
    // ******************************
    itemComparer(a, b) {
      if (a.startDate < b.startDate) return -1
      if (b.startDate < a.startDate) return 1
      if (a.endDate > b.endDate) return -1
      if (b.endDate > a.endDate) return 1
      return a.id < b.id ? -1 : 1
    },
    // Return a list of items that INCLUDE any day within the date range,
    // inclusive, sorted so items that start earlier are returned first.
    findAndSortItemsInDateRange(startDate, endDate) {
      return this.fixedItems
        .filter(
          (item) =>
            item.endDate >= startDate &&
            this.dateOnly(item.startDate) <= endDate
        )
        .sort(this.itemComparer)
    },
    // Return a list of items that CONTAIN the week starting on a day.
    // Sorted so the items that start earlier are always shown first.
    getItems() {
      const items = this.findAndSortItemsInDateRange(
        this.showDate,
        this.addDays(this.showDate, this.numberOfDays)
      )
      const results = []
      const itemRows = []
      for (const i of Array(this.numberOfDays)) {
        itemRows.push([])
      }
      for (let i = 0; i < items.length; i++) {
        const ep = Object.assign({}, items[i], {
          classes: [...items[i].classes],
          itemRow: 0,
        })
        const continued = ep.startDate < this.showDate
        const startOffset = continued
          ? 0
          : this.dayDiff(this.showDate, ep.startDate)
        const span = Math.min(
          this.numberOfDays - startOffset,
          this.dayDiff(this.addDays(this.showDate, startOffset), ep.endDate) + 1
        )
        if (continued) {
          ep.classes.push('continued')
        }
        if (this.dayDiff(this.showDate, ep.endDate) > this.numberOfDays - 1) {
          ep.classes.push('toBeContinued')
        }
        if (this.isInPast(ep.endDate)) {
          ep.classes.push('past')
        }
        if (ep.originalItem.url) {
          ep.classes.push('hasUrl')
        }
        for (let d = 0; d < this.numberOfDays; d++) {
          if (d === startOffset) {
            let s = 0
            while (itemRows[d][s]) s++
            ep.itemRow = s
            itemRows[d][s] = true
          } else if (d < startOffset + span) {
            itemRows[d][ep.itemRow] = true
          }
        }
        ep.classes.push(`offset${startOffset}`)
        ep.classes.push(`span${span}`)
        results.push(ep)
      }
      return results
    },
    // CalendarMath functions
    normalizeItem(item, isHovered) {
      // Starting in version 6, classes must be an array of string
      // Classes may be a string, an array, or null. Normalize to an array
      const itemClasses = item.classes ? [...item.classes] : []
      // Provides support for pseudo-hover of entire item when one part of it is hovered
      if (isHovered) itemClasses.push('isHovered')
      return {
        originalItem: item,
        startDate: this.toLocalDate(item.startDate),
        // For an item without an end date, the end date is the start date
        endDate: this.toLocalDate(item.endDate || item.startDate),
        classes: itemClasses,
        // Items without a title are untitled
        title: item.title || 'Untitled',
        // An ID is *required*. Auto-generating leads to weird bugs because these are used as keys and passed in items
        id: item.id,
        // Pass the URL along
        url: item.url,
      }
    },
    toLocalDate(d) {
      return typeof d === 'string'
        ? this.fromIsoStringToLocalDate(d)
        : new Date(d)
    },
    fromIsoStringToLocalDate(s) {
      let d = [...Array(7)].map((_) => 0)
      s.split(/\D/, 7).forEach((s, i) => (d[i] = Number(s)))
      d[1]-- // adjust month
      return new Date(d[0], d[1], d[2], d[3], d[4], d[5], d[6])
    },
    languageCode(l) {
      return l.substring(0, 2)
    },
    paddedMonth(d) {
      return ('0' + String(d.getMonth() + 1)).slice(-2)
    },
    isPastMonth(d) {
      return this.beginningOfMonth(d) < this.beginningOfMonth(this.today())
    },
    isFutureMonth(d) {
      return this.beginningOfMonth(d) > this.beginningOfMonth(this.today())
    },
    beginningOfMonth(d) {
      return new Date(d.getFullYear(), d.getMonth())
    },
    today() {
      return this.dateOnly(new Date())
    },
    dateOnly(d) {
      // Always use a copy, setHours mutates argument
      const d2 = new Date(d)
      d2.setHours(0, 0, 0, 0)
      return d2
    },
    supportsIntl() {
      return typeof Intl !== 'undefined'
    },
    isoYearMonth(d) {
      return d.getFullYear() + '-' + this.paddedMonth(d)
    },
    isoYearMonthDay(d) {
      return this.isoYearMonth(d) + '-' + this.paddedDay(d)
    },
    getDefaultBrowserLocale() {
      // If not running in the browser, cannot determine a default, return the code for unknown (blank is invalid)
      if (typeof navigator === 'undefined') return 'unk'
      // Return the browser's language setting, implementation is browser-specific
      return (navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language
      ).toLowerCase()
    },
    beginningOfPeriod(d, periodUom, startDow) {
      switch (periodUom) {
        case 'year':
          return new Date(d.getFullYear(), 0)
        case 'month':
          return new Date(d.getFullYear(), d.getMonth())
        case 'week':
          return this.beginningOfWeek(d, startDow)
        default:
          return d
      }
    },
    beginningOfWeek(d, startDow) {
      return this.addDays(d, (startDow - d.getDay() - 7) % -7)
    },
    addDays(d, days) {
      return new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate() + days,
        d.getHours(),
        d.getMinutes(),
        d.getSeconds()
      )
    },
    dayDiff(d1, d2) {
      const endDate = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate()),
        startDate = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate())
      return (endDate - startDate) / 86400000
    },
    isInPast(d) {
      return this.dateOnly(d) < this.today()
    },
    isInFuture(d) {
      return this.dateOnly(d) > this.today()
    },
    daysOfWeek(weekStart) {
      return [...Array(this.numberOfDays)].map((_, i) =>
        this.addDays(weekStart, i)
      )
    },
    paddedDay(d) {
      return ('0' + String(d.getDate())).slice(-2)
    },
    isoMonthDay(d) {
      return this.paddedMonth(d) + '-' + this.paddedDay(d)
    },
    isSameDate(d1, d2) {
      return d1 && d2 && this.dayDiff(d1, d2) === 0
    },
    isSameMonth(d1, d2) {
      return (
        d1 &&
        d2 &&
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth()
      )
    },
    isLastDayOfMonth(d) {
      return d.getMonth() !== this.addDays(d, 1).getMonth()
    },
    isLastInstanceOfMonth(d) {
      return d.getMonth() !== this.addDays(d, 7).getMonth()
    },
  },
}
</script>
<!--
The CSS below represents only the CSS required for proper rendering (positioning, etc.) and
minimalist default borders and colors. Special-day colors, holiday emoji, item colors,
and decorations like border-radius should be part of a theme. Styles related to the default
header are in the CalendarViewHeader component.
-->
<style lang="scss" scoped>
/* Position/Flex */
/* Make the calendar flex vertically */
.cv-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.cv-wrapper,
.cv-wrapper div {
  box-sizing: border-box;
  line-height: 1em;
  font-size: 1em;
}
.cv-header-days {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  flex-flow: row nowrap;
  border-width: 0 0 0 1px;
}
.cv-header-day {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-width: 1px 1px 0 0;
}
/* The calendar grid should take up the remaining vertical space */
.cv-weeks {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  flex-flow: column nowrap;
  border-width: 0 0 1px 1px;
  /* Allow grid to scroll if there are too may weeks to fit in the view */
  overflow-y: auto;
  -ms-overflow-style: none;
}
.cv-weeknumber {
  width: 2rem;
  position: relative;
  text-align: center;
  border-width: 1px 1px 0 0;
  border-style: solid;
  line-height: 1;
}
/* Use flex basis of 0 on week row so all weeks will be same height regardless of content */
.cv-week {
  display: flex;
  /* Shorthand flex: 1 1 0 not supported by IE11 */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-flow: row nowrap;
  min-height: 3em;
  border-width: 0;
  /* Allow week items to scroll if they are too tall */
  position: relative;
  width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;
}
.cv-weekdays {
  display: flex;
  /* Shorthand flex: 1 1 0 not supported by IE11 */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  flex-flow: row nowrap;
  /* Days of the week go left to right even if user's language is RTL (#138) */
  direction: ltr;
  position: relative;
  overflow-y: auto;
}
.cv-day {
  display: flex;
  /* Shorthand flex: 1 1 0 not supported by IE11 */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  position: relative; /* Fallback for IE11, which doesn't support sticky */
  position: sticky; /* When week's items are scrolled, keep the day content fixed */
  top: 0;
  border-width: 1px 1px 0 0;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;
}
.cv-day-number {
  height: auto;
  align-self: flex-start;
}
/* Default styling for holiday hover descriptions */
.cv-day-number:hover::after {
  position: absolute;
  top: 1rem;
  background-color: var(--cal-holiday-bg, #f7f7f7);
  border: var(--cal-holiday-border, 1px solid #f0f0f0);
  box-shadow: 0.1rem 0.1rem 0.2rem
    var(--cal-holiday-shadow, rgba(0, 0, 0, 0.25));
  padding: 0.2rem;
  margin: 0.5rem;
  line-height: 1.2;
}
/*
A bug in Microsoft Edge 41 (EdgeHTML 16) has been reported (#109) where days "disappear" because they are
wrapping under the next week (despite the "nowrap" on cv-week). This appears to be an issue specifically
with our metrics and the sticky positioning. I was not able to reproduce this issue in Edge 38, 42, or 44.
I'm reticent to turn off the sticky functionality for all Edge users because of one version (or perhaps an
interaction of that version with a specific graphics adapter or other setting). So instead, I'm leaving this
as an example for anyone forced to support Edge 41 who may see the same issue. If that's the case, just
add this selector to your own CSS.
@supports (-ms-ime-align: auto) {
	.cv-day {
		position: relative;
	}
}
_:-ms-lang(x),
.cv-day {
	position: relative;
}
.cv-day-number {
	position: absolute;
	right: 0;
}
*/
.cv-day[draggable],
.cv-item[draggable] {
  user-select: none;
}
.cv-item {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  background-color: #f7f7f7;
  border-width: 1px;
  /* Restore user's direction setting (overridden for week) */
  direction: initial;
}
/* Wrap to show entire item title on hover */
.cv-wrapper.wrap-item-title-on-hover .cv-item:hover {
  white-space: normal;
  z-index: 1;
}
/* Colors */
.cv-header-days,
.cv-header-day,
.cv-weeks,
.cv-week,
.cv-day,
.cv-item {
  border-style: solid;
  border-color: #ddd;
}
/* Item Times */
.cv-item .endTime::before {
  content: '-';
}
/* Internal Metrics */
.cv-header-day,
.cv-day-number,
.cv-item {
  padding: 0.2em;
}
/* Allows emoji icons or labels (such as holidays) to be added more easily to specific dates by having the margin set already. */
.cv-day-number::before {
  margin-right: 0.5em;
}
.cv-item.offset0 {
  left: 0;
}
.cv-item.offset1 {
  left: calc((100% / 14));
}
.cv-item.offset2 {
  left: calc((200% / 14));
}
.cv-item.offset3 {
  left: calc((300% / 14));
}
.cv-item.offset4 {
  left: calc((400% / 14));
}
.cv-item.offset5 {
  left: calc((500% / 14));
}
.cv-item.offset6 {
  left: calc((600% / 14));
}
.cv-item.offset7 {
  left: calc((700% / 14));
}
.cv-item.offset8 {
  left: calc((800% / 14));
}
.cv-item.offset9 {
  left: calc((900% / 14));
}
.cv-item.offset10 {
  left: calc((1000% / 14));
}
.cv-item.offset11 {
  left: calc((1100% / 14));
}
.cv-item.offset12 {
  left: calc((1200% / 14));
}
.cv-item.offset13 {
  left: calc((1300% / 14));
}
/* Metrics for items spanning dates */
.cv-item.span1 {
  width: calc((100% / 14) - 0.05em - 1px);
}
.cv-item.span2 {
  width: calc((200% / 14) - 0.05em - 1px);
}
.cv-item.span3 {
  width: calc((300% / 14) - 0.05em - 1px);
}
.cv-item.span4 {
  width: calc((400% / 14) - 0.05em - 1px);
}
.cv-item.span5 {
  width: calc((500% / 14) - 0.05em - 1px);
}
.cv-item.span6 {
  width: calc((600% / 14) - 0.05em - 1px);
}
.cv-item.span7 {
  width: calc((700% / 14) - 0.05em - 1px);
}
.cv-item.span8 {
  width: calc((800% / 14) - 0.05em - 1px);
}
.cv-item.span9 {
  width: calc((900% / 14) - 0.05em - 1px);
}
.cv-item.span10 {
  width: calc((1000% / 14) - 0.05em - 1px);
}
.cv-item.span11 {
  width: calc((1100% / 14) - 0.05em - 1px);
}
.cv-item.span12 {
  width: calc((1200% / 14) - 0.05em - 1px);
}
.cv-item.span13 {
  width: calc((1300% / 14) - 0.05em - 1px);
}
.cv-item.span14 {
  width: calc((1400% / 14) - 0.05em - 1px);
}
/* Hide scrollbars for the grid and the week */
.cv-weeks::-webkit-scrollbar,
.cv-week::-webkit-scrollbar {
  width: 0; /* remove scrollbar space */
  background: transparent; /* optional: just make scrollbar invisible */
}
/*
**************************************************************
This theme is the default shipping theme, it includes some
decent defaults, but is separate from the calendar component
to make it easier for users to implement their own themes w/o
having to override as much.
**************************************************************
*/

/* Header */

.theme-default .cv-header,
.theme-default .cv-header-day {
  background-color: #f0f0f0;
}

.theme-default .cv-header .periodLabel {
  font-size: 1.5em;
}

.theme-default .cv-header button {
  color: #7f7f7f;
}

.theme-default .cv-header button:disabled {
  color: #ccc;
  background-color: #f7f7f7;
}

/* Grid */

.theme-default .cv-weeknumber {
  background-color: #e0e0e0;
  border-color: #ccc;
  color: #808080;
}

.theme-default .cv-weeknumber span {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.theme-default .cv-day.past {
  background-color: #fafafa;
}

.theme-default .cv-day.outsideOfMonth {
  background-color: #f7f7f7;
}

.theme-default .cv-day.today {
  background-color: #ffe;
}

.theme-default .cv-day[aria-selected='true'] {
  background-color: #ffc;
}

/* Events */

.theme-default .cv-item {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
}

.theme-default .cv-item.purple {
  background-color: #f0e0ff;
  border-color: #e7d7f7;
}

.theme-default .cv-item.orange {
  background-color: #ffe7d0;
  border-color: #f7e0c7;
}

.theme-default .cv-item.continued::before,
.theme-default .cv-item.toBeContinued::after {
  content: ' \21e2 ';
  color: #999;
}

.theme-default .cv-item.toBeContinued {
  border-right-style: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.theme-default .cv-item.isHovered.hasUrl {
  text-decoration: underline;
}

.theme-default .cv-item.continued {
  border-left-style: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cv-item.span3,
.cv-item.span4,
.cv-item.span5,
.cv-item.span6,
.cv-item.span7 {
  text-align: center;
}

/* Event Times */

.theme-default .cv-item .startTime,
.theme-default .cv-item .endTime {
  font-weight: bold;
  color: #666;
}

/* Drag and drop */

.theme-default .cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em yellow;
}
</style>
