import axios from 'axios'
import { baseUrl } from '@/utils/env'

export const actions = {
  getReceipts(store, params) {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/receipts?${query}`
    return axios.get(url)
  },
}
