/**
 * Regular expression pattern to match US state abbreviations.
 * Includes all 50 states with their standard two-letter abbreviations.
 *
 * Example matches include "UT" for Utah, "CA" for California, etc.
 */
export const stateAbbreviationPattern: RegExp = /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/gm

/**
 * Regular expression pattern to match any number, including negative numbers
 * and numbers with dashes or underscores in them. Primarily used for
 * validating input where numbers with special characters are allowed.
 *
 * Example matches include "123", "-45", and "67_89".
 */
export const anyNumberPattern: RegExp = /[0-9-_]/g
