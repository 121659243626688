import uuid from 'uuid'
import * as logger from '@/utils/logger'

export const namespaced = true

export const state = {
  showFlexFrame: false,
  flexCommand: undefined,
  flexCommandTrigger: uuid.v4(),
  agentStatus: 'Unavailable',
  isOnCall: false,
  confirmCall: false,
  outgoingCallDetail: undefined,
}

export const getters = {
  showFlexFrame() {
    return state.showFlexFrame
  },
  flexCommand() {
    return state.flexCommand
  },
  flexCommandTrigger() {
    return state.flexCommandTrigger
  },
  agentStatus() {
    return state.agentStatus
  },
  isOnCall() {
    return state.isOnCall
  },
  confirmCall() {
    return state.confirmCall
  },
  outgoingCallDetail() {
    return state.outgoingCallDetail
  },
}

export const mutations = {
  TOGGLE_FLEX_FRAME(state, value) {
    if (typeof value === 'boolean') {
      state.showFlexFrame = value
      return
    }
    state.showFlexFrame = !state.showFlexFrame
  },
  SET_FLEX_COMMAND(state, command) {
    state.flexCommand = command
  },
  SET_FLEX_COMMAND_TRIGGER(state) {
    state.flexCommandTrigger = uuid.v4()
  },
  SET_AGENT_STATUS(state, agentStatus) {
    state.agentStatus = agentStatus
  },
  SET_IS_ON_CALL(state, isOnCall) {
    state.isOnCall = isOnCall
  },
  SET_CONFIRM_CALL(state, confirmCall) {
    state.confirmCall = confirmCall
  },
  SET_OUTGOING_CALL_DETAIL(state, outgoingCallDetail) {
    state.outgoingCallDetail = outgoingCallDetail
  },
}

export const actions = {
  setFlexWindowDisplay({ commit }, value) {
    commit('TOGGLE_FLEX_FRAME', value)
  },
  callNumber({ commit, dispatch, rootGetters }, callDetail) {
    if (!rootGetters['auth/isFlexUser']) {
      return logger.warn('not a flex user')
    }
    if (state.agentStatus !== 'Available' || state.isOnCall) {
      const message = state.isOnCall
        ? 'You cannot make a call while on another call.'
        : 'You must be Available in Twilio to make calls.'
      return dispatch(
        'app/showAlert',
        { type: 'error', message },
        { root: true }
      )
    }
    commit('SET_CONFIRM_CALL', true)
    const firstName = callDetail?.customer?.firstName
    const lastName = callDetail?.customer?.lastName
    const phone = callDetail?.customer?.phone
    const outgoingCallDetail = {
      firstName,
      lastName,
      phone,
    }
    commit('SET_OUTGOING_CALL_DETAIL', outgoingCallDetail)
    commit('SET_FLEX_COMMAND', { action_name: 'make_call', to_number: phone })
  },
  startOutgoingCall({ commit }) {
    commit('SET_FLEX_COMMAND_TRIGGER')
  },
  clearFlexCommand({ commit }) {
    commit('SET_FLEX_COMMAND', undefined)
  },
  setConfirmCall({ commit }, confirmCall) {
    commit('SET_CONFIRM_CALL', confirmCall)
  },
  sendAgentInfo({ commit }, userId) {
    commit('SET_FLEX_COMMAND', { action_name: 'user_data', user_id: userId })
    commit('SET_FLEX_COMMAND_TRIGGER')
  },
  getAgentInfo({ commit }, userId) {
    commit('SET_FLEX_COMMAND', { action_name: 'get_worker', user_id: userId })
    commit('SET_FLEX_COMMAND_TRIGGER')
  },
  setAgentStatus({ commit }, agentStatus) {
    commit('SET_AGENT_STATUS', agentStatus)
  },
  setIsOnCall({ commit }, isOnCall) {
    commit('SET_IS_ON_CALL', isOnCall)
  },
}
