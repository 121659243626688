import { render, staticRenderFns } from "./ShofurHelp.vue?vue&type=template&id=6897d2bb&scoped=true"
var script = {}
import style0 from "./ShofurHelp.vue?vue&type=style&index=0&id=6897d2bb&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6897d2bb",
  null
  
)

export default component.exports