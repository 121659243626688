import { buildAddressTypes } from '@/utils/address'

export class Address {
  constructor(address) {
    this.addressId = address?.addressId || null
    this.city = address?.city || ''
    this.state = address?.state || ''
    this.country = address?.country || ''
    this.postalCode = address?.postalCode || ''
    this.addressName = address?.addressName || address?.name || ''
    this.completeAddress = address?.addressName || ''
    this.name = address?.addressName || address?.name || ''
    this.street1 = address?.street1 || ''
    this.street2 = address?.street2 || null
    this.lat = address?.lat || ''
    this.lng = address?.lng || ''
    this.timeZone = address?.timeZone || ''
    this.title = address?.title || null
    this.knownAddressId = address?.knownAddressId || null
    this.addressTypes = buildAddressTypes(address?.types) || null
  }

  toObject() {
    const address = Object.assign({}, this)
    return address
  }
}
