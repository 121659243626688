var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"margin-t-3",attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-form',{ref:"team-classifications-form",staticClass:"w-full"},[_c('v-flex',{staticClass:"margin-r-2",attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"label":"Product","items":_vm.productClassifications,"value":_vm.productClassificationId,"disabled":_vm.disabled,"item-value":"classificationId","item-text":"label","loading":_vm.loading,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Product is Required',
                error: 'Product is Required',
              }),
            ]},on:{"input":function($event){return _vm.handleInput('product-input', $event)}}})],1),_c('v-flex',{staticClass:"margin-r-2",attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"label":"Sourcing Team","items":_vm.sourcingTeamClassifications,"value":_vm.sourcingTeamClassificationId,"disabled":_vm.disabled,"item-value":"classificationId","item-text":"label","loading":_vm.loading,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Sourcing Team is Required',
                error: 'Sourcing Team is Required',
              }),
            ]},on:{"input":function($event){return _vm.handleInput('sourcing-team-input', $event)}}})],1),_c('v-flex',{staticClass:"margin-r-2",attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"label":"Support Team","items":_vm.supportTeamClassifications,"value":_vm.supportTeamClassificationId,"disabled":_vm.disabled,"item-value":"classificationId","item-text":"label","loading":_vm.loading,"rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Support Team is Required',
                error: 'Support Team is Required',
              }),
            ]},on:{"input":function($event){return _vm.handleInput('support-team-input', $event)}}})],1),(_vm.showTierSelection)?_c('v-flex',{staticClass:"margin-r-2",attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"id":"reservation-tier","label":"Service Tier","items":_vm.tiers,"item-text":"label","item-value":"tierId","loading":_vm.loading},on:{"input":function($event){return _vm.handleInput('reservation-tier-input', $event)}},model:{value:(_vm.tierId),callback:function ($$v) {_vm.tierId=$$v},expression:"tierId"}})],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }