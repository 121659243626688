export class CustomerAccountDefaults {
  constructor(customerAccountDefaults) {
    if (!customerAccountDefaults) {
      return
    }

    this.discountPercent = customerAccountDefaults.discountPercent || null
    this.markupPercent = customerAccountDefaults.markupPercent || null
    this.downPaymentPercent = customerAccountDefaults.downPaymentPercent || null
    this.needsManualReferral =
      customerAccountDefaults.needsManualReferral === true
        ? true
        : customerAccountDefaults.needsManualReferral === false
        ? false
        : null
    this.minQuality = customerAccountDefaults.minQuality || null
    this.paymentMethodType = customerAccountDefaults.paymentMethodType || null
    this.paymentTermsDays = customerAccountDefaults.paymentTermsDays || null
    this.paymentType = customerAccountDefaults.paymentType || null
    this.pricingMethodKey = customerAccountDefaults.pricingMethodKey || null
    this.productClassification =
      customerAccountDefaults.productClassification || null
    this.sourcingTeamClassification =
      customerAccountDefaults.sourcingTeamClassification || null
    this.supportTeamClassification =
      customerAccountDefaults.supportTeamClassification || null
  }

  toObject() {
    const customerAccountDefaults = Object.assign({}, this)
    customerAccountDefaults.roundedMinQuality = this.roundedMinQuality
    return customerAccountDefaults
  }

  get roundedMinQuality() {
    if (!this.minQuality) {
      return null
    }
    return this.minQuality.toFixed(2)
  }
}
