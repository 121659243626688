import { Rate } from './Rate'

export class VehicleTypeRate {
  constructor(vehicleTypeRate) {
    this.vehicleTypeKey = vehicleTypeRate?.vehicleTypeKey || null
    this.vehicleTypeLabel = vehicleTypeRate?.vehicleTypeLabel || null
    this.rates = []

    for (const rate of vehicleTypeRate?.rates || []) {
      this.rates.push(new Rate(rate))
    }
  }
  toObject() {
    const vehicleTypeRate = Object.assign({}, this)
    vehicleTypeRate.rates = this.rates.map((rate) => rate.toObject())
    return vehicleTypeRate
  }
}
