import knownAddress from '@/services/knownAddress'
import { buildHierarchy } from '@/utils/knownAddress'

const blankKnownAddress = {
  knownAddressId: null,
  parentKnownAddressId: null,
  lat: null,
  lng: null,
  name: null,
  street1: null,
  street2: null,
  city: null,
  state: null,
  postalCode: null,
  country: null,
  title: null,
  timeZone: null,
  addressTypes: [],
  childKnownAddresses: [],
}

export const state = {
  knownAddress: null,
  knownAddresses: [],
  mode: 'view',
  isEditingCoordinates: false,
  topLevelParent: null,
  hierarchy: [],
}

export const getters = {
  knownAddress(state) {
    return state.knownAddress
  },
  knownAddresses(state) {
    return state.knownAddresses
  },
  mode(state) {
    return state.mode
  },
  isEditingCoordinates(state) {
    return state.isEditingCoordinates
  },
  topLevelParent(state) {
    return state.topLevelParent
  },
  hierarchy(state) {
    return state.hierarchy
  },
}

export const mutations = {
  SET_KNOWN_ADDRESSES(state, knownAddresses) {
    state.knownAddresses = knownAddresses
  },
  SET_KNOWN_ADDRESS(state, knownAddress) {
    state.knownAddress = knownAddress
  },
  SET_LATITUDE(state, latitude) {
    state.knownAddress.lat = latitude
  },
  SET_LONGITUDE(state, longitude) {
    state.knownAddress.lng = longitude
  },
  SET_STREET1(state, street1) {
    state.knownAddress.street1 = street1
  },
  SET_STREET2(state, street2) {
    state.knownAddress.street2 = street2
  },
  SET_CITY(state, city) {
    state.knownAddress.city = city
  },
  SET_STATE(state, stateName) {
    state.knownAddress.state = stateName
  },
  SET_POSTAL_CODE(state, postalCode) {
    state.knownAddress.postalCode = postalCode
  },
  SET_COUNTRY(state, country) {
    state.knownAddress.country = country
  },
  SET_TITLE(state, title) {
    state.knownAddress.title = title
  },
  SET_TIME_ZONE(state, timeZone) {
    state.knownAddress.timeZone = timeZone
  },
  SET_NAME(state, name) {
    state.knownAddress.name = name
  },
  SET_ADDRESS_TYPES(state, addressTypes) {
    state.knownAddress.addressTypes = addressTypes
  },
  SET_MODE(state, mode) {
    state.mode = mode
  },
  SET_IS_EDITING_COORDINATES(state, isEditingCoordinates) {
    state.isEditingCoordinates = isEditingCoordinates
  },
  SET_TOP_LEVEL_PARENT(state, topLevelParent) {
    state.topLevelParent = topLevelParent
  },
  SET_HIERACHY(state, hierarchy) {
    state.hierarchy = hierarchy
  },
}

export const actions = {
  setKnownAddress({ commit }, knownAddress) {
    commit('SET_KNOWN_ADDRESS', knownAddress)
  },
  setLatitude({ commit }, latitude) {
    commit('SET_LATITUDE', latitude)
  },
  setLongitude({ commit }, longitude) {
    commit('SET_LONGITUDE', longitude)
  },
  setStreet1({ commit }, street1) {
    commit('SET_STREET1', street1)
  },
  setStreet2({ commit }, street2) {
    commit('SET_STREET2', street2)
  },
  setCity({ commit }, city) {
    commit('SET_CITY', city)
  },
  setState({ commit }, stateName) {
    commit('SET_STATE', stateName)
  },
  setPostalCode({ commit }, postalCode) {
    commit('SET_POSTAL_CODE', postalCode)
  },
  setCountry({ commit }, country) {
    commit('SET_COUNTRY', country)
  },
  setTitle({ commit }, title) {
    commit('SET_TITLE', title)
  },
  setTimeZone({ commit }, timeZone) {
    commit('SET_TIME_ZONE', timeZone)
  },
  setName({ commit }, name) {
    commit('SET_NAME', name)
  },
  setAddressTypes({ commit }, addressTypes) {
    commit('SET_ADDRESS_TYPES', addressTypes)
  },
  setMode({ commit }, mode) {
    commit('SET_MODE', mode)
  },
  setIsEditingCoordinates({ commit }, isEditingCoordinates) {
    commit('SET_IS_EDITING_COORDINATES', isEditingCoordinates)
  },
  async fetchTopLevelParent({ commit, state }, lowestKnownAddressId) {
    const knownAddressId =
      lowestKnownAddressId || state.knownAddress?.knownAddressId
    const topLevelParentResponse = await knownAddress.topLevelParentById(
      lowestKnownAddressId
    )
    const topLevelParent = topLevelParentResponse.data.data
    commit('SET_TOP_LEVEL_PARENT', topLevelParent)
    const hierarchy = buildHierarchy([topLevelParent], knownAddressId)
    commit('SET_HIERACHY', hierarchy)
  },
  setTopLevelParent({ commit, state }, topLevelParent) {
    commit('SET_TOP_LEVEL_PARENT', topLevelParent)
    const hierarchy = buildHierarchy(
      [topLevelParent],
      state.knownAddress?.knownAddressId
    )
    commit('SET_HIERACHY', hierarchy)
  },
  initNewKnownAddress({ commit, dispatch }) {
    commit('SET_KNOWN_ADDRESS', blankKnownAddress)
    dispatch('setMode', 'add')
  },
  save({ state }) {
    if (state.mode === 'edit') {
      return knownAddress.update(
        state.knownAddress.knownAddressId,
        state.knownAddress
      )
    }
    if (state.mode === 'add') {
      return knownAddress.create(state.knownAddress)
    }
  },
}
