<template>
  <div>
    <CRButton
      v-for="(option, index) in options"
      :key="`${option.value}-${index}`"
      @click="handleClick(option.value)"
    >
      {{ option.text }}
    </CRButton>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
